import { call, put, all } from 'redux-saga/effects';
import DirectoryActions from 'redux/directory';
import sortBy from 'lodash/sortBy';

export function* getDirectoryData(api) {
  try {
    const [ regions, years ] = yield all([
      call(api.getRegions),
      call(api.getStatYears),
    ]);

    const normalizedRegions = sortBy(regions.results.map(item => ({
      name: item.name,
      label: `${item.code} ${item.name}`,
      id: item.id,
      value: `${item.id}`,
      code: parseInt(item.code, 10),
    })), 'code');

    const normalizedYears = years.results.map(year => ({
      value: year.pk,
      label: year.name,
    }));

    yield put(DirectoryActions.getDirectoryDataSuccess(normalizedRegions, normalizedYears));
  } catch (error) {
    yield put(DirectoryActions.getDirectoryDataError(error));
  }
}

export function* getDirectoryDistricts(api, { id }) {
  try {
    const districts = yield call(api.getDistricts, id);

    const normalizedDistricts = districts.results.map(item => ({
      name:item.name,
      label:item.name,
      region: item.region,
      id: item.id,
      value: `${item.id}`,
    }));

    yield put(DirectoryActions.getDirectoryDistrictsSuccess(normalizedDistricts));
  } catch (error) {
    yield put(DirectoryActions.getDirectoryDistrictsError(error));
  }
}


export function* getMetaSchools(api, { params }) {
  try {
    var { study_year: years } = params;
    var results = yield all(years.reduce((result, year) => {
      var newParams = Object.assign({}, params);
      newParams.study_year = year;
      result[year] = call(api.getSchoolStat, newParams);
      return result;
    },{}));
    yield put(DirectoryActions.getDirectorySchoolsSuccess(results));
  } catch (error) {
    yield put(DirectoryActions.getDirectorySchoolsError(error));
  }
}

export function* addSchoolGroup(api, { params }) {
  try {
    var { name, list } = params;
    const { results } = yield call(api.addSchoolGroup, {
      name,
      data: list,
      user: 1,
    });
    yield put(DirectoryActions.addSchoolGroupSuccess(results));
  } catch (error) {
    yield put(DirectoryActions.addSchoolGroupError(error));
  }
}