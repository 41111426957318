import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import ClickOutside from 'react-click-outside';
import ReportFilterItem from 'components/ReportFilterItem/ReportFilterItem';
import ReportFilterMembersPopup from 'containers/ReportFilterMembersPopup/ReportFilterMembersPopup';
import { selectMembersCount } from 'redux/report';
import './ReportFilterMembers.css';

const mapStateToProps = ({ report, app }) => ({
  count: selectMembersCount(report),
});

const mapActionsToProps = {};


class ReportFilterMembers extends Component {
  static propTypes = {
    count: PropTypes.number,
    disabled: PropTypes.bool,
    
  }

  state = {
    isOpen: false,

  }

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  }

  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const { count, disabled } = this.props;
    const { isOpen } = this.state;
  
    const popupClassName = classnames('report-filter-members__popup', {
      'report-filter-members__popup_open': isOpen,
      'report-filter-members__popup_close': !isOpen,
    });

    return (
      <ClickOutside onClickOutside={this.handleClose}>
        <div className="report-filter-members" >
          <ReportFilterItem
            isDisabled={disabled}
            title={'Группы участников'}
            count={count}
            isOpen={isOpen}
            onClick={this.handleToggle}
          />
          <div className={popupClassName}>
            <ReportFilterMembersPopup />
          </div>
        </div>
      </ClickOutside>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportFilterMembers);
