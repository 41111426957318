import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader, { LOADER_SIZES } from 'components/Loader/Loader';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import progressTypes from 'constants/progressTypes';
import NoMatch from 'containers/NoMatch/NoMatch';
import Report from 'containers/Report/Report';
import Olap from 'containers/Olap/Olap';
import Directory from 'containers/Directory/Directory';
// import Indicators from 'containers/Indicators';
// import MyGroups from 'containers/MyGroups/MyGroups';
import Signin from 'containers/Signin/Signin';
import Signout from 'containers/Signout/Signout';
import AppActions, {
  selectCheckAuthorizationProgress,
  selectIsUserAuthorized,
} from 'redux/app';
import './App.css';

const mapStateToProps = ({ app }) => ({
  progress: selectCheckAuthorizationProgress(app),
  isAuthorized: selectIsUserAuthorized(app),
});

const mapActionsToProps = {
  checkAuthorization: AppActions.checkAuthorization,
};

class App extends Component {
  static propTypes = {
    isAuthorized: PropTypes.bool,
    progress: PropTypes.string,
    checkAuthorization: PropTypes.func,
  }

  componentDidMount() {
    this.props.checkAuthorization();
  }

  render() {
    const { progress, isAuthorized } = this.props;

    if (
      progress === progressTypes.IDLE ||
      progress === progressTypes.WORK
    ) {
      return (
        <Loader size={LOADER_SIZES.LARGE} />
      );
    }

    return (
      <div className="app">
        <BrowserRouter>
          <Switch>
            <PrivateRoute exact path="/" component={Report} isUserAuthorized={isAuthorized} />
            <PrivateRoute path="/olap" component={Olap} isUserAuthorized={isAuthorized} />
            <PrivateRoute path="/directory" component={Directory} isUserAuthorized={isAuthorized} />
            {/* <PrivateRoute path="/indicators" component={Indicators} isUserAuthorized={isAuthorized} /> */}
            {/* <PrivateRoute path="/my-groups" component={MyGroups} isUserAuthorized={isAuthorized} /> */}
            <Route path="/signin" component={Signin} />
            <Route path="/signout" component={Signout} />
            <Route component={NoMatch} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(App);
