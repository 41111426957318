import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createHistory from 'history/createBrowserHistory';
import { ConnectedRouter } from 'react-router-redux';
import { addLocaleData, IntlProvider } from 'react-intl';
import appLocaleData from 'react-intl/locale-data/ru';
import { LocaleProvider } from 'antd';
import antdRu from 'antd/lib/locale-provider/ru_RU';
import * as Sentry from '@sentry/browser';


import createStore from 'globalRedux';
import App from 'containers/App/App';
import ruMessages from 'locales/ru.json';

const appLocale = {
  messages: {
    ...ruMessages,
  },
  antd: antdRu,
  locale: 'ru-RU',
  data: appLocaleData,
};
const history = createHistory();
const store = createStore(history);

addLocaleData(appLocale.data);

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

class Application extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <LocaleProvider locale={appLocale.antd}>
            <IntlProvider locale={appLocale.locale} messages={appLocale.messages}>
              <App />
            </IntlProvider>
          </LocaleProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

ReactDOM.render(<Application />, document.getElementById('root'));
