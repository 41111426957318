export const insertItem = (array, action) => ([
  ...array.slice(0, action.index),
  action.item,
  ...array.slice(action.index),
]);

export const removeItem = (array, action) => [
  ...array.slice(0, action.index),
  ...array.slice(action.index + 1),
];

export default {
  insertItem,
  removeItem,
};
