import React, { Component } from 'react';
import { Spin, Layout } from 'antd';
import ReactTable from "react-table";
import progressTypes from 'constants/progressTypes';
import { tableText as tableDefaultTexts} from 'constants/tableSettings';
import DirectoryHeader from 'components/DirectoryHeader';
import { parsePercents } from 'utils/parseValues'; 
import getXlsx from 'utils/xlsx';
import 'react-table/react-table.css';
import './DirectoryAteResults.scss';

var { Content } = Layout;

var HEADERS = [{
  Header: 'Группы участников',
  accessor: 'name',
}, {
  Header: 'Количество ОО',
  accessor: 'count_oo',
  className: 'rt-td_right',
}, {
  Header: 'Доля ОО, в которых есть классы с углубленным изучением отдельных предметов',
  accessor: 'countGlub',
  id: 'countGlub',
  className: 'rt-td_right',
}, {
  Header: 'Среднее количество обучающихся в ОО ',
  accessor: 'count_stud',
  className: 'rt-td_right',
}, {
  Header: 'Среднеквадратичное отклонение количества обучающихся в ОО ',
  accessor: 'srednekvadrCount',
  className: 'rt-td_right',
}, {
  Header: 'Доля ОО с низкой транспортной доступностью',
  accessor: 'countTransportLow',
  className: 'rt-td_right',
}, {
  Header: 'Доля ОО со средней транспортной доступностью',
  accessor: 'countTransportMid',
  className: 'rt-td_right',

}, {
  Header: 'Доля ОО с высокой транспортной доступностью',
  accessor: 'countTransportHigh',
  className: 'rt-td_right',
}];

class DirectoryAteResults extends Component {
  downloadTable = (result) => {
    var title = 'ATE';
    getXlsx(title, HEADERS, result);
  }

  formatResults = (results) =>
    results.map(({ count_transport_high, count_transport_mid, count_transport_low, ...result }) => {
      var total = count_transport_high + count_transport_mid + count_transport_low;
      return {
        ...result,
        countGlub: parsePercents(result.count_glub, result.count_oo),
        srednekvadrCount: parseFloat(result.srednekvadr.toFixed(1)),
        countTransportLow: parsePercents(count_transport_low, total),
        countTransportHigh: parsePercents(count_transport_high, total),
        countTransportMid: parsePercents(count_transport_mid, total),
      };
    });

  renderError() {
    return (
      <Layout>
        <Content className={'main'}>
          <div className={'content'}>
            <div>Ошибка: данные не были получены</div>
          </div>
        </Content>
      </Layout>
    );
  }

  renderResults = (results) => {

    return (
      <div className="directory__container">
        <ReactTable
          data={results}
          columns={HEADERS}
          minRows={0}
          {...tableDefaultTexts}
        />
      </div>
    );
  }

  renderData() {
    var { results: { results, count } } = this.props;
    var headerText = '';
    if (count == 0) {
      headerText = 'Нет результатов';
    }

    var formattedResults = this.formatResults(results);

    return (
      <div className="directory__container">
        <DirectoryHeader
          text={headerText}
          isShown={false}
          isDownload={true}
          onDownload={() => this.downloadTable(formattedResults)}
          onShowModal={() => {}}
        />
        {count > 0 && this.renderResults(formattedResults)}
      </div>
    );
  }

  renderLoader() {
    return (
      <Spin className="loading" size='large' />
    );
  }

  render() {
    var { progress } = this.props;

    switch (progress) {
      case progressTypes.IDLE: {
        return this.renderLoader();
      }
      case progressTypes.WORK: {
        return this.renderLoader();
      }
      case progressTypes.SUCCESS: {
        return this.renderData();
      }
      case progressTypes.ERROR: {
        return this.renderError();
      }
      default: {
        return null;
      }
    }
  }
}

export default DirectoryAteResults;