import XLSX from 'xlsx';
import FileSaver from 'file-saver';

var exportToXlsx = function (title, headers, data) {
  var headerList = headers.map(header => header.Header);
  var accessorsList = headers.map(header => header.accessor);
  var formattedData = data.map(item => (
    accessorsList.reduce(function reducer(acc, key) {
      var res = item[key];
      if (Array.isArray(res)) {
        res = res.map(({ name }) => name).join(' ');
      }
      return [
        ...acc,
        res,
      ];
    }, [])
  ));
  downloadXlsx([
    [title],
    headerList,
    ...formattedData,
  ]);
};

function stringToBuffer(s) {
  var buffer = new ArrayBuffer(s.length);
  var view = new Uint8Array(buffer);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xFF;
  }
  return buffer;
}

function downloadXlsx(data) {
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Report");
  const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
  var title = data[0][0];
  FileSaver.saveAs(new Blob([stringToBuffer(wbout)], { type: "application/octet-stream" }), `${title}.xlsx`);
};

export default exportToXlsx;