import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import SelectAll from 'components/SelectAll/SelectAll';
import ReportList from 'components/ReportList/ReportList';
import ReportActions, {
  selectReports,
  selectIsCheckedAllReports,
  selectIsIndeterminateReports,
} from 'redux/report';
import './ReportFilterReportsPopup.css';
import { isDevelopment } from 'utils/isDevelopment';

var NO_PROC = [8, 9, 12];

// TODO выборку отчётов делать на бэке
var SCHOOL_USER_REPORTS_HIDDEN = [7, 12];
var NOT_SCHOOL_USER_REPORTS_HIDDEN = [14]

const mapStateToProps = ({ report }) => ({
  reports: selectReports(report),
  isCheckedAll: selectIsCheckedAllReports(report),
  isIndeterminate: selectIsIndeterminateReports(report),
});

const mapActionsToProps = {
  checkReport: ReportActions.checkReport,
  checkAllReport: ReportActions.checkAllReport,
};


class ReportFilterReportsPopup extends Component {
  static propTypes = {
    reports: PropTypes.array,
    isCheckedAll: PropTypes.bool,
    isIndeterminate: PropTypes.bool,
    checkReport: PropTypes.func,
    checkAllReport: PropTypes.func,
  }

  componentDidMount() {
    if (isDevelopment) {
      // this.props.checkReport(3);
      // this.props.checkReport(4);
      // this.props.checkReport(6);
      // this.props.checkReport(7);
      // this.props.checkReport(2);
      // this.props.checkReport(9);
    }
  }

  handleSelectReport = (id) => {
    this.props.checkReport(id);
  }

  handleSelectAll = () => {
    this.props.checkAllReport();
  }

  render() {
    const {
      reports,
      isCheckedAll,
      isIndeterminate,
      isSchoolUser,
    } = this.props;
    let filteredReports = reports;
    if (isSchoolUser) {
      filteredReports = reports.filter(report => !SCHOOL_USER_REPORTS_HIDDEN.includes(report.report_type));
    }

    return (
      <div className="report-filter-reports-popup">
        <div className="report-filter-reports-popup__header">
          <SelectAll
            checked={isCheckedAll}
            indeterminate={isIndeterminate}
            onChange={this.handleSelectAll}
          />
        </div>
        <div className="report-filter-reports-popup__content">
          <ReportList
            items={sortBy(filteredReports, 'report_type')}
            onSelect={this.handleSelectReport}
            onCheck={this.handleSelectReport}
            noProcIds={NO_PROC}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportFilterReportsPopup);
