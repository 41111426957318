import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, CartesianGrid, ScatterChart, Scatter, XAxis, YAxis, Tooltip } from 'recharts';
import './ReportSeven.css';

class ReportSeven extends Component {
  static propTypes = {
    data: PropTypes.array,
    procedures: PropTypes.array,
  }


  renderTooltip({ payload }) {
    if (payload.length) {
      const item = payload[0].payload;
      return (
        <div className="report-seven__tooltip">
          <div className="report-seven__tooltip-row">
            <div className="report-seven__tooltip-title">{item.name}</div>
          </div>
          <div className="report-seven__tooltip-row">
            <div className="report-seven__tooltip-item">{item.firstName}</div>
            <div className="report-seven__tooltip-separator">:</div>
            <div className="report-seven__tooltip-value">{item.first}</div>
          </div>
          <div className="report-seven__tooltip-row">
            <div className="report-seven__tooltip-item">{item.secondName}</div>
            <div className="report-seven__tooltip-separator">:</div>
            <div className="report-seven__tooltip-value">{item.second}</div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { data, procedures } = this.props;

    if (procedures.length !== 2) {
      return (
        <div>
          <div className="report-seven">
            <div className="report-seven__no-data">Выберите регион или муниципалитет и две оценочные процедуры</div>
          </div>
        </div>
      );
    }

    const [first, second] = procedures;

    const firstProcedure = first.procedure;
    const secondProcedure = second.procedure;

    const firstName = `${firstProcedure.name} ${firstProcedure.year.name} ${firstProcedure.level.name} класс ${firstProcedure.subject.name}`;
    const secondName = `${secondProcedure.name} ${secondProcedure.year.name} ${secondProcedure.level.name} класс ${secondProcedure.subject.name}`;

    const reportsData = data.map((item, key) => {
      const membersFirst = first.members[key];
      const membersSecond = second.members[key];
      return {
        items: membersFirst.items.map((item, index) => {
          const itemFirst = membersFirst.items[index];
          const itemSecond = membersSecond.items[index];

          if (
            !itemFirst.attribs[0]
            || !itemSecond.attribs[0]
            || !parseFloat(itemFirst.attribs[0].value)
            || !parseFloat(itemSecond.attribs[0].value)
          ) {
            return{
              first: null,
              second: null,
            };
          }

          return {
            firstName,
            secondName,
            name: itemFirst.short_name,
            first: parseFloat(parseFloat(itemFirst.attribs[0].value).toFixed(2)),
            second: parseFloat(parseFloat(itemSecond.attribs[0].value).toFixed(2)),
            r: 15,
          };
        }),
      };
    });


    return (
      <div>
        {data.map((item, key) => (
          <div className="report-seven" key={key}>
            <div className="report-seven__name">{item.checkedMembersNames[key]}</div>
            <ResponsiveContainer aspect={1.0 / 0.25} width='100%'>
              <ScatterChart
                width={600}
                height={300}
                margin={{ top: 40, right: 41, left: 5, bottom: 40 }}
              >
                <XAxis
                  dataKey={'second'}
                  name={secondName}
                  type="number"
                  label={{ value: secondName, offset: -10, position: 'insideBottom' }}
                />
                <YAxis
                  dataKey={'first'}
                  name={firstName}
                  type="number"
                  label={{ value: firstName, offset: 10,  angle: -90, position: 'insideStart' }}
                />
                <CartesianGrid />
                <Scatter name='Школы' data={reportsData[key].items} r={15} fill='#add8f7'/>
                <Tooltip content={this.renderTooltip} />
              </ScatterChart>
            </ResponsiveContainer>
          </div>
        ))}
      </div>
    );
  }

}

export default ReportSeven;
