import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import './SelectAll.css';

class SelectAll extends Component {
  static propTypes = {
    indeterminate: PropTypes.bool,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
  }

  render() {
    const {
      indeterminate,
      checked,
      onChange,
    } = this.props;

    return (
      <div className="select-all" onClick={onChange}>
        <div className="select-all__label">{'выбрать все'}</div>
        <div onClick={onChange}>
          <Checkbox
            indeterminate={!checked ? indeterminate : false}
            checked={checked}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
}

export default SelectAll;
