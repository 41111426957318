import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Form, Icon, Input, Button, Alert } from 'antd';
import progressTypes from 'constants/progressTypes';
import { selectIsUserAuthorized } from 'redux/app';
import SigninActions, {
  selectUsername,
  selectPassword,
  selectSigninSendProgress,
  selectAuthLink,
} from 'redux/signin';
import lockImg from './lock.svg';
import './Signin.css';


const mapStateToProps = ({ signin, app }) => ({
  isUserAuthorized: selectIsUserAuthorized(app),
  username: selectUsername(signin),
  password: selectPassword(signin),
  signinSendProgress: selectSigninSendProgress(signin),
  authLink: selectAuthLink(signin),
});

const mapActionsToProps = {
  onChangeEmail: SigninActions.changeUsername,
  onChangePassword: SigninActions.changePassword,
  onSendSignin: SigninActions.sendSignin,
  onGetOauth: SigninActions.getOauth,
};


class Signin extends Component {
  static propTypes = {
    isUserAuthorized: PropTypes.bool,
    username: PropTypes.string,
    password: PropTypes.string,
    signinSendProgress: PropTypes.string,
    onChangeEmail: PropTypes.func,
    onChangePassword: PropTypes.func,
    onSendSignin: PropTypes.func,
  }

  state = {
    usernameValidateStatus: '',
    usernameHelp: '',
    passwordValidateStatus: '',
    passwordHelp: '',
  }

  componentDidUpdate(prevProps) {
    var { authLink } = this.props;
    if (prevProps.authLink === "" && authLink !== "") {
      window.location.href = authLink;
    }
  }

  handleChangeEmail = (event) => {
    this.setState({
      usernameValidateStatus: '',
      usernameHelp: '',
    });
    this.props.onChangeEmail(event.target.value);
  };

  handleChangePassword = (event) => {
    this.setState({
      passwordValidateStatus: '',
      passwordHelp: '',
    });
    this.props.onChangePassword(event.target.value);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { username, password } = this.props;
    let isFormInvalid = false;

    if (username === '') {
      this.setState({
        usernameValidateStatus: 'error',
        usernameHelp: 'Ввидите логин',
      });
      isFormInvalid = true;
    }

    if (password === '') {
      this.setState({
        passwordValidateStatus: 'error',
        passwordHelp: 'Ввидите пароль',
      });
      isFormInvalid = true;
    }

    if (!isFormInvalid) {
      this.props.onSendSignin();
    }
  }

  render() {
    const {
      username,
      password,
      signinSendProgress,
      isUserAuthorized,
    } = this.props;

    if (isUserAuthorized) {
      return (
        <Redirect to={{ pathname: '/' }} />
      );
    }

    const {
      usernameValidateStatus,
      usernameHelp,
      passwordValidateStatus,
      passwordHelp,
    } = this.state;

    const isDisabled = signinSendProgress === progressTypes.WORK;
    const isErrorShow = signinSendProgress === progressTypes.ERROR;

    return (
      <div className="signin-wrapper">
        <div className="signin">
          <div className="signin-header">
            <div className="signin-header__title">ФИС ОКО</div>
            <img src={lockImg} role="presentation" alt="presentation" className="signin-header__icon-img" />
          </div>

          <div className="signin-content">
            <Button
              onClick={this.props.onGetOauth}
              type="primary"
            >Войти в систему</Button>
            {/* <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item
                validateStatus={usernameValidateStatus}
                help={usernameHelp}
              >
                <Input
                  prefix={<Icon type="user" style={{ fontSize: 13 }} />}
                  placeholder="Логин"
                  value={username}
                  disabled={isDisabled}
                  onChange={this.handleChangeEmail}
                />
              </Form.Item>
              <Form.Item
                validateStatus={passwordValidateStatus}
                help={passwordHelp}
              >
                <Input
                  prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
                  type="password"
                  placeholder="Пароль"
                  disabled={isDisabled}
                  value={password}
                  onChange={this.handleChangePassword}
                />
              </Form.Item>

                {isErrorShow && <div className="signin__error"><Alert message="Неверный логин или пароль" type="error" /></div>}

              <Form.Item>
                <Button
                  loading={isDisabled}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Войти
                </Button>
              </Form.Item>
            </Form> */}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(Signin);
