import { createActions, createReducer } from 'reduxsauce';
import progressTypes from 'constants/progressTypes';

/* ------------- Types and Action Creators ------------- */
const { Creators } = createActions({
  checkAuthorization: null,
  checkAuthorizationSuccess: ['user'],
  checkAuthorizationError: null,
});

export default Creators;

const Types = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  CHECK_AUTHORIZATION_SUCCESS: 'CHECK_AUTHORIZATION_SUCCESS',
  CHECK_AUTHORIZATION_ERROR: 'CHECK_AUTHORIZATION_ERROR',
};

export const AppTypes = Types;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  user: null,
  isUserAuthorized: false,
  checkAuthorizationProgress: progressTypes.IDLE,
};

/* ------------- Reducers ------------- */
export const checkAuthorization = (state) => ({
  ...state,
  isUserAuthorized: false,
  checkAuthorizationProgress: progressTypes.IDLE,
});

export const checkAuthorizationSuccess = (state, { user }) => ({
  ...state,
  user,
  isUserAuthorized: true,
  checkAuthorizationProgress: progressTypes.SUCCESS,
});

export const checkAuthorizationError = (state) => ({
  ...state,
  user: null,
  isUserAuthorized: false,
  checkAuthorizationProgress: progressTypes.ERROR,
});

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHECK_AUTHORIZATION]: checkAuthorization,
  [Types.CHECK_AUTHORIZATION_SUCCESS]: checkAuthorizationSuccess,
  [Types.CHECK_AUTHORIZATION_ERROR]: checkAuthorizationError,
});

/* ------------- Selectors ------------- */
export const selectCheckAuthorizationProgress = (appState) => appState.checkAuthorizationProgress;
export const selectIsUserAuthorized = (appState) => appState.isUserAuthorized;
export const selectUser = (appState) => appState.user;
export const selectUserName = ({ user }) => user.username || user.email;
export const selectSchoolUser = state => state.user.school;