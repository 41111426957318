import React from 'react';
import { Affix, Button } from 'antd';

const DirectoryHeader = props => {
  var { text, isShown, isDownload } = props;
  return (
    <Affix>
      <div className="directory__container--title">
        <div>{text}</div>
        <div className="directory-container__buttons">
          {isShown && <Button
            type="dashed"
            size="small"
            className="directory-container__buttons_group"
            onClick={props.onShowModal}
          >Сохранить группу</Button>}
          {
            isDownload && (
              <Button
                type="primary"
                size="small"
                onClick={props.onDownload}
              >Выгрузить в XLS</Button>
            )
          }
        </div>
      </div>
    </Affix>
  );
};

export default DirectoryHeader;