import React, { Component } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import first from 'lodash/first';
import { Sticky, StickyContainer } from 'react-sticky';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';

import groupDigits from 'utils/groupDigits';
import './ReportFour.css';
import arrowUpIcon from './arrow-up.svg';
import arrowDownIcon from './arrow-down.svg';

class ReportFour extends Component {
  static propTypes = {
    data: PropTypes.array,
  }

  state = {
    showedCols: [],
  }

  renderTooltip({ payload }) {
    if (payload.length) {
      const item = payload[0].payload;
      return (
        <div className="report-four__tooltip">
          <div className="report-four__tooltip-item">{item.value}%</div>
          <div className="report-four__tooltip-separator">|</div>
          <div className="report-four__tooltip-item">{item.allValue}%</div>
        </div>
      );
    }
  }


  handleClick = (id) => {
    const { showedCols } = this.state;
    if (showedCols.indexOf(id) !== -1) {
      showedCols.splice(showedCols.indexOf(id), 1);
    } else {
      showedCols.push(id);
    }
    this.setState({ showedCols });
  }

  render() {
    const { showedCols } = this.state;
    const { data } = this.props;

    const columns = first(data).columns;
    const countp = find(data[0].paramsAll.attribs, item => item.name === 'COUNTP');

    return (
      <StickyContainer key={data[0].id} className="report-four__container">
        <div className="report-four">
          <Sticky>
            {
              ({ style }) => (
                <div style={{...style, zIndex: 1}}>
                  <div className="report-four__header">
                    <div className="report-four__header-member">
                      <div>Группы</div>
                      <div>участников</div>
                    </div>
                    <div className="report-four__header-countp">
                      <div>Кол-во</div>
                      <div>участников</div>
                    </div>

                    <div className="report-four__header-balls">
                      <div className="report-four__header-balls-title">Распределение групп баллов в %</div>
                      <div className="report-four__header-balls-cols">
                        {columns.filter(item => item.name !== 'COUNTP' && item.name !== 'BADSCHOOL').map(((item, key) => (
                          <div className="report-four__header-balls-col" key={key}>
                            {item.label}
                          </div>
                        )))}
                      </div>
                    </div>
                    <div className="report-four__header-chart">
                      Общая гистограмма отметок
                    </div>
                  </div>

                  <div className="report-four__sum">
                    <div className="report-four__sum-member">Вся выборка</div>
                    <div className="report-four__sum-countp">{countp ? groupDigits(countp.value) : ''}</div>
                    <div className="report-four__sum-cols">
                      {columns.sort((a, b) => a.position - b.position).filter(item => item.name !== 'COUNTP' && item.name !== 'BADSCHOOL').map(((item, key) => {
                        const cell = find(data[0].paramsAll.attribs, cell => cell.name === item.name);
                        return (
                          <div className="report-four__sum-col" key={`report-four__sum-${item.name}__${key}`}>
                            {(cell && cell.value) ? parseFloat(cell.value) : '-'}
                          </div>
                        );
                      }))}
                    </div>
                    <div className="report-four__sum-chart"></div>
                  </div>
                </div>
              )
            }
          </Sticky>

          {data.map((member, key) => {

            const isBlack = find(member.attribs, attribs => attribs.name === 'BADSCHOOL');

            if (!member.attribs.length) {
              return (
                <div className="report-four__row" key={`report-four__row__${key}`}>
                  <div className="report-four__row-data">
                    <div className={
                      (!isBlack)
                        ? 'report-four__row-member report-four__row-member_empty-data'
                        : 'report-four__row-member report-four__row-member_empty-data report-four__row-member_bad'}
                    >
                      {member.short_name || member.name}
                    </div>
                    <div className="report-four__row-no-data">
                      Нет данных
                    </div>
                  </div>
                </div>
              );
            }

            const chartData = member.columns
              .sort((a, b) => a.position - b.position)
              .filter(item => item.name !== 'COUNTP' && item.name !== 'BADSCHOOL')
              .map((attribsItem, index) => {
                const all = find(member.paramsAll.attribs, (item) => item.name === attribsItem.name);
                const attr = find(member.attribs, (item) => item.name === attribsItem.name);

                return {
                  ...attribsItem,
                  allValue: all ? all.value : null,
                  value: parseFloat(attr.value),
                  label: `${attribsItem.label}`,
                };
              });

            return (
              <div className="report-four__row" key={`report-four__row__${key}`}>
                <div className="report-four__row-data">
                  <div className={!isBlack ? 'report-four__row-member' : 'report-four__row-member report-four__row-member_bad'}>
                    {member.short_name || member.name}
                  </div>
                  <div className={!isBlack ? 'report-four__row-countp' : 'report-four__row-countp report-four__row-countp_bad'}>
                    {groupDigits(member.attribs.find(item => item.name === 'COUNTP').value)}
                  </div>
                  <div className={!isBlack ? 'report-four__row-cols' : 'report-four__row-cols report-four__row-cols_bad'}>
                    {member.columns
                      .sort((a, b) => a.position - b.position)
                      .filter(item => item.name !== 'COUNTP' && item.name !== 'BADSCHOOL')
                      .map(((col, colKey) => {
                        const attr = find(member.attribs, (attribsItem) => col.name === attribsItem.name);
                        return (
                          <div className="report-four__row-col" key={`report-four__row-col__${key}__${col}__${colKey}`}>
                            {parseFloat(attr.value)}
                          </div>
                        );
                      }))}
                  </div>
                  <div className="report-four__row-chart">
                    <div className="report-four__row-chart-item">
                      <BarChart
                        width={80}
                        height={21}
                        barCategoryGap={5}
                        barGap={1}
                        margin={{ top: 0, right: 3, bottom: 1, left: 3 }}
                        data={chartData}
                      >
                        <Bar dataKey='value' barSize={6} fill='#add8f7'/>
                        <Bar dataKey='allValue' barSize={6} fill='#e7ebee'/>
                      </BarChart>
                    </div>
                    <img
                      onClick={() => this.handleClick(key)}
                      src={(showedCols.indexOf(key) !== -1) ? arrowUpIcon : arrowDownIcon}
                      alt="раскрыть отчет"
                      className="report-four__row-chart-icon"
                    />
                  </div>
                </div>

                {(showedCols.indexOf(key) !== -1) && (
                  <div className="report-four__row-big-chart">
                    <div className="report-four__row-big-chart-inner">
                      <ResponsiveContainer aspect={1.0 / 0.25} width='100%'>
                        <BarChart
                          data={chartData}
                          margin={{ top: 50, right: 40, left: 0, bottom: 20 }}
                        >
                          <XAxis dataKey="label"/>
                          <YAxis/>
                          <CartesianGrid strokeDasharray="2 2" vertical={false}/>
                          <Tooltip cursor={false} content={this.renderTooltip}/>
                          <Legend/>
                          <Bar dataKey={'value'} fill="#add8f7" barSize={48} name={member.short_name || member.name}/>
                          <Bar dataKey={'allValue'} fill="#e7ebee" barSize={48} stroke="#c9dae7" name={'Вся выборка'}/>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </StickyContainer>
    );
  }

}

export default ReportFour;
