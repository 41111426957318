import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DirectoryFilterWrapper from 'components/DirectoryFilterWrapper';
import DirectoryActions, { selectDistrictsProgress, selectYears, selectOptions } from 'redux/directory';
import isEmpty from 'lodash/isEmpty';
import DirectoryFilterOptionsPopup from 'containers/DirectoryFilterOptionsPopup';
import './DirectoryFilter.css';

const mapStateToProps = ({
  directory,
}) => ({
  districtsProgress: selectDistrictsProgress(directory),
  years: selectYears(directory),
  options: selectOptions(directory),
});

const mapActionsToProps = {
  getDirectoryDistricts: DirectoryActions.getDirectoryDistricts,
  updateOption: DirectoryActions.updateOption,
};

class DirectoryFilter extends Component {

  static propTypes = {
    regions: PropTypes.array,
    districts: PropTypes.array,
    onSubmit: PropTypes.func,
    progress: PropTypes.string,
    getDirectoryDistricts: PropTypes.func,
  }

  handleChange = (name, value) => {
    this.props.updateOption(name, value);

    if (name === 'school__region' && value) {
      this.props.getDirectoryDistricts(value);
      this.props.updateOption('school__city', undefined);
    }
  }

  getParams = () => {
    var { options } = this.props;
    const params = {};

    Object.keys(options).forEach(key => {
      const item = options[key];
      if (item) {
        params[key] = item;
      }
    });

    return params;
  }

  isFormInvalid = () => {
    const params = this.getParams();
    return !(params.school__region && params.study_year && params.study_year.length > 0);
  }

  handleSubmit = () => {
    const params = this.getParams();
    // особенности школы
    if (!isEmpty(params)) {
      var { type } = this.props;
      params.type = type;
      
      this.props.onSubmit(params);
      this.props.onHandleClose();
    }
  }

  render() {
    const {
      regions,
      districts,
      progress,
      districtsProgress,
      years,
      options,
    } = this.props;

    return (
      <DirectoryFilterOptionsPopup
        regions={regions}
        districts={districts}
        progress={progress}
        years={years}
        districtsProgress={districtsProgress}
        handleChange={this.handleChange}
        data={options}
        onSubmit={this.handleSubmit}
        onFormInvalid={this.isFormInvalid()}
      />
    );
  }
}

export default connect(
  mapStateToProps, 
  mapActionsToProps
)(DirectoryFilterWrapper({ name: 'Справочник ОО' })(DirectoryFilter));
