import React from 'react';
import { Layout } from 'antd';
import DocumentTitle from 'react-document-title';
import Header from 'components/Header/Header';
import Iframe from 'react-iframe';

const Olap = () => (
  <DocumentTitle title={'ФИС-ОКО - OLAP-АНАЛИЗ'}>
    <Layout className="layout">
      <Header/>
      <div className="layout__iframe">
        <Iframe
          url="https://cube.fis-oko.sgdev.xyz/"
          width="100%"
          height="100%"
          styles={{ height: '100%' }}
          allowFullScreen
        />
      </div>
    </Layout>
  </DocumentTitle>
);

export default Olap;
