import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Popover } from 'antd';
import first from 'lodash/first';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import has from 'lodash/has';
import isNull from 'lodash/isNull';

import groupDigits from 'utils/groupDigits';
import './ReportSix.css';

const parseValue = (value) => value === '-1' || isNull(value) ? '-' : parseFloat(value);
const parseChartValue = (value) => value === '-1' || isNull(value) ? null : parseFloat(value);

class ReportSix extends Component {
  static propTypes = {
    data: PropTypes.array,
  }

  renderTooltip({ payload }) {
    if (payload.length) {
      const item = payload[0].payload;
      return (
        <div className="report-six__tooltip">
          <div>{item.name}</div>
          <div>{item.task.split(0, 100)}</div>
          <div>Ср.% вып. уч. гр. баллов 2: {item.p2}</div>
          <div>Ср.% вып. уч. гр. баллов 3: {item.p3}</div>
          <div>Ср.% вып. уч. гр. баллов 4: {item.p4}</div>
          <div>Ср.% вып. уч. гр. баллов 5: {item.p5}</div>
        </div>
      );
    }
  }

  renderMiddleCol(item, cols, key, text) {
    return (
      <div className="report-six__row-main">
        <div className="report-six__row-middle-col-name">{text}</div>
        <div
          className="report-six__row-countp">{parseValue(item.attribs.find(item => item.name === 'COUNTP')[key])}</div>
        <div className="report-six__row-cols">
          {cols.filter(item => item.name !== 'COUNTP').map(((attr, attrKey) => (
            <div className="report-six__row-col" key={`report-six-row__${attr.name}__${attrKey}__${key}`}>
              {parseValue(attr[key])}
            </div>
          )))}
        </div>
      </div>
    );
  }

  renderGroups(item, cols, chartData) {
    return (
      <div className="report-six__row-chart">
        {this.renderMiddleCol(item, cols, 'p2', 'Ср.% вып. уч. гр. баллов 2')}
        {this.renderMiddleCol(item, cols, 'p3', 'Ср.% вып. уч. гр. баллов 3')}
        {this.renderMiddleCol(item, cols, 'p4', 'Ср.% вып. уч. гр. баллов 4')}
        {this.renderMiddleCol(item, cols, 'p5', 'Ср.% вып. уч. гр. баллов 5')}

        <div className="report-six__chart-title">Ср. % выполнения заданий группами участников</div>

        <ResponsiveContainer aspect={1.0 / 0.25} width='100%'>
          <LineChart
            width={600}
            height={300}
            data={chartData}
            margin={{ top: 40, right: 41, left: 5, bottom: 20 }}
          >
            <XAxis dataKey="name"/>
            <XAxis dataKey="task"/>
            <YAxis/>
            <CartesianGrid strokeDasharray="3 3" vertical={false}/>
            <Tooltip content={this.renderTooltip} />
            <Legend/>
            <Line type="linear" strokeWidth={3} dataKey="p2" name='Ср.% вып. уч. гр. баллов 2' stroke="#8bceff"/>
            <Line type="linear" strokeWidth={3} dataKey="p3" name='Ср.% вып. уч. гр. баллов 3' stroke="#3aca60"/>
            <Line type="linear" strokeWidth={3} dataKey="p4" name='Ср.% вып. уч. гр. баллов 4' stroke="#393e4a"/>
            <Line type="linear" strokeWidth={3} dataKey="p5" name='Ср.% вып. уч. гр. баллов 5' stroke="#8884d8"/>
          </LineChart>
        </ResponsiveContainer>

      </div>
    );
  }

  render() {
    const { data } = this.props;

    const columns = first(data).columns;
    const validColumns = sortBy(columns, item => item.position).map(item => item.name);

    const paramsAll = data[0].paramsAll.attribs;
    const paramsAllCountP = find(data[0].paramsAll.attribs, cell => cell.name === 'COUNTP');

    return (
      <div className="report-six">
        <div className="report-six__header">
          <div className="report-six__header-member">
            <div>Группы</div>
            <div>участников</div>
          </div>
          <div className="report-six__header-countp">
            <div>Кол-во</div>
            <div>участников</div>
          </div>
          <div className="report-six__header-cols">
            <div className="report-six__header-cols-title">Задания</div>
            <div className="report-six__header-cols-wrapper">
            {sortBy(columns, item => item.position).filter(item => item.name !== 'COUNTP').map(((item, key) => {
              const label = item.label.replace('_S', '');
              const task = find(data[0].tasks, item => item.column_name === label);
              return (
                <div className="report-six__header-col" key={`header-${item.name}__${key}`}>
                  <div className="report-six__header-meta">
                    <Popover content={(
                      <div className="report-one__popover">
                        {task ? (task.task) : 'Для данного задания нет описания'}
                      </div>
                    )} title={label}>
                      <div className="report-six__header-col-title">
                        {label}
                      </div>
                    </Popover>
                  </div>
                </div>
              );
            }))}
            </div>
            <div className="report-six__header-cols-subtitle">% выполнения заданий</div>
          </div>
        </div>

        <div className="report-six__sum">
          <div className="report-six__sum-member">Вся выборка</div>
          <div className="report-six__sum-countp">{paramsAllCountP ? groupDigits(paramsAllCountP.value) : ''}</div>
          <div className="report-six__sum-cols">

            {columns.sort((a, b) => a.position - b.position).filter(item => item.name !== 'COUNTP').map(((item, key) => {
              const cell = find(paramsAll, cell => cell.name === item.name);
              return (
                <div className="report-six__sum-col" key={`sum-${item.name}__${key}`}>
                  {(cell && cell.value) ? parseFloat(cell.value).toFixed(0) : '-'}
                </div>
              );
            }))}
          </div>
        </div>

        {data.map((item, key) => {
          if (has(item, 'district') && has(item, 'city') && has(item, 'region')) {
            return null;
          }

          const isBlack = find(item.attribs, attribs => attribs.name === 'BADSCHOOL');

          if (!item.attribs.length) {
            return (
              <div className="report-six__row" key={`report-six-row__${key}`}>
                <div className="report-six__row-main">
                <div className={!isBlack ? 'report-six__row-member' : 'report-six__row-member report-six__row-member_black'}>
                  {item.short_name || item.name}
                </div>
                <div className="report-six__no-data">
                    Нет данных
                </div>
                </div>
              </div>
            );
          }

          const cols = validColumns.map(validCol => {
            return find(item.attribs, (attr) => {
              return attr.name === validCol;
            });
          });

          let hasDataForChart = false;

          const chartData = cols.filter(col => col && col.name !== 'COUNTP').map(col => {
            const name = col.name.replace('_S', '');
            const task = find(item.tasks, task => task.column_name === name);
            const data = {
              name: name,
              task: task ? task.task : 'Для данного задания нет описания',
              p2: parseChartValue(col.p2),
              p3: parseChartValue(col.p3),
              p4: parseChartValue(col.p4),
              p5: parseChartValue(col.p5),
            };

            if (
              !isNull(data.p2) ||
              !isNull(data.p3) ||
              !isNull(data.p4) ||
              !isNull(data.p5)
            ) {
              hasDataForChart = true;
            }
            return data;
          });


          return (
            <div className="report-six__row" key={`report-six-row__${key}`}>
              <div className="report-six__row-main">
                <div className={!isBlack ? 'report-six__row-member' : 'report-six__row-member report-six__row-member_bad'}>
                  {item.short_name || item.name}
                </div>
                <div className="report-six__row-countp">
                  {groupDigits(item.attribs.find(item => item.name === 'COUNTP').value)}
                </div>
                <div className="report-six__row-cols">
                  {cols.filter(item => item.name !== 'COUNTP').map(((attr, attrKey) => (
                    <div className="report-six__row-col" key={`report-six-row__${attr.name}__${attrKey}__${key}`}>
                      {parseFloat(attr.value)}
                    </div>
                  )))}
                </div>
              </div>
              {hasDataForChart ? this.renderGroups(item, cols, chartData) : null}
            </div>
          );
        })}

      </div>
    );
  }

}

export default ReportSix;
