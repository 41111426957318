import { createActions, createReducer } from 'reduxsauce';
import progressTypes from 'constants/progressTypes';
import { isDevelopment } from 'utils/isDevelopment';

const { Creators } = createActions({
  changeUsername: ['username'],
  changePassword: ['password'],
  sendSignin: null,
  sendSigninSuccess: null,
  sendSigninError: ['error'],
  getOauth: null,
  getOauthSuccess: ['link'],
  getOathError: ['error'],
});

export default Creators;

const Types = {
  CHANGE_USERNAME: 'CHANGE_USERNAME',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  SEND_SIGNIN: 'SEND_SIGNIN',
  SEND_SIGNIN_SUCCESS: 'SEND_SIGNIN_SUCCESS',
  SEND_SIGNIN_ERROR: 'SEND_SIGNIN_ERROR',
  GET_OAUTH: 'GET_OAUTH',
  GET_OAUTH_SUCCESS: 'GET_OAUTH_SUCCESS',
  GET_OAUTH_ERROR: 'GET_OAUTH_ERROR',
};

export const SigninTypes = Types;

export const INITIAL_STATE = {
  username: isDevelopment ? 'rinkana' : '',
  password: isDevelopment ? '1234567As' : '',
  sendSigninProgress: progressTypes.IDLE,
  error: null,
  authLink: '',
  authError: null,
};

export const changeUsername = (state, { username }) => ({
  ...state,
  username,
  sendSigninProgress: progressTypes.IDLE,
});

export const changePassword = (state, { password }) => ({
  ...state,
  password,
  sendSigninProgress: progressTypes.IDLE,
});

export const sendSignin = (state) => ({
  ...state,
  sendSigninProgress: progressTypes.WORK,
});

export const sendSigninSuccess = (state) => ({
  ...state,
  sendSigninProgress: progressTypes.SUCCESS,
});

export const sendSigninError = (state, { error }) => ({
  ...state,
  sendSigninProgress: progressTypes.ERROR,
  error: error,
});

export const getOauth = state => state;

export const getOauthSuccess = (state, { link }) => ({
  ...state,
  authLink: link,
});

export const getOathError = (state, { error }) => ({
  ...state,
  authError: error,
});


export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_USERNAME]: changeUsername,
  [Types.CHANGE_PASSWORD]: changePassword,
  [Types.SEND_SIGNIN]: sendSignin,
  [Types.SEND_SIGNIN_SUCCESS]: sendSigninSuccess,
  [Types.SEND_SIGNIN_ERROR]: sendSigninError,
  [Types.GET_OAUTH]: getOauth,
  [Types.GET_OAUTH_SUCCESS]: getOauthSuccess,
  [Types.GET_OAUTH_ERROR]: getOathError,
});

export const selectUsername = (state) => state.username;
export const selectPassword = (state) => state.password;
export const selectSigninSendProgress = (state) => state.sendSigninProgress;
export const selectError = (state) => state.error;
export const selectAuthLink = state => state.authLink;