import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';
import ProcedureList from 'components/ProcedureList/ProcedureList';
import YearList from 'components/YearList/YearList';
import LevelList from 'components/LevelList/LevelList';
import SubjectList from 'components/SubjectList/SubjectList';
import ProcedureTag from 'components/ProcedureTag/ProcedureTag';
import { isDevelopment } from 'utils/isDevelopment';
import './ReportFilterProceduresPopup.css';

const isProcedureValid = (procedure) => {
  let isValid = true;
  if (!procedure) {
    return false;
  }
  if (!procedure.year) {
    isValid = false;
  }
  if (!procedure.level) {
    isValid = false;
  }
  if (!procedure.subject) {
    isValid = false;
  }
  return isValid;
};


class ReportFilterProceduresPopup extends Component {
  static propTypes = {
    tags: PropTypes.array,
    procedures: PropTypes.array,
    addSelectedProcedure: PropTypes.func,
    removeSelectedProcedure: PropTypes.func,
  }

  constructor(props) {
    super(props);
    const { procedures } = props;

    this.state = {
      procedures: procedures.map((item, key) => ({ ...item, checked: false })),
      selectedYear: null,
      selectedLevel: null,
      selectedSubject: null,
    };
  }

  componentDidMount() {
    if (isDevelopment) {
      setTimeout(() => {
        // this.handleCheckProcedure(3, 2);
        // this.handleCheckYear(2018, 0);
        // this.handleCheckLevel(5, 0);
        // this.handleCheckSubject(2, 2);
        // setTimeout(() => {
          // this.handleCheckProcedure(3, 1);
          // this.handleCheckYear(2017, 1);
          // this.handleCheckLevel(5, 0);
          // this.handleCheckSubject(8, 0);
        // }, 500);
      }, 600);
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedProcedure } = this.state;
    const { procedures, checkedUnique } = this.props;

    if (isProcedureValid(selectedProcedure)) {
      this.setState({
        procedures: procedures.map((item, key) => ({ ...item, checked: false })),
        selectedProcedure: null,
        procedureIndex: null,
        selectedYear: null,
        selectedLevel: null,
        selectedSubject: null,
      }, () => {
        this.props.addSelectedProcedure(selectedProcedure);
      });
    }

    if (checkedUnique && !prevProps.checkedUnique) {
      this.props.resetSelectedProcedures();
      setTimeout(() => {
        this.handleCheckProcedure(3, 2);
        this.handleCheckYear(2018, 1);
        this.handleCheckLevel(5, 0);
        this.handleCheckSubject(2, 1);

        this.handleCheckProcedure(3, 2);
        this.handleCheckYear(2018, 1);
        this.handleCheckLevel(5, 0);
        this.handleCheckSubject(1, 0);

        this.handleCheckProcedure(3, 2);
        this.handleCheckYear(2018, 1);
        this.handleCheckLevel(4, 1);
        this.handleCheckSubject(2, 1);

        this.handleCheckProcedure(3, 2);
        this.handleCheckYear(2018, 1);
        this.handleCheckLevel(4, 1);
        this.handleCheckSubject(1, 0);

      }, 600);
    }
  }

  handleCheckProcedure = (id, index) => {
    const { procedures } = this.state;
    const selectedProcedure = procedures[index];

    this.setState({
      selectedProcedure,
      procedures: procedures.map(item => ({
        ...item,
        checked: item.id === id && !item.checked,
        years: item.years.map(item => ({
          ...item,
          checked: false,
          classes: item.classes.map(item => ({
            ...item,
            checked: false,
            subjects: item.subjects.map(item => ({
              ...item,
              checked: false,
            })),
          })),
        })),
      })),
      procedureIndex: index,
      selectedYear: null,
      selectedLevel: null,
      selectedSubject: null,
    });
  }

  handleCheckYear = (id, index) => {
    const { procedures, procedureIndex } = this.state;

    procedures[procedureIndex].years = procedures[procedureIndex].years.map(item => ({
      ...item,
      checked: item.id === id && !item.checked,
      classes: item.classes.map(item => ({
        ...item,
        checked: false,
        subjects: item.subjects.map(item => ({
          ...item,
          checked: false,
        })),
      })),
    }));

    const selectedProcedure = procedures[procedureIndex];

    selectedProcedure.year = procedures[procedureIndex].years[index];

    this.setState({
      procedures,
      selectedProcedure,
      selectedYear: index,
    });
  }

  handleCheckLevel = (id, index) => {
    const { procedures, procedureIndex, selectedYear } = this.state;
    const levels = procedures[procedureIndex].years[selectedYear].classes;
    procedures[procedureIndex].years[selectedYear].classes = levels.map(item => ({
      ...item,
      checked: item.id === id && !item.checked,
      subjects: item.subjects.map(item => ({
        ...item,
        checked: false,
      })),
    }));

    const selectedProcedure = procedures[procedureIndex];

    selectedProcedure.level = levels[index];

    this.setState({
      procedures,
      selectedProcedure,
      selectedLevel: index,
    });
  }

  handleCheckSubject = (id, index) => {
    const { procedures, procedureIndex, selectedYear, selectedLevel } = this.state;
    const subjects = procedures[procedureIndex].years[selectedYear].classes[selectedLevel].subjects;
    procedures[procedureIndex].years[selectedYear].classes[selectedLevel].subjects = subjects.map(item => ({
      ...item,
      checked: item.id === id && !item.checked,
    }));

    const selectedProcedure = procedures[procedureIndex];
    selectedProcedure.subject = subjects[index];

    this.setState({ procedures, selectedProcedure, selectedSubject: index });
  }

  handleRemoveSelectedProcedure = (index) => {
    this.props.removeSelectedProcedure(index);
  }

  render() {
    const { tags } = this.props;
    const { procedures, procedureIndex, selectedYear, selectedLevel } = this.state;

    const procedure = procedures[procedureIndex];
    const years = (procedure && procedure.years) || [];
    const levels = (isNull(selectedYear))
      ? []
      : years[selectedYear].classes;
    let subjects = (isNull(selectedLevel))
      ? []
      : levels[selectedLevel].subjects;

    if (tags.length > 0 && (selectedLevel || selectedLevel === 0)) {
      const selectedProcedureId = procedures[procedureIndex].id;
      const selectedYearId = procedures[procedureIndex].years[selectedYear].id;
      const selectedLevelId = procedures[procedureIndex].years[selectedYear].classes[selectedLevel].id;
      var filteredTags = tags.filter(tag => 
        tag.id == selectedProcedureId && tag.level.id == selectedLevelId && tag.year.id == selectedYearId);
      if (filteredTags.length > 0) {
        const subjectIds = filteredTags.map(tag => tag.subject.id);
        subjects = subjects.map(item => {
          if (subjectIds.includes(item.id)) {
            item.checked = true;
            item.disabled = true;
          } else if (item.disabled) {
            item.checked = false;
            item.disabled = false;
          }
          return item;
        });
      }
    }

    return (
      <div className="report-filter-procedures-popup">
        <div className="report-filter-procedures-popup__tags">
          <span>Выбранные мероприятия:</span>
          {tags.map((item, index) => (
            <ProcedureTag
              key={index}
              index={index}
              procedure={`${item.name}`}
              level={`${item.level.name}`}
              subject={`${item.subject.name}`}
              year={`${item.year.name}`}
              onClick={this.handleRemoveSelectedProcedure}
            />
          ))}
        </div>
        <div className="report-filter-procedures-popup__cols">
          <div className="report-filter-procedures-popup__col">
            <ProcedureList
              items={procedures}
              onSelect={this.handleCheckProcedure}
              onCheck={this.handleCheckProcedure}
            />
          </div>
          <div className="report-filter-procedures-popup__col">
            <YearList
              items={years}
              onSelect={this.handleCheckYear}
              onCheck={this.handleCheckYear}
            />
          </div>
          <div className="report-filter-procedures-popup__col">
            <LevelList
              items={levels.map(item => ({ ...item, name: `${item.name} класс` }))}
              onSelect={this.handleCheckLevel}
              onCheck={this.handleCheckLevel}
            />
          </div>
          <div className="report-filter-procedures-popup__col">
            <SubjectList
              items={subjects}
              onSelect={this.handleCheckSubject}
              onCheck={this.handleCheckSubject}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default (ReportFilterProceduresPopup);
