import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'antd';
import './SortItem.css';

class Sort extends Component {
  static propTypes = {
    id: PropTypes.number,
    text: PropTypes.string,
    direction: PropTypes.string,
    active: PropTypes.bool,
    onClick: PropTypes.func,
  }

  handleClick = () => {
    const { id } = this.props;
    this.props.onClick(id);
  }

  render() {
    const {
      text,
      direction,
      active,
    } = this.props;

    const itemClassName = classnames('sort-item', {
      'sort-item_active': active,
    });

    return (
      <div className={itemClassName} onClick={this.handleClick}>
        <div className="sort-item__text">{text}</div>
        <div className="sort-item__direction">
          {direction === 'asc'
            ? (<Icon type="arrow-down" />)
            : (<Icon type="arrow-up" />)}
        </div>
      </div>
    );
  }
}

export default Sort;
