import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Checkbox, Radio } from 'antd';
import Dotdotdot from 'react-dotdotdot';
import './ReportFilterListItem.css';


export const REPORT_FILTER_LIST_ITEM_TYPES = {
  RADIO: 'RADIO',
  CHECKBOX: 'CHECKBOX',
};

class ReportFilterListItem extends Component {
  static propTypes = {
    id: PropTypes.number,
    code: PropTypes.string,
    index: PropTypes.number,
    name: PropTypes.string,
    selected: PropTypes.bool,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    hasSelectedChildren: PropTypes.bool,
    onSelect: PropTypes.func,
    onCheck: PropTypes.func,
    type: PropTypes.string,
  }

  handleSelect = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { id, index } = this.props;
    this.props.onSelect(id, index);
  }

  handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { id, index } = this.props;
    this.props.onCheck(id, index);
  }

  render() {
    const {
      code,
      name,
      selected,
      checked,
      disabled,
      hasSelectedChildren,
      type,
    } = this.props;

    const className = classnames('report-filter-list-item', {
      'report-filter-list-item_selected': selected,
    });

    return (
      <div className={className} onClick={this.handleSelect}>
        {hasSelectedChildren && <div className="report-filter-list-item__has-selected-children"></div>}
        <div className="report-filter-list-item__meta">
          {code && <div className="report-filter-list-item__code">{code}</div>}
          <div className="report-filter-list-item__name" alt={name} title={name}>
            <Dotdotdot clamp={1}>{name}</Dotdotdot>
          </div>
        </div>
        <div className="report-filter-list-item__checkbox" onClick={this.handleChange}>
          {type === REPORT_FILTER_LIST_ITEM_TYPES.RADIO && (
            <Radio checked={checked} disabled={disabled} />
          )}
          {type === REPORT_FILTER_LIST_ITEM_TYPES.CHECKBOX && (
            <Checkbox checked={checked} disabled={disabled} />
          )}
        </div>
      </div>
    );
  }
}

ReportFilterListItem.defaultProps = {
  type: REPORT_FILTER_LIST_ITEM_TYPES.CHECKBOX,
};

export default ReportFilterListItem;
