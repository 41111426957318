import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReportActions, { selectChosenSchoolGroups, selectSchoolGroups } from 'redux/report';
import { selectSchoolUser } from 'redux/app';
import ReportFilterMembers from 'containers/ReportFilterMembers/ReportFilterMembers';
import ReportFilterProcedures from 'containers/ReportFilterProcedures/ReportFilterProcedures';
import ReportFilterReports from 'containers/ReportFilterReports/ReportFilterReports';
import ReportFilterSubmit from 'containers/ReportFilterSubmit/ReportFilterSubmit';
import ReportSchoolGroups from 'components/ReportSchoolGroups';
import './ReportFilter.css';

var SCHOOL_USER_GROUP = 'SCHOOL_USER_GROUP';

const mapStateToProps = ({ report, app }) => ({
  schoolGroups: selectSchoolGroups(report),
  chosenGroups: selectChosenSchoolGroups(report),
  schoolUser: selectSchoolUser(app),
});

const mapActionsToProps = {
  setSchoolGroup: ReportActions.setSchoolGroup,
};

class ReportFilter extends Component {
  static propTypes = {
    some: PropTypes.number,
  }

  componentDidMount() {
    const { schoolUser } = this.props;
    // console.log(">>>>>>>", this.props);

    // this.props.schoolUser[0] = 43662;
    if (schoolUser.length > 0) {
      this.props.setSchoolGroup([{
        name: SCHOOL_USER_GROUP,
        data: schoolUser[0].id,
      }]);
    } 
  }

  setReportGroup = (schools) => {
    this.props.setSchoolGroup(schools);
  } 

  render() {
    const { schoolGroups, chosenGroups, schoolUser } = this.props;
    const isFilterDisabled = chosenGroups.length > 0;
    const isSchoolUser =  schoolUser.length > 0;
    return (
      <div className="report-filter">
        <div className="report-filter__col">
          <div className="report-filter__title">Выберите:</div>
          {!isSchoolUser && <ReportFilterMembers disabled={isFilterDisabled} />}
          {!isSchoolUser && <ReportSchoolGroups
            groups={schoolGroups}
            chosenGroups={chosenGroups}
            onSetReportGroup={this.setReportGroup}
          />}
          <ReportFilterProcedures/>
          <ReportFilterReports isSchoolUser={isSchoolUser} />
          <ReportFilterSubmit/>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportFilter);
