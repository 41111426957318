import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'antd';
import './DirectoryFilterItem.css';

class DirectoryFilterItem extends Component {
  static propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
  }

  render() {
    const { title, isOpen, onClick } = this.props;
    const caretClassName = classnames('directory-filter-item__caret', {
      'directory-filter-item__caret_up': isOpen,
      'directory-filter-item__caret_down': !isOpen,
    });
    const caretClassItem = classnames('directory-filter-item', {
      'directory-filter-item_is-open': isOpen,
    });

    return (
      <div className={caretClassItem} onClick={onClick}>
        <div className='directory-filter-item__name'>
          {title}
        </div>
        <div className={caretClassName}>
          <Icon type={isOpen ? 'caret-up' : 'caret-down'} />
        </div>
      </div>
    );
  }
}

export default DirectoryFilterItem;
