export const normalizeRegions = (array) => {
  return array.map(item => {
    const name = item.name
      // .replace('Республика', 'респ.')
      // .replace('Муниципальный район', 'м. р.')
      // .replace('муниципальный район', 'м. р.')
      // .replace('(региональное подчинение)', '(р. п.)')
      // .replace('город', 'г.')
      .trim();

    const sortName = item.name
      // .replace('Республика', '')
      // .replace('респ.', '')
      // .replace('г.', '')
      // .replace('обл.', '')
      // .replace('авт. обл.', '')
      // .replace('авт. округ', '')
      // .replace('Муниципальный район', '')
      // .replace('муниципальный район', '')
      // .replace('(региональное подчинение)', '')
      // .replace('город', '')
      .trim();

    return {
      name,
      sortName,
      id: item.id,
      code: parseInt(item.code, 10),
      codeText: item.code.length === 1 ? `0${item.code}` : item.code,
      selected: false,
      checked: false,
      disabled: false,
      hasSelectedChildren: false,
    };
  });
};

export default normalizeRegions;
