export const normalizeSchools = (array) => {
  return array.map(item => {
    return {
      id: item.id,
      name: item.short_name || item.name,
      region: item.region,
      district: item.city,
      selected: false,
      checked: false,
      disabled: false,
      hasSelectedChildren: false,
    };
  });
};

export default normalizeSchools;
