import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './ProcedureScatterTitle.css';

class ProcedureScatterTitle extends PureComponent {
  static propTypes = {
    procedures: PropTypes.array,
    report: PropTypes.object,
  };

  render() {
    const { procedures } = this.props;
    const [{ procedure: first }, { procedure: second }] = procedures;

    return (
      <div className="procedure-scatter-title">
        <div className="procedure-scatter-title__col">
          <div className="procedure-scatter-title__name">{first.name}</div>
          <div className="procedure-scatter-title__year">{first.year.name}</div>
          <div className="procedure-scatter-title__separator">/</div>
          <div className="procedure-scatter-title__level">{first.level.name} класс</div>
          <div className="procedure-scatter-title__subject">{first.subject.name}</div>

          <div className="procedure-scatter-title__separator procedure-scatter-title__separator_items">,</div>

          <div className="procedure-scatter-title__name">{second.name}</div>
          <div className="procedure-scatter-title__year">{second.year.name}</div>
          <div className="procedure-scatter-title__separator">/</div>
          <div className="procedure-scatter-title__level">{second.level.name} класс</div>
          <div className="procedure-scatter-title__subject">{second.subject.name}</div>
        </div>
      </div>
    );
  }
}

export default ProcedureScatterTitle;
