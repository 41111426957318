import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

import './Loader.css';

export const LOADER_SIZES = {
  DEFAULT: '',
  SMALL: 'small',
  LARGE: 'large',
};

const Loader = ({ size }) => {
  return (
    <div className="loader">
      <Spin size={size} />
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.string,
};

export default Loader;
