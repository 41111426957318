import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import ClickOutside from 'react-click-outside';
import ReportFilterItem from 'components/ReportFilterItem/ReportFilterItem';
import ReportFilterProceduresPopup from 'containers/ReportFilterProceduresPopup/ReportFilterProceduresPopup';
import ReportActions, { selectSelectedProceduresCount, selectSelectedProcedures, selectProcedures, selectCheckedUniqueReports } from 'redux/report';
import './ReportFilterProcedures.css';

const mapStateToProps = ({ report }) => ({
  count: selectSelectedProceduresCount(report),
  procedures: selectProcedures(report),
  tags: selectSelectedProcedures(report),
  checkedUniqueReports: selectCheckedUniqueReports(report),
});

const mapActionsToProps = {
  addSelectedProcedure: ReportActions.addSelectedProcedure,
  removeSelectedProcedure: ReportActions.removeSelectedProcedure,
  resetSelectedProcedures: ReportActions.resetSelectedProcedures,
};

class ReportFilterProcedures extends Component {
  static propTypes = {
    count: PropTypes.number,
  }

  state = {
    isOpen: false,
  }

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  }

  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const { count, procedures, tags, checkedUniqueReports } = this.props;
    const { isOpen } = this.state;

    const popupClassName = classnames('report-filter-procedures__popup', {
      'report-filter-procedures__popup_open': isOpen,
      'report-filter-procedures__popup_close': !isOpen,
    });

    return (
      <ClickOutside onClickOutside={this.handleClose}>
        <div className="report-filter-procedures">
          <ReportFilterItem
            title={'Мероприятие'}
            count={count}
            isOpen={isOpen}
            onClick={this.handleToggle}
          />
        </div>
        <div className={popupClassName}>
          <ReportFilterProceduresPopup
            resetSelectedProcedures={this.props.resetSelectedProcedures}
            addSelectedProcedure={this.props.addSelectedProcedure}
            removeSelectedProcedure={this.props.removeSelectedProcedure}
            procedures={procedures}
            checkedUnique={checkedUniqueReports}
            tags={tags}
          />
        </div>
      </ClickOutside>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportFilterProcedures);
