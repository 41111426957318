import { createActions, createReducer } from 'reduxsauce';
import findIndex from 'lodash/findIndex';
import progressTypes from 'constants/progressTypes';
import { removeItem } from 'utils/reduxHelpers';

var NO_PROC = [8, 9];
var REGIONS_INDICATORS = 12;
var UNIQUE_REPORT = 13;
const INDIVIDUAL_RESULTS = 14;

const { Creators } = createActions({
  getInitialData: null,
  getInitialDataSuccess: ['data'],
  getInitialDataError: ['error'],
  getDistricts: ['id'],
  getDistrictsSuccess: ['districts'],
  getDistrictsError: ['error'],
  getSchools: ['id'],
  getSchoolsSuccess: ['schools'],
  getSchoolsError: ['id', 'error'],
  selectRegion: ['id'],
  selectDistrict: ['id'],
  checkRegion: ['id'],
  checkAllRegion: null,
  checkDistrict: ['id'],
  checkAllDistrict: null,
  checkSchool: ['id'],
  checkAllSchool: null,
  addSelectedProcedure: ['procedure'],
  removeSelectedProcedure: ['index'],
  resetSelectedProcedures: null,
  checkReport: ['id'],
  checkAllReport: null,
  getReportsData: null,
  getReportsDataSuccess: ['reportsData'],
  getReportsDataError: ['error'],
  resetCheckedItems: null,
  setSchoolGroup: ['list'],
});

export default Creators;

const Types = {
  GET_INITIAL_DATA: 'GET_INITIAL_DATA',
  GET_INITIAL_DATA_SUCCESS: 'GET_INITIAL_DATA_SUCCESS',
  GET_INITIAL_DATA_ERROR: 'GET_INITIAL_DATA_ERROR',
  GET_DISTRICTS: 'GET_DISTRICTS',
  GET_DISTRICTS_SUCCESS: 'GET_DISTRICTS_SUCCESS',
  GET_DISTRICTS_ERROR: 'GET_DISTRICTS_ERROR',
  GET_SCHOOLS: 'GET_SCHOOLS',
  GET_SCHOOLS_SUCCESS: 'GET_SCHOOLS_SUCCESS',
  GET_SCHOOLS_ERROR: 'GET_SCHOOLS_ERROR',
  SELECT_REGION: 'SELECT_REGION',
  SELECT_DISTRICT: 'SELECT_DISTRICT',
  CHECK_REGION: 'CHECK_REGION',
  CHECK_ALL_REGION: 'CHECK_ALL_REGION',
  CHECK_DISTRICT: 'CHECK_DISTRICT',
  CHECK_ALL_DISTRICT: 'CHECK_ALL_DISTRICT',
  CHECK_SCHOOL: 'CHECK_SCHOOL',
  CHECK_ALL_SCHOOL: 'CHECK_ALL_SCHOOL',
  ADD_SELECTED_PROCEDURE: 'ADD_SELECTED_PROCEDURE',
  REMOVE_SELECTED_PROCEDURE: 'REMOVE_SELECTED_PROCEDURE',
  CHECK_REPORT: 'CHECK_REPORT',
  CHECK_ALL_REPORT: 'CHECK_ALL_REPORT',
  GET_REPORTS_DATA: 'GET_REPORTS_DATA',
  GET_REPORTS_DATA_SUCCESS: 'GET_REPORTS_DATA_SUCCESS',
  GET_REPORTS_DATA_ERROR: 'GET_REPORTS_DATA_ERROR',
  RESET_CHECKED_ITEMS: 'RESET_CHECKED_ITEMS',
  SET_SCHOOL_GROUP: 'SET_SCHOOL_GROUP',
  RESET_SELECTED_PROCEDURES: 'RESET_SELECTED_PROCEDURES',
};

export const ReportTypes = Types;

export const INITIAL_STATE = {
  regions: [],
  districts: [],
  schools: [],
  procedures: null,
  reports: null,
  neobProcedures: [],
  getInitialDataProgress: progressTypes.IDLE,
  getDistrictsProgress: progressTypes.IDLE,
  getSchoolsProgress: progressTypes.IDLE,
  getReportsDataProgress: progressTypes.IDLE,
  checkedRegions: [],
  checkedDistricts: [],
  checkedSchools: [],
  selectedProcedures: [],
  reportsData: [],
  reportsDataError: null,
  schoolGroups: [],
  chosenGroups: [],
  evaluations: [],
};

export const getInitialData = (state) => ({
  ...state,
  getInitialDataProgress: progressTypes.WORK,
});

export const getInitialDataSuccess = (state, { data }) => ({
  ...state,
  ...data,
  getInitialDataProgress: progressTypes.SUCCESS,
});

export const getInitialDataError = (state) => ({
  ...state,
  getInitialDataProgress: progressTypes.ERROR,
});

export const getDistricts = (state) => ({
  ...state,
  districts: [],
  schools: [],
  getDistrictsProgress: progressTypes.WORK,
});

export const getDistrictsSuccess = (state, { districts }) => {
  const checkedId = state.checkedDistricts.map(item => item.id);
  return {
    ...state,
    getDistrictsProgress: progressTypes.SUCCESS,
    districts: districts.map(item => ({
      ...item,
      checked: checkedId.indexOf(item.id) !== -1,
    })),
  };
};

export const getDistrictsError = (state) => ({
  ...state,
  districts: [],
  schools: [],
  getDistrictsProgress: progressTypes.ERROR,
});

export const getSchools = (state) => ({
  ...state,
  schools: [],
  getSchoolsProgress: progressTypes.WORK,
});

export const getSchoolsSuccess = (state, { schools }) => {
  const checkedId = state.checkedSchools.map(item => item.id);
  return {
    ...state,
    getSchoolsProgress: progressTypes.SUCCESS,
    schools: schools.map(item => ({
      ...item,
      checked: checkedId.indexOf(item.id) !== -1,
    })),
  };
};

export const getSchoolsError = (state) => ({
  ...state,
  schools: [],
  getSchoolsProgress: progressTypes.ERROR,
});

export const selectRegion = (state, { id }) => {
  const regions = state.regions.map(item => {
    return {
      ...item,
      selected: item.id === id,
    };
  });

  return {
    ...state,
    regions,
  };
};

export const selectDistrict = (state, { id }) => {
  const districts = state.districts.map(item => {
    return {
      ...item,
      selected: item.id === id,
    };
  });

  return {
    ...state,
    districts,
  };
};

export const checkRegion = (state, { id }) => {
  const regions = state.regions.map(item => ({
    ...item,
    checked: item.id === id ? !item.checked : item.checked,
  }));
  const checkedRegions = regions.filter(item => item.checked);

  return {
    ...state,
    regions,
    checkedRegions,
  };
};

export const checkAllRegion = (state) => {
  let isCheckedAll = true;

  state.regions.forEach(item => {
    if (!item.checked) {
      isCheckedAll = false;
    }
  });

  const regions = state.regions.map(item => ({
    ...item,
    checked: !isCheckedAll,
  }));

  const checkedRegions = regions.filter(item => item.checked);

  return {
    ...state,
    regions,
    checkedRegions,
  };
};

export const checkDistrict = (state, { id }) => {
  const districts = state.districts.map(item => {
    if (item.id !== id) {
      return item;
    }
    return {
      ...item,
      checked: !item.checked,
    };
  });

  const districtIndex = findIndex(districts, item => item.id === id);
  const checkedDistrictsIndex = findIndex(state.checkedDistricts, item => item.id === id);

  const checkedDistricts = (checkedDistrictsIndex === -1)
    ? [...state.checkedDistricts, districts[districtIndex]]
    : removeItem(state.checkedDistricts, { index: checkedDistrictsIndex });

  return {
    ...state,
    districts,
    checkedDistricts,
  };
};

export const checkAllDistrict = (state) => {
  let isCheckedAll = true;

  state.districts.forEach(item => {
    if (!item.checked) {
      isCheckedAll = false;
    }
  });

  const districts = state.districts.map(item => ({
    ...item,
    checked: !isCheckedAll,
  }));

  let checkedDistricts = [...state.checkedDistricts];

  districts.forEach(({ id, checked }) => {
    if (checked) {
      const districtIndex = findIndex(districts, item => item.id === id);
      const checkedDistrictsIndex = findIndex(checkedDistricts, item => item.id === id);
      if (checkedDistrictsIndex === -1) {
        checkedDistricts = [...checkedDistricts, districts[districtIndex]];
      }
    } else {
      const checkedDistrictsIndex = findIndex(checkedDistricts, item => item.id === id);
      checkedDistricts = removeItem(checkedDistricts, { index: checkedDistrictsIndex });
    }
  });

  return {
    ...state,
    districts,
    checkedDistricts,
  };
};

export const checkSchool = (state, { id }) => {
  const schools = state.schools.map(item => {
    if (item.id !== id) {
      return item;
    }
    return {
      ...item,
      checked: !item.checked,
    };
  });
  const schoolIndex = findIndex(schools, item => item.id === id);
  const checkedSchoolIndex = findIndex(state.checkedSchools, item => item.id === id);

  const checkedSchools = (checkedSchoolIndex === -1)
    ? [...state.checkedSchools, schools[schoolIndex]]
    : removeItem(state.checkedSchools, { index: checkedSchoolIndex });

  return {
    ...state,
    schools,
    checkedSchools,
  };
};

export const checkAllSchool = (state) => {
  let isCheckedAll = true;

  state.schools.forEach(item => {
    if (!item.checked) {
      isCheckedAll = false;
    }
  });

  const schools = state.schools.map(item => ({
    ...item,
    checked: !isCheckedAll,
  }));

  let checkedSchools = [...state.checkedSchools];

  schools.forEach(({ id, checked }) => {
    if (checked) {
      const schoolIndex = findIndex(schools, item => item.id === id);
      const checkedSchoolIndex = findIndex(checkedSchools, item => item.id === id);

      if (checkedSchoolIndex === -1) {
        checkedSchools = [...checkedSchools, schools[schoolIndex]];
      }
    } else {
      const checkedSchoolIndex = findIndex(checkedSchools, item => item.id === id);
      checkedSchools = removeItem(checkedSchools, { index: checkedSchoolIndex });
    }
  });

  return {
    ...state,
    schools,
    checkedSchools,
  };
};

export const addSelectedProcedure = (state, { procedure }) => ({
  ...state,
  selectedProcedures: [...state.selectedProcedures, procedure],
});

export const removeSelectedProcedure = (state, { index }) => ({
  ...state,
  selectedProcedures: removeItem(state.selectedProcedures, { index }),
});

export const checkReport = (state, { id }) => ({
  ...state,
  reports: state.reports.map(item => {
    if (item.id !== id) {
      return item;
    }
    return {
      ...item,
      checked: !item.checked,
    };
  }),
});

export const checkAllReport = (state) => {
  let isCheckedAll = true;

  state.reports.forEach(item => {
    if (!item.checked) {
      isCheckedAll = false;
    }
  });

  return {
    ...state,
    reports: state.reports.map(item => ({
      ...item,
      checked: !isCheckedAll,
    })),
  };
};

export const getReportsData = (state) => ({
  ...state,
  reportsData: [],
  getReportsDataProgress: progressTypes.WORK,
});

export const getReportsDataSuccess = (state, { reportsData }) => ({
  ...state,
  reportsData,
  getReportsDataProgress: progressTypes.SUCCESS,
});

export const getReportsDataError = (state, { error }) => ({
  ...state,
  reportsData: [],
  reportsDataError: `${error.name} (${error.message}): ${JSON.stringify(error.data)}`,
  getReportsDataProgress: progressTypes.ERROR,
});

export const resetCheckedItems = (state) => ({
  ...state,
  districts: state.districts.map(item => ({
    ...item,
    checked: false,
  })),
  regions: state.regions.map(item => ({
    ...item,
    checked: false,
  })),
  schools: state.schools.map(item => ({
    ...item,
    checked: false,
  })),
  reports: state.reports.map(item => ({
    ...item,
    checked: false,
  })),
  checkedDistricts: [],
  checkedSchools: [],
  checkedRegions: [],
  selectedProcedures: [],
});

export const setSchoolGroup = (state, { list }) => ({
  ...state,
  chosenGroups: list,
});

export const resetSelectedProcedures = (state) => ({
  ...state,
  selectedProcedures: [],
});


export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_INITIAL_DATA]: getInitialData,
  [Types.GET_INITIAL_DATA_SUCCESS]: getInitialDataSuccess,
  [Types.GET_INITIAL_DATA_ERROR]: getInitialDataError,
  [Types.GET_DISTRICTS]: getDistricts,
  [Types.GET_DISTRICTS_SUCCESS]: getDistrictsSuccess,
  [Types.GET_DISTRICTS_ERROR]: getDistrictsError,
  [Types.GET_SCHOOLS]: getSchools,
  [Types.GET_SCHOOLS_SUCCESS]: getSchoolsSuccess,
  [Types.GET_SCHOOLS_ERROR]: getSchoolsError,
  [Types.SELECT_REGION]: selectRegion,
  [Types.SELECT_DISTRICT]: selectDistrict,
  [Types.CHECK_REGION]: checkRegion,
  [Types.CHECK_ALL_REGION]: checkAllRegion,
  [Types.CHECK_DISTRICT]: checkDistrict,
  [Types.CHECK_ALL_DISTRICT]: checkAllDistrict,
  [Types.CHECK_SCHOOL]: checkSchool,
  [Types.CHECK_ALL_SCHOOL]: checkAllSchool,
  [Types.ADD_SELECTED_PROCEDURE]: addSelectedProcedure,
  [Types.REMOVE_SELECTED_PROCEDURE]: removeSelectedProcedure,
  [Types.CHECK_REPORT]: checkReport,
  [Types.CHECK_ALL_REPORT]: checkAllReport,
  [Types.GET_REPORTS_DATA]: getReportsData,
  [Types.GET_REPORTS_DATA_SUCCESS]: getReportsDataSuccess,
  [Types.GET_REPORTS_DATA_ERROR]: getReportsDataError,
  [Types.RESET_CHECKED_ITEMS]: resetCheckedItems,
  [Types.SET_SCHOOL_GROUP]: setSchoolGroup,
  [Types.RESET_SELECTED_PROCEDURES]: resetSelectedProcedures,
});

export const selectGetInitialDataProgress = (state) => state.getInitialDataProgress;
export const selectRegions = (state) => {
  const checkedDistrictsRegionListId = state.checkedDistricts.map(item => item.region);
  const checkedSchoolRegionListId = state.checkedSchools.map(item => item.region);
  return state.regions.map(item => ({
    ...item,
    hasSelectedChildren: (
      (checkedDistrictsRegionListId.indexOf(item.id) !== -1) ||
      (checkedSchoolRegionListId.indexOf(item.id) !== -1)
    ),
  }));
};
export const selectDistricts = (state) => {
  const checkedSchoolDistrictsListId = state.checkedSchools.map(item => item.district);
  return state.districts && state.districts.map(item => ({
    ...item,
    hasSelectedChildren: (checkedSchoolDistrictsListId.indexOf(item.id) !== -1),
  }));
};
export const selectSchools = (state) => {
  return state.schools;
};
export const selectGetDistrictsProgress = (state) => state.getDistrictsProgress;
export const selectGetSchoolsProgress = (state) => state.getSchoolsProgress;
export const selectMembersCount = (state) => {
  return state.checkedRegions.length + state.checkedDistricts.length + state.checkedSchools.length;
};
export const selectProcedures = (state) => state.procedures;
export const selectSelectedProcedures = (state) => state.selectedProcedures;
export const selectSelectedProceduresCount = (state) => state.selectedProcedures.length;
export const selectSelectedReportsCount = (state) => state.reports.filter(item => item.checked).length;
export const selectReports = (state) => state.reports;
export const selectIsCheckedAllReports = (state) => {
  let isCheckedAll = true;

  state.reports.forEach(item => {
    if (!item.checked) {
      isCheckedAll = false;
    }
  });

  return isCheckedAll;
};
export const selectIsIndeterminateReports = (state) => {
  let isIndeterminate = false;

  state.reports.forEach(item => {
    if (item.checked) {
      isIndeterminate = true;
    }
  });

  return isIndeterminate;
};
export const selectProcedureFilterIsValid = (state) => {
  // TODO fix for different cases (e.g. region indicators and other rep chosen)
  let hasSelectedReports = false;

  var checkedReportsIds = [];
  state.reports.forEach(item => {
    if (item.checked) {
      hasSelectedReports = true;
      checkedReportsIds.push(item.report_type);
    }
  });

  if (!hasSelectedReports) {
    return false;
  }

  if (checkedReportsIds.some(x => REGIONS_INDICATORS == x) && state.checkedRegions.length == 0) {
    return false;
  }

  if (checkedReportsIds.some(x => ![...NO_PROC, REGIONS_INDICATORS].includes(x)) && 
    ((!state.checkedRegions.length && !state.checkedDistricts.length && !state.checkedSchools.length && !state.chosenGroups.length) || (
      !state.selectedProcedures.length
    ))
  ) {
    return false;
  }

  if (checkedReportsIds.some(x => NO_PROC.includes(x)) && (
    !state.checkedRegions.length && !state.checkedDistricts.length && !state.checkedSchools.length && !state.chosenGroups.length
  )) {
    return false;
  }

  if (checkedReportsIds.some(x => x === INDIVIDUAL_RESULTS) && (
    !state.checkedSchools.length && !state.chosenGroups.length
  )) {
    return false;
  }

  return true;
};
export const selectResetFilterIsEnabled = (state) => {
  let isEnabled = false;
  if (
    state.checkedRegions.length ||
    state.chosenGroups.length ||
    state.checkedDistricts.length ||
    state.checkedSchools.length ||
    state.selectedProcedures.length ||
    state.reports.some(item => item.checked)
  ) {
    isEnabled = true;
  }

  return isEnabled;
};
export const selectGetReportsDataProgress = (state) => state.getReportsDataProgress;
export const selectReportsData = (state) => state.reportsData;
export const selectIsCheckedAllRegion = (state) => {
  let isCheckedAll = true;

  state.regions.forEach(item => {
    if (!item.checked) {
      isCheckedAll = false;
    }
  });

  return isCheckedAll;
};
export const selectIsIndeterminateAllRegion = (state) => {
  let isIndeterminate = false;

  state.regions.forEach(item => {
    if (item.checked) {
      isIndeterminate = true;
    }
  });

  return isIndeterminate;
};
export const selectIsCheckedAllDistrict = (state) => {
  let isCheckedAll = true;

  state.districts.forEach(item => {
    if (!item.checked) {
      isCheckedAll = false;
    }
  });

  return isCheckedAll;
};
export const selectIsIndeterminateAllDistrict = (state) => {
  let isIndeterminate = false;

  state.districts.forEach(item => {
    if (item.checked) {
      isIndeterminate = true;
    }
  });

  return isIndeterminate;
};
export const selectIsCheckedAllSchool = (state) => {
  let isCheckedAll = true;

  state.schools.forEach(item => {
    if (!item.checked) {
      isCheckedAll = false;
    }
  });

  return isCheckedAll;
};
export const selectIsIndeterminateAllSchool = (state) => {
  let isIndeterminate = false;

  state.schools.forEach(item => {
    if (item.checked) {
      isIndeterminate = true;
    }
  });

  return isIndeterminate;
};

export const getReportNumber = (state) => {
  const {
    checkedRegions,
    checkedDistricts,
    checkedSchools,
    selectedProcedures,
    reports,
  } = state;
  const checkedReports = reports.filter(report => report.checked).length;

  return (checkedRegions.length + checkedDistricts.length + checkedSchools.length) *
    selectedProcedures.length * checkedReports;
};


export const selectChosenSchoolGroups = state => state.chosenGroups;
export const selectSchoolGroups = state => state.schoolGroups;
export const selectNeobProcedures = state => state.neobProcedures;
export const selectEvaluations = state => state.evaluations;
export const selectDataError = state => state.reportsDataError;
export const selectCheckedUniqueReports = state => state.reports.some(rep => rep.checked && rep.report_type == UNIQUE_REPORT);