import { all, takeLatest } from 'redux-saga/effects';

import API from 'services/Api';

import { AppTypes } from 'redux/app';
import { SigninTypes } from 'redux/signin';
import { SignoutTypes } from 'redux/signout';
import { ReportTypes } from 'redux/report';
import { DirectoryTypes } from 'redux/directory';
import { DirectoryAteTypes } from 'redux/directoryAte';
import { DirectoryProcTypes } from 'redux/directoryProc';
import { IndicatorTypes } from 'redux/indicators';

import { checkUserAuthorization } from 'saga/app';
import { signin, oauthLogin } from 'saga/signin';
import { signout } from 'saga/signout';
import { getData } from 'saga/report';
import { getDistricts } from 'saga/report';
import { getSchools } from 'saga/report';
import { getReportData } from 'saga/report';
import { getDirectoryData, getMetaSchools, getDirectoryDistricts, addSchoolGroup } from 'saga/directory';
import { getDirectoryDataAte, getDirectoryDistrictsAte, getDirectoryAteResults } from 'saga/directoryAte';
import { getDirectoryProcOptions, getDirectoryProcResults } from 'saga/directoryProc';
import {
  getData as getIndicatorsInitialData,
  getDistricts as getIndicatorsDistricts,
  getResults as getIndicatorsResults,
} from 'saga/indicators';

const api = API.create();

export default function * root () {
  yield all([
    takeLatest(AppTypes.CHECK_AUTHORIZATION, checkUserAuthorization, api),

    takeLatest(SigninTypes.SEND_SIGNIN, signin, api),
    takeLatest(SignoutTypes.SEND_SIGNOUT, signout, api),
    takeLatest(SigninTypes.GET_OAUTH, oauthLogin, api),

    takeLatest(ReportTypes.GET_INITIAL_DATA, getData, api),
    takeLatest(ReportTypes.GET_DISTRICTS, getDistricts, api),
    takeLatest(ReportTypes.GET_SCHOOLS, getSchools, api),
    takeLatest(ReportTypes.GET_REPORTS_DATA, getReportData, api),

    takeLatest(DirectoryTypes.GET_DIRECTORY_DATA, getDirectoryData, api),
    takeLatest(DirectoryTypes.GET_DIRECTORY_SCHOOLS, getMetaSchools, api),
    takeLatest(DirectoryTypes.GET_DIRECTORY_DISTRICTS, getDirectoryDistricts, api),
    takeLatest(DirectoryTypes.ADD_SCHOOL_GROUP, addSchoolGroup, api),

    takeLatest(DirectoryAteTypes.GET_DIRECTORY_DATA, getDirectoryDataAte, api),
    takeLatest(DirectoryAteTypes.GET_DIRECTORY_DISTRICTS, getDirectoryDistrictsAte, api),
    takeLatest(DirectoryAteTypes.GET_DIRECTORY_RESULTS, getDirectoryAteResults, api),

    takeLatest(DirectoryProcTypes.GET_OPTIONS, getDirectoryProcOptions, api),
    takeLatest(DirectoryProcTypes.GET_RESULTS, getDirectoryProcResults, api),

    takeLatest(IndicatorTypes.GET_INITIAL_DATA, getIndicatorsInitialData, api),
    takeLatest(IndicatorTypes.GET_DISTRICTS, getIndicatorsDistricts, api),
    takeLatest(IndicatorTypes.GET_REPORTS_DATA, getIndicatorsResults, api),
  ]);
}


