import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => {
    return (
      rest.isUserAuthorized ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect to={{
          pathname: '/signin',
          state: { from: props.location },
        }}/>
      )
    );
  }}/>
);

PrivateRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.object,
};

export default PrivateRoute;
