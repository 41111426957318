import React, { Component } from 'react';
import { Modal, Input, Checkbox, Popover } from 'antd';
import find from 'lodash/find';
import ReactTable from 'react-table';
import { tableText as tableDefaultTexts} from 'constants/tableSettings';
import getXlsx from 'utils/xlsx';
import DirectoryHeader from 'components/DirectoryHeader';

import { transportAccessibilityTypes } from 'constants/schoolsTypes';

var HEADERS = [{
  Header: 'ОО',
  accessor: 'name',
}, {
  Header: 'МР',
  accessor: 'district',
}, {
  Header: 'ТИП НАС. ПУНКТА',
  accessor: 'location',
}, {
  Header: 'ЧИСЛЕННОСТЬ НАСЕЛЁННОГО ПУНКТА',
  accessor: 'population',
}, {
  Header: '% УЧЕНИКОВ, ДЛЯ КОТОРЫХ РУСС. ЯЗ. НЕ РОДНОЙ<',
  accessor: 'notNative',
}, {
  Header: 'ОСОБЕННОСТИ ОО',
  accessor: 'feature',
}, {
  Header: 'Количество процедур',
  accessor: 'procedures',
}, {
  Header: 'Транспортная доступность',
  accessor: 'transport',
}, {
  Header: 'Количество обучающихся',
  accessor: 'studentsNumber',
}, {
  Header: 'Классы с углубленным изучением предметов',
  accessor: 'glub',
}, {
  Header: 'Дата последней проверки',
  accessor: 'datelastcheck',
}, {
  Header: 'Результат последней проверки',
  accessor: 'lastcheck',
}, {
  Header: 'Кластер',
  accessor: 'cluster',
}];


const NOTNATIVE = [
  { value: 1, label: 'менее 5%' },
  { value: 2, label: 'от 20 до 40%' },
  { value: 3, label: 'от 40 до 60%' },
  { value: 4, label: 'от 5 до 20%' },
  { value: 5, label: 'от 60 до 80%' },
  { value: 6, label: 'от 80 до 100%' },
  { value: 7, label: 'таких обучающихся нет' },
];

const LOCATION = [
  { value: 1, label: 'Город' },
  { value: 2, label: 'Городская местность' },
  { value: 3, label: 'Село' },
];

const FEATURES = [
  { value: 'internat', label: 'Интернат'},
  { value: 'correct', label: 'Коррекционная' },
  { value: 'small', label: 'Малокомплектная школа' },
  { value: 'glub', label: 'С углубленным изучением' },
  { value: 'concurs', label: 'Конкурс при поступлении'},
];

const NASELENIE = [
  { value: '10', label: 'менее 200 человек' },
  { value: '3', label: '200-1000 человек' },
  { value: '2', label: '1000-5000 человек' },
  { value: '6', label: '5000-25 тыс человек' },
  { value: '4', label: '25 тыс. – 50 тыс. человек' },
  { value: '7', label: '50 тыс. – 100 тыс человек' },
  { value: '1', label: '100тыс- 250 тыс. человек' },
  { value: '5', label: '250 тыс – 500 тыс. человек' },
  { value: '8', label: '500 тыс – 1 млн. человек' },
  { value: '9', label: 'более 1 млн. человек' },
];

class SchoolsListItem extends Component {
  state = {
    list: [],
    isModalShown: false,
    groupName: '',
    confirmLoading: false,
  }

  downloadTable = () => {
    // TODO сохранять значения фильтров в редакс, чтобы не вытаскивать выбранный регион из школ
    var { regions } = this.props;
    var schools = this.props.schools.results;
    var currentRegion = schools[0].school.region;
    var title = `ОО ${regions.find(region => region.id == currentRegion).name}`.toLowerCase();
    getXlsx(title, HEADERS, this.getFormattedSchools(schools));
  }

  getFormattedSchools(schools) {
    var { districts } = this.props;
    return schools.map(school => {
      const {
        school: {
          correct,
          id,
          procedures,
          short_name,
          mestonahozhdenie,
          nerus,
          transport: transportValue,
          glub: glubValue,
          city,
        },
        total_count: studentsCount,
        cluster,
        datacheck: datelastcheck,
        resultcheck: lastcheck,
        town_type,
      } = school;
      const transport = transportAccessibilityTypes.find(x => x.value == transportValue);
      const notNative = NOTNATIVE.find(x => x.value === nerus);
      const location = LOCATION.find(x => x.value === mestonahozhdenie);
      const correctFeature = correct < 3 ? 'Коррекционная' : '';
      const feature = ['internat', 'small', 'glub', 'concurs'].filter(feature => school.school[feature]) || [];
      const featureList = feature.length > 0 && (feature.map(x => FEATURES.find(f => f.value == x).label)).join(' ') + correctFeature;
      const glub = glubValue ? 'Есть' : 'Нет';
      const district = find(districts, district => district.id === city);
      const populationValue = NASELENIE.find(x => x.value == town_type);
      return {
        id,
        name: short_name,
        district: district ? district.name : '-',
        location:  location ? location.label : '-',
        population: populationValue ? populationValue.label : '-',
        notNative: notNative ? notNative.label : '-',
        feature: featureList,
        procedures: procedures,
        transport: transport ? transport.label : '-',
        studentsNumber: studentsCount,
        glub: glub,
        datelastcheck: datelastcheck || '-',
        lastcheck: lastcheck || '-',
        cluster: cluster,
      };
    });
  }

  addToGroup = (e, id) => {
    if (e.target.checked) {
      this.setState(prevState => ({
        list: [...prevState.list, id],
      }));
    } else {
      this.setState({ list: this.state.list.filter(x => x != id)});
    }
  }

  setGroupName = e => {
    var groupName = e.target.value;
    this.setState({ groupName });
  }

  hideModal = () => {
    this.setState({ isModalShown: false });
  }

  showModal = () => {
    this.setState({ isModalShown: true });
  }

  saveGroup = () => {
    const { list, groupName: name } = this.state;
    this.props.onSaveSchoolGroup({ list, name });
    this.setState({
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        isModalShown: false,
        confirmLoading: false,
        groupName: '',
      });
    }, 2000);
  }

  renderSchools(schools) {
    const { districts } = this.props;
    if ([schools, districts].some(x => x === undefined)) {
      throw new Error('Schools or districts are undefined');
    }

    const columns = [{
      Header: 'ОО',
      accessor: ({ name, id }) => ({ name, id }),
      id: 'schoolName',
      sortMethod: ({ name: firstName }, { name: secondName }) => {
        if (firstName >secondName) {
          return 1;
        }
        if (firstName <secondName) {
          return -1;
        }
        return 0;
      },
      Cell: row => {
        var { value: { name, id } } = row;
        return (
          <Checkbox onChange={event => this.addToGroup(event, id)}>{name}</Checkbox>
        );
      },
    }, {
      Header: 'МР',
      accessor: 'district',
    }, {
      Header: 'ТИП НАС. ПУНКТА',
      accessor: 'location',
    }, {
      Header: 'ЧИСЛЕННОСТЬ НАСЕЛЁННОГО ПУНКТА',
      accessor: 'population',
    }, {
      Header: '% УЧЕНИКОВ, ДЛЯ КОТОРЫХ РУСС. ЯЗ. НЕ РОДНОЙ<',
      accessor: 'notNative',
    }, {
      Header: 'ОСОБЕННОСТИ ОО',
      accessor: 'feature',
    }, {
      Header: 'Количество процедур',
      accessor: 'procedures',
      className: 'rt-td_right',
      Cell: row => {
        var { value: procedures } = row;
        return (
          <span>
            <Popover
              placement="left"
              content={(
                <ul>
                  {
                    procedures.map(({ id, name }) => 
                    <li key={id}>{name}</li>)
                  }
                </ul>
              )}
              title="Процедуры"
            >
              <div style={{ cursor: 'pointer' }}>
                {procedures.length} (показать)
              </div>
            </Popover>
          </span>
        );
      },
    }, {
      Header: 'Транспортная доступность',
      accessor: 'transport',
    }, {
      Header: 'Количество обучающихся',
      accessor: 'studentsNumber',
      className: 'rt-td_right',
    }, {
      Header: 'Классы с углубленным изучением предметов',
      accessor: 'glub',
    }, {
      Header: 'Дата последней проверки',
      accessor: 'datelastcheck',
    }, {
      Header: 'Результат последней проверки',
      accessor: 'lastcheck',
    }, {
      Header: 'Кластер',
      accessor: 'cluster',
      className: 'rt-td_right',
    }];

    const schoolsData = this.getFormattedSchools(schools);
    return (
      <ReactTable
        data={schoolsData}
        columns={columns}
        showPaginationBottom
        minRows={0}
        rowsText={schoolsData.length === 1 && 'строка'}
        {...tableDefaultTexts}
      />
    );
  }


  render() {
    var { schools: { count, results }, year } = this.props;
    const { list, isModalShown, groupName, confirmLoading } = this.state;

    const headerText = `${count === 0
    ? 'ОО не найдены'
    : count === 1
      ? `Найдено 1 ОО (${year.label} год)`
      : `Найдено ${count} ОО`} (${year.label} год)`;
    return (
      <div>
        <Modal
          title="Сохранить группу школ"
          cancelText="Отменить"
          okText="Сохранить"
          centered
          visible={isModalShown}
          onOk={this.saveGroup}
          onCancel={this.hideModal}
          confirmLoading={confirmLoading}
          okButtonProps={{ disabled: groupName.length == 0 }}
        >
          <p>Введите название для группы школ</p>
          <Input
            type="text"
            maxLength={150}
            placeholder="Новая группа школ"
            onChange={this.setGroupName}
            value={groupName}
          />
        </Modal>
        <DirectoryHeader
          text={headerText}
          isDownload={results.length > 0}
          isShown={list.length > 0}
          onDownload={this.downloadTable}
          onShowModal={this.showModal}
        />
        {results.length > 0 && this.renderSchools(results)}
      </div>
    );
  }
}

export default SchoolsListItem;
