import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './ReportTitle.css';

class ReportTitle extends PureComponent {
  static propTypes = {
    report: PropTypes.object,
    membersNames: PropTypes.array,
  };

  render() {
    const { report } = this.props;
    return (
      <div className="report-title">
        <div className="report-title__name">{ report.name || '' }</div>
      </div>
    );
  }
}

export default ReportTitle;
