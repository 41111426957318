import { createActions, createReducer } from 'reduxsauce';
import progressTypes from 'constants/progressTypes';
import uniqBy from 'lodash/uniqBy';

const { Types, Creators } = createActions({
  getInitialData: null,
  getInitialDataSuccess: ['data'],
  getInitialDataError: ['error'],

  getDistricts: ['id'],
  getDistrictsSuccess: ['districts'],
  getDistrictsError: ['error'],

  checkYear: ['id'],
  checkAllYears: null,
  checkRegion: ['id'],
  checkAllRegions: null,
  checkDistrict: ['id'],
  checkAllDistricts: null,
  checkReport: ['id'],
  checkAllReports: null,
  checkProcedure: ['id'],

  getReportsData: null,
  getReportsDataSuccess: ['results'],
  getReportsDataError: ['error'],
}, { prefix: 'INDICATORS_'});

export default Creators;
export const IndicatorTypes = Types;

export const INITIAL_STATE = {
  regions: [],
  districts: [],
  procedures: [],
  reports: [],
  years: [],
  results: [],

  initialProgress: progressTypes.IDLE,
  districtsProgress: progressTypes.IDLE,
  resultsProgress: progressTypes.IDLE,

  initialDataError: null,
  districtsError: null,
  resultsError: null,

  checkedRegions: [],
  checkedDistricts: [],
};

var getInitialData = state => ({
  ...state,
  initialProgress: progressTypes.WORK,
});

var getInitialDataSuccess = (state, { data }) => ({
  ...state,
  ...data,
  initialProgress: progressTypes.SUCCESS,
});

var getInitialDataError = (state, { error }) => ({
  ...state,
  initialProgress: progressTypes.ERROR,
  initialDataError: error,
});

var getDistricts = state => ({
  ...state,
  districts: [],
  districtsProgress: progressTypes.WORK,
});

var getDistrictsSuccess = (state, { districts }) => {
  var checkedDistricts = state.checkedDistricts.map(x => x.id);
  var updatedDistricts = districts.map(x => ({
    ...x,
    checked: checkedDistricts.includes(x.id),
  }));
  return {
    ...state,
    districts: updatedDistricts,
    districtsProgress: progressTypes.SUCCESS,
  };
};

var getDistrictsError = (state, { error }) => ({
  ...state,
  districtsError: error,
  districtsProgress: progressTypes.ERROR,
});

var checkYear = (state, { id }) => ({
  ...state,
  years: state.years.map(item => ({
    ...item,
    checked: item.id == id ? !item.checked : item.checked,
  })),
});

var checkAllYears = state => {
  var isAllChecked = state.years.every(x => x.checked);
  return {
    ...state,
    years: state.years.map(x => ({
      ...x,
      checked: !isAllChecked,
    })),
  };
};

var checkRegion = (state, { id }) => {
  var regions = state.regions.map(item => ({
    ...item,
    checked: item.id == id ? !item.checked : item.checked,
  }));
  return {
    ...state,
    regions,
    checkedRegions: regions.filter(item => item.checked),
  };
};

var checkAllRegions = state => {
  var isAllChecked = state.regions.every(x => x.checked);
  return {
    ...state,
    regions: state.regions.map(x => ({
      ...x,
      checked: !isAllChecked,
    })),
  };
};

var checkDistrict = (state, { id }) => {
  var districts = state.districts.map(item => ({
    ...item,
    checked: item.id == id ? !item.checked : item.checked,
  }));
  var checkedDistricts = uniqBy([...state.checkedDistricts, ...districts.filter(item => item.checked)], 'id');
  return {
    ...state,
    districts,
    checkedDistricts,
  };
};

var checkAllDistricts = state => {
  var isAllChecked = state.districts.every(x => x.checked);
  return {
    ...state,
    districts: state.districts.map(x => ({
      ...x,
      checked: !isAllChecked,
    })),
  };
};

var checkReport = (state, { id }) => ({
  ...state,
  reports: state.reports.map(item => ({
    ...item,
    checked: item.id == id ? !item.checked : item.checked,
  })),
});

var checkAllReports = state => {
  var isAllChecked = state.reports.every(x => x.checked);
  return {
    ...state,
    reports: state.reports.map(x => ({
      ...x,
      checked: !isAllChecked,
    })),
  };
};

var checkProcedure = (state, { id }) => ({
  ...state,
  procedures: state.procedures.map(item => ({
    ...item,
    checked: item.id == id ? !item.checked : item.checked,
  })),
});

var getReportsData = state => ({
  ...state,
  results: [],
  resultsProgress: progressTypes.WORK,
});

var getReportsDataSuccess = (state, { results }) => ({
  ...state,
  results,
  resultsProgress: progressTypes.SUCCESS,
});

var getReportsDataError = (state, { error }) => ({
  ...state,
  resultsError: error,
  resultsProgress: progressTypes.ERROR,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_INITIAL_DATA]: getInitialData,
  [Types.GET_INITIAL_DATA_SUCCESS]: getInitialDataSuccess,
  [Types.GET_INITIAL_DATA_ERROR]: getInitialDataError,
  [Types.GET_DISTRICTS]: getDistricts,
  [Types.GET_DISTRICTS_SUCCESS]: getDistrictsSuccess,
  [Types.GET_DISTRICTS_ERROR]: getDistrictsError,
  [Types.CHECK_YEAR]: checkYear,
  [Types.CHECK_ALL_YEARS]: checkAllYears,
  [Types.CHECK_REGION]: checkRegion,
  [Types.CHECK_ALL_REGIONS]: checkAllRegions,
  [Types.CHECK_DISTRICT]: checkDistrict,
  [Types.CHECK_ALL_DISTRICTS]: checkAllDistricts,
  [Types.CHECK_REPORT]: checkReport,
  [Types.CHECK_ALL_REPORTS]: checkAllReports,
  [Types.CHECK_PROCEDURE]: checkProcedure,
  [Types.GET_REPORTS_DATA]: getReportsData,
  [Types.GET_REPORTS_DATA_SUCCESS]: getReportsDataSuccess,
  [Types.GET_REPORTS_DATA_ERROR]: getReportsDataError,
});

export const selectInitialProgress = state => state.initialProgress;
export const selectDistrictsProgress = state => state.districtsProgress;
export const selectResultsProgress = state => state.resultsProgress;

export const selectResults = state => state.results;
export const selectYears = state => state.years;
export const selectProcedures = state => state.procedures;
export const selectDistricts = state => state.districts;
export const selectRegions = state => state.regions;
export const selectReports = state => state.reports;

export const selectInitialError = state => state.initialDataError;
export const selectDistrictsError = state => state.districtsError;
export const selectResultsError = state => state.resultsError;

export const selectMembersCount = ({ checkedRegions, checkedDistricts }) => checkedDistricts.length + checkedRegions.length;

export const selectCheckedReports = ({ reports }) => reports.filter(report => report.checked);

// TODO расширить количество параметров как появятся годы и процедуры
export const selectIsFormValid = state => (
  state.checkedRegions.length > 0 || state.checkedDistricts.length > 0
) && selectCheckedReports(state).length > 0;