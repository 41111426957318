import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import ReportFilterListItem from 'components/ReportFilterListItem/ReportFilterListItem';
import progressTypes from 'constants/progressTypes';
import './SchoolsList.css';

class SchoolsList extends Component {
  static propTypes = {
    items: PropTypes.array,
    progress: PropTypes.string,
    onCheck: PropTypes.func,
    onSelect: PropTypes.func,
  }

  renderLoader() {
    return (
      <div className="schools-list__wrapper-spin">
        <div className="schools-list__spin">
          <Spin className="loading" size="small" />
        </div>
      </div>
    );
  }

  renderError() {
    return (
      <div className="schools-list__wrapper-spin">
        <div className="schools-list__spin">
          {'При загрузке данных произошла ошибка'}
        </div>
      </div>
    );
  }

  renderList() {
    const { items } = this.props;
    return items.map((item, index) => (
      <ReportFilterListItem
        key={item.id}
        id={item.id}
        index={index}
        name={item.name}
        selected={item.selected}
        checked={item.checked}
        disabled={item.disabled}
        onSelect={this.props.onSelect}
        onCheck={this.props.onCheck}
      />
    ));
  }

  render() {
    const { progress } = this.props;

    switch (progress) {
      case progressTypes.WORK: {
        return this.renderLoader();
      }
      case progressTypes.SUCCESS: {
        return (
          <div className="schools-list">
            {this.renderList()}
          </div>
        );
      }
      case progressTypes.ERROR: {
        return this.renderError();
      }
      default: {
        return null;
      }
    }
  }

}

export default SchoolsList;
