import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';

import globalSagas from 'globalSaga';

import { reducer as appReducer } from 'redux/app';
import { reducer as signinReducer } from 'redux/signin';
import { reducer as signoutReducer } from 'redux/signout';
import { reducer as reportReducer } from 'redux/report';
import { reducer as directoryReducer } from 'redux/directory';
import { reducer as directoryAteReducer } from 'redux/directoryAte';
import { reducer as directoryProcReducer } from 'redux/directoryProc';
import { reducer as indicatorsReducer } from 'redux/indicators';

export default (history) => {

  const rootReducer = combineReducers({
    router: routerReducer,
    app: appReducer,
    signin: signinReducer,
    signout: signoutReducer,
    report: reportReducer,
    directory: directoryReducer,
    directoryAte: directoryAteReducer,
    directoryProc: directoryProcReducer,
    indicators: indicatorsReducer,
  });

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const sagaMiddleware = createSagaMiddleware();

  const middleware = [
    sagaMiddleware,
    routerMiddleware(history),
  ];

  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

  sagaMiddleware.run(globalSagas);

  return store;
};


