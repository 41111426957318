import React from 'react';
import PropTypes from 'prop-types';
import { Select, Checkbox, Spin } from 'antd';
import { Button } from 'antd';

import progressTypes from 'constants/progressTypes';
import './DirectoryFilterOptionsPopup.css';

const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;

const NASELENIE = [
  { value: '10', label: 'менее 200 человек' },
  { value: '3', label: '200-1000 человек' },
  { value: '2', label: '1000-5000 человек' },
  { value: '6', label: '5000-25 тыс человек' },
  { value: '4', label: '25 тыс. – 50 тыс. человек' },
  { value: '7', label: '50 тыс. – 100 тыс человек' },
  { value: '1', label: '100тыс- 250 тыс. человек' },
  { value: '5', label: '250 тыс – 500 тыс. человек' },
  { value: '8', label: '500 тыс – 1 млн. человек' },
  { value: '9', label: 'более 1 млн. человек' },
];

const MESTONAHOZHDENIE = [
  { value: '1', label: 'в городе' },
  { value: '2', label: 'в городской местности' },
  { value: '3', label: 'в сельской местности' },
];

const TRANSPORT = [
  { value: '1', label: 'высокое' },
  { value: '2', label: 'среднее' },
  { value: '3', label: 'низкое' },
];

const NERUS = [
  { value: '1', label: 'менее 5%' },
  { value: '2', label: 'от 20 до 40%' },
  { value: '3', label: 'от 40 до 60%' },
  { value: '4', label: 'от 5 до 20%' },
  { value: '5', label: 'от 60 до 80%' },
  { value: '6', label: 'от 80 до 100%' },
  { value: '7', label: 'таких обучающихся нет' },
];

var CLUSTER = Array.from({ length: 32 }, (_, i) => ({
  value: i + 1,
  label: String(i + 1),
}));

const DEFAULT_SELECT_LIST = [
  { name: 'naselenie', options: NASELENIE, placeholder: 'Размер населенного пункта' },
  { name: 'mestonahozhdenie', options: MESTONAHOZHDENIE, placeholder: 'Местонахождение' },
  { name: 'transport', options: TRANSPORT, placeholder: 'Транспортное сообщение' },
  { name: 'nerus', options: NERUS, placeholder: 'Процент обучающихся, для которых русский не является родным' },
  { name: 'cluster', options: CLUSTER, placeholder: 'Кластер' },
];

const schoolFeatures = [
  { name: 'school__internat', placeholder: 'Интернат' },
  { name: 'school__correct', placeholder: 'Коррекционная' },
  { name: 'school__concurs', placeholder: 'Конкурс при поступлении' },
  { name: 'school__glub', placeholder: 'С углубленным изучением' },
  { name: 'school__small', placeholder: 'Малокомплектная школа' },
];

const DirectoryFilterOptionsPopup = (props) => {
  const {
    regions,
    districts,
    progress,
    years,
    districtsProgress,
    data,
    handleChange,
    onFormInvalid,
  } = props;
  const list = [
    {
      name: 'study_year',
      options: years,
      placeholder: 'Год',
      mode: 'multiple',
      defaultValue: [],
    },
    { name: 'school__region', options: regions, placeholder: 'Регион' },
    { name: 'school__city', options: districts, placeholder: 'Муниципалитет', notFoundContent: <Spin size="small" />  },
    ...DEFAULT_SELECT_LIST,
  ];
  const className = 'directory-filter-options-popup';
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };


  return (
    <div className={`${className}`}>
      <div className={`${className}__col`}>
        <div className={`${className}__content`}>
          {
            list.map((item, key) => (
              <div
                className={`${className}__content__item`}
                key={`select_${key}`}
              >
                <div className={`${className}__content__item--header`}>{item.placeholder}</div>
                <div className={`${className}__content__item--options`}>
                  <Select
                    showSearch
                    allowClear
                    style={item.style || { width: 200 }}
                    placeholder={item.placeholder}
                    optionFilterProp="children"
                    value={data[item.name]}
                    defaultValue={item.defaultValue || ''}
                    mode={item.mode || 'default'}
                    notFoundContent={item.notFoundContent}
                    onChange={(value) => handleChange(item.name, value)}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    disabled={item.options.length == 0 && districtsProgress != progressTypes.WORK}
                  >
                    {item.options && item.options.map((option, optionsKey) => (
                      <Option key={`options_${key}_${optionsKey}`} value={option.value}>{option.label}</Option>
                    ))}
                  </Select>
                </div>
              </div>
            ))
          }
          <div className={`${className}__content__options`}>
            <div className={`${className}__content__options--header`}>
              Тип / особенности ОО
            </div>
            <div className={`${className}__content_features`}>
              {
                schoolFeatures.map(f => (
                  <Checkbox
                    key={f.name}
                    checked={data[f.name]}
                    onChange={(e) => handleChange(f.name, e.target.checked)}
                  >
                  {f.placeholder}</Checkbox>
                ))
              }
              
            </div>
          </div>
          <div className={`${className}__content__options`}>
            <div className={`${className}__content__options--header`}>
              Является ТОМ
            </div>
            <Checkbox onChange={({ target: { checked }}) => handleChange('tom', checked)}>Да</Checkbox>
          </div>
          <Button
            type="primary"
            className={`${className}__button`}
            onClick={props.onSubmit}
            loading={progress === progressTypes.WORK}
            disabled={onFormInvalid}
          >
            Показать
          </Button>
        </div>
      </div>
    </div>
  );
};

DirectoryFilterOptionsPopup.propTypes = {
  regions: PropTypes.array,
  districts: PropTypes.array,
  handleChange: PropTypes.func,
  data: PropTypes.object,
};

export default DirectoryFilterOptionsPopup;
