import { call, put } from 'redux-saga/effects';
import SignoutActions from 'redux/signout';
import { checkUserAuthorization } from 'saga/app';

export function * signout (api) {
  try {
    yield call(api.signout);
    yield call(checkUserAuthorization, api);
    yield put(SignoutActions.sendSignoutSuccess());
  } catch (error) {
    yield put(SignoutActions.sendSignoutError());
  }
}
