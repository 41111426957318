import React, { PureComponent } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import './ProcedureTitle.css';

class ProcedureTitle extends PureComponent {
  static propTypes = {
    procedure: PropTypes.object,
    report: PropTypes.object,
    onBtnClick: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.number,
  };

  render() {
    const { procedure, type, data, report } = this.props;

    const demo = get(data[0], 'links.demo');
    const specific = get(data[0], 'links.specific');
    const { report_type: reportType } = report;
    return (
      <div className="procedure-title">
        <div className="procedure-title__col">
          <div className="procedure-title__name">{procedure.name}</div>
          <div className="procedure-title__year">{procedure.year.name}</div>
          <div className="procedure-title__separator">/</div>
          <div className="procedure-title__level">{procedure.level.name} класс</div>
          <div className="procedure-title__subject">{procedure.subject.name}</div>
          {
            reportType !== 5 &&
            <Button size={'small'} type="primary" onClick={() => this.props.onBtnClick(type, data, report, procedure)}>Выгрузить в XLS</Button>
          }
        </div>
        <div className="procedure-title__col">
          {demo && <span className="procedure-title__href-separator">/</span>}
          {demo && <a href={demo} className="procedure-title__href" target="_blank" rel="noopener noreferrer">демо-версия</a>}
          {specific && <span className="procedure-title__href-separator">/</span>}
          {specific && <a href={specific} className="procedure-title__href" target="_blank" rel="noopener noreferrer">спецификация</a>}
        </div>
      </div>
    );
  }
}

export default ProcedureTitle;
