import React from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { selectUserName } from 'redux/app';
import Header from 'components/Header/Header';

var mapStateToProps = ({ app }) => ({
  userName: selectUserName(app),
});

const CustomLayout = ({ userName, ...props}) => (
  <Layout className="layout">
    <Header userName={userName} />
    {props.children}
  </Layout>
);

export default connect(mapStateToProps, null)(CustomLayout);