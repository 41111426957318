import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Sticky, StickyContainer } from 'react-sticky';
import sortBy from 'lodash/sortBy';

import groupDigits from 'utils/groupDigits';
import './ReportTwo.css';

const getValueByName = (attribs, name) => {
  const fld = attribs.find((item) => item.name === name);

  return (fld && fld.value)
    ? groupDigits(fld.value)
    : '-';
};

//Достижение планируемых результатов
class ReportTwo extends Component {
  static propTypes = {
    data: PropTypes.array,
  }

  render() {
    const { data } = this.props;

    return (
      <div className="report-two">
        {data.map((member, key) => {
          if (!member.tasks.length) {
            return (
              <div key={key}>
                <div className="report-two__header">
                  <div className="report-two__header-num">ОО</div>
                  <div className="report-two__header-max">МАКС БАЛЛ</div>
                  <div className="report-two__header-balls">Средний % выполнения заданий</div>
                  {/*
                  <div className="report-two__header-balls">
                    <div className="report-two__header-balls-title">Средний % выполнения по "{member.short_name || member.name}"</div>
                  </div>
                  <div className="report-two__header-balls">
                    <div className="report-two__header-balls-title">Средний % выполнения по "Всей России"</div>
                  </div>
                  */}
                </div>
                <div className="report-two__rows" key={key}>
                  <div className="report-two__row" key={key}>
                    <div className="report-two__row-empty">Нет данных</div>
                  </div>
                </div>
              </div>
            );
          }

          return (
            <StickyContainer key={key}>
              <Sticky>
                {
                  ({ style }) => (
                    <div style={{...style, zIndex: 1}}>
                      <div className="report-two__header">
                        <div className="report-two__header-num">ОО</div>
                        <div className="report-two__header-max">Макс. бал</div>
                        <div className="report-two__header-balls">Средний % выполнения заданий</div>
                      </div>

                      <div className="report-two__container">
                        <div className="report-two__sum">
                          <div className="report-two__sum--member">
                            {member.short_name || member.name}
                          </div>
                          <div className="report-two__sum--max"></div>
                          <div className="report-two__sum--balls">
                            <div className="report-two__sum--balls-box">
                              <div className="report-two__sum--balls-title">Кол-во учеников в ОО</div>
                              <div className="report-two__sum--balls-number">{getValueByName(member.attribs, 'COUNTP')}</div>
                            </div>
                            <div className="report-two__sum--balls-box">
                              <div className="report-two__sum--balls-title">Вся выборка (РФ)</div>
                              <div className="report-two__sum--balls-number">{getValueByName(member.paramsAll.attribs, 'COUNTP')}</div>
                            </div>
                          </div>
                          {/* <div className="report-two__sum-balls">
                            <div className="report-two__sum-balls-cols">
                              <div className="report-two__sum-balls-col"></div>
                              <div className="report-two__sum-balls-col">{getValueByName(member.attribs, 'COUNTP')}</div>
                              <div className="report-two__sum-balls-col">{getValueByName(member.paramsAll.attribs, 'COUNTP')}</div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  )
                }
              </Sticky>

              <div className="report-two__rows" key={key}>
                {sortBy(member.columns, item => item.position)
                  .filter(item => item !== 'COUNTP' && item !== 'BADSCHOOL')
                  .map((colum, key) => {
                    const task = member.tasks.find(item => item.column_name === colum.name.replace('_S', ''));

                    if (!task) {
                      return null;
                    }

                    return (
                      <div className="report-two__row" key={key}>
                        <div className="report-two__row-num">{task.num}</div>
                        <div className="report-two__row-task">
                          {
                            task.task
                              .replace('<--', '')
                              .replace('<-', '')
                              .replace('-->', '')
                              .replace('->', '')
                              .replace('<<', '')
                              .replace('>>', '')
                          }
                        </div>
                        <div className="report-two__row-max">{task.max_point}</div>
                        <div className="report-two__row-balls">{getValueByName(member.attribs, `${task.column_name}_S`)}</div>
                        <div className="report-two__row-balls">{getValueByName(member.paramsAll.attribs, `${task.column_name}_S`)}</div>
                      </div>
                    );
                  })
                }
              </div>
            </StickyContainer>
          );
        })}

      </div>
    );
  }

}

export default ReportTwo;
