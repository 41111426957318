/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Select, Spin, Button } from 'antd';

import progressTypes from 'constants/progressTypes';

const Option = Select.Option;

var YEARS = [
  { value: '2016', label: '2016/2017' },
  { value: '2017', label: '2017/2018' },
  { value: '2018', label: '2018/2019' },
];

var DEFAULT_SELECT_YEAR = {
  name: 'filter_year',
  options: YEARS,
  placeholder: 'Год',
  mode: 'multiple',
  defaultValue: [],
};

const DirectoryAteFilterPopup = (props) => {
  var {
    regions,
    districts,
    progress,
    data,
    handleChange,
    resultsProgress,
  } = props;

  var list = [
    DEFAULT_SELECT_YEAR,
    { name: 'region', options: [{ code: 0, id: 0, label: 'Все регионы', name: 'Все регионы', value: 'all' }, ...regions], placeholder: 'Регион', mode: 'multiple', defaultValue: [] },
    { name: 'districts', options: districts, defaultValue: [], mode: 'multiple', placeholder: 'Муниципалитет', disabled: data['region'].length !== 1 },
  ];

  var className = 'directory-filter-options-popup';
  return (
    <div className={`${className}`}>
      <div className={`${className}__col`}>
        <div className={`${className}__content`}>
          {
            list.map((item, key) => (
              <div
                className={`${className}__content__item`}
                key={`select_${key}`}
              >
                <div className={`${className}__content__item--header`}>{item.placeholder}</div>
                <div className={`${className}__content__item--options ${className}__content__item--min`}>
                  <Select
                    showSearch
                    allowClear
                    style={{ width: 200 }}
                    placeholder={item.placeholder}
                    optionFilterProp="children"
                    value={data[item.name]}
                    defaultValue={item.defaultValue || ''}
                    mode={item.mode || 'default'}
                    notFoundContent={progress === progressTypes.WORK ? <Spin size="small" /> : null}
                    onChange={(value) => handleChange(item.name, value)}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    disabled={(item.options.length == 0 && progress != progressTypes.WORK) || item.disabled}
                  >
                    {item.options && item.options.map((option, optionsKey) => (
                      <Option key={`options_${key}_${optionsKey}`} value={option.value}>{option.label}</Option>
                    ))}
                  </Select>
                </div>
              </div>
            ))
          }
          <Button
            type="primary"
            className={`${className}__button`}
            onClick={props.onSubmit}
            loading={resultsProgress === progressTypes.WORK}
            disabled={props.onFormInvalid}
          >
            Показать
          </Button>
        </div>
      </div>
    </div>
  );
};

DirectoryAteFilterPopup.propTypes = {
  regions: PropTypes.array,
  districts: PropTypes.array,
  schools: PropTypes.array,
  handleChange: PropTypes.func,
  data: PropTypes.object,
};

export default DirectoryAteFilterPopup;
