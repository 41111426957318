import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import ClickOutside from 'react-click-outside';
import ReportFilterItem from 'components/ReportFilterItem/ReportFilterItem';
import ReportFilterReportsPopup from 'containers/ReportFilterReportsPopup/ReportFilterReportsPopup';
import { selectSelectedReportsCount } from 'redux/report';
import './ReportFilterReports.css';

const mapStateToProps = ({ report }) => ({
  count: selectSelectedReportsCount(report),
});

const mapActionsToProps = {};

class ReportFilterReports extends Component {
  static propTypes = {
    count: PropTypes.number,
  }

  state = {
    isOpen: false,
  }

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  }

  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const { count, isSchoolUser } = this.props;
    const { isOpen } = this.state;
    const popupClassName = classnames('report-filter-reports__popup', {
      'report-filter-reports__popup_open': isOpen,
      'report-filter-reports__popup_close': !isOpen,
    });

    return (
      <ClickOutside onClickOutside={this.handleClose}>
        <div className="report-filter-reports">
          <ReportFilterItem
            title={'Отчеты'}
            count={count}
            isOpen={isOpen}
            onClick={this.handleToggle}
          />
          <div className={popupClassName}>
            <ReportFilterReportsPopup isSchoolUser={isSchoolUser}/>
          </div>
        </div>
      </ClickOutside>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportFilterReports);
