/* eslint-disable */ 

import React, { Component, Fragment } from 'react';
import { Button, Modal, Select } from 'antd';

const Option = Select.Option;

class ReportSchoolGroups extends Component {
  state = {
    isModalVisible: false,
    groups: [],
  }

  showModal = () => {
    var { chosenGroups } = this.props;
    this.setState({
      groups: chosenGroups,
      isModalVisible: true,
    });
  }
  
  handleOk = () => {
    this.props.onSetReportGroup(this.state.groups);
    this.handleCancel();
  }

  handleCancel = () => {
    var { chosenGroups } = this.props;
    this.setState({
      isModalVisible: false,
      groups: chosenGroups,
    });
  }

  handleChangeGroup = res => { 
    var { groups } = this.props;
    var elements = res.map(x => groups.find(group => group.id == x));
    this.setState({ groups: elements });
  }

  render() {
    var { groups, chosenGroups } = this.props;
    var { groups: stateGroups } = this.state;
    var buttonText = 'Выбрать группы';
    var chosenGroupsValue = stateGroups.map(group => group.id);
    if (chosenGroups.length > 0) {
      buttonText = `Выбрано: ${chosenGroups.length}`;
    } 
    return (
      <Fragment>
        <Button
          type="dashed"
          style={{ alignSelf: 'center' }}
          disabled={groups.length == 0}
          onClick={this.showModal}
        >{buttonText}</Button>
        <Modal
          title="Выбрать группу для оценивания"
          visible={this.state.isModalVisible}
          cancelText="Отмена"
          okText="Сохранить"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose={true}
        >
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Выбрать группы школ"
            value={chosenGroupsValue}
            onChange={this.handleChangeGroup}
          >
            {
              groups.map((group) => <Option key={group.id} value={group.id}>{group.name}</Option>)
            }
          </Select>
        </Modal>
      </Fragment>
    );
  }
}

export default ReportSchoolGroups;