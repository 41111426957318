import { call, put, all, select } from 'redux-saga/effects';
import DirectoryProcActions from 'redux/directoryProc';

var getSelectedProcedures = ({ directoryProc }) => directoryProc.selectedProcedures;

export function* getDirectoryProcOptions(api) {
  try {
    const [options, subjects] = yield all([
      call(api.getProcedure),
      call(api.getSubjects),
    ]);
    const optionsData = options.results.map((item, key) => ({
      ...item,
      selected: false,
      checked: false,
      disabled: false,
      hasSelectedChildren: false,
    }));
    yield put(DirectoryProcActions.getOptionsSuccess(optionsData, subjects.results));
  } catch (error) {
    yield put(DirectoryProcActions.getOptionsError(error));
  }
}

export function* getDirectoryProcResults(api) {
  try {
    var procedures = yield select(getSelectedProcedures);
    var results = yield all(procedures.map(proc => {
      var params = {};
      params.level = proc.level.id;
      params.year = proc.year.id;
      params.exam_subj = proc.subject.id;
      params.procedure = proc.id;
      return call(api.getProceduresDir, params)
    }));
    var res = results.reduce((acc, item) => {
      return {
        count: acc.count + item.count,
        results: [...acc.results, ...item.results],
      };
    }, { count: 0, results: [] });
    yield put(DirectoryProcActions.getResultsSuccess(res));
  } catch (error) {
    yield put(DirectoryProcActions.getResultsError(error));
  }
}