import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Layout, Menu, Icon } from 'antd';
import './Header.css';

const menuItems = [
  {
    to: 'https://lk-fisoko.obrnadzor.gov.ru/',
    key: 'dataExchange',
    translationId: 'header.menu.dataExchange',
    external: true,
  },
  {
    to: '/',
    key: 'report',
    translationId: 'header.menu.reports',
  },
  // {
  //   to: '/olap',
  //   key: 'olap',
  //   translationId: 'header.menu.olap',
  // },

  {
    to: '/directory',
    key: 'directory',
    translationId: 'header.menu.dict',
  },
  {
    to: 'https://mgmt-oko.obrnadzor.gov.ru/',
    key: 'order',
    translationId: 'header.menu.order',
    external: true,
  },
  {
    to: 'http://185.12.28.6/vpr/rt1.php',
    key: 'questionAnswer',
    translationId: 'header.menu.questionAnswer',
    external: true, 
  },
];

const CustomLink = ({ link, external, ...props }) => (
  external ?
    <a href={link} target="_blank" rel="noopener noreferrer">{props.children}</a> :
    <Link to={link}>{props.children}</Link>
);

class Header extends Component {
  render() {
    const { location, userName } = this.props;
    const selectedKey = menuItems.find((item) => item.to === location.pathname);

    return (
      <Layout.Header className="header">
        <div className="header__content">
          <div className="header__left">

            <div className="logo">
              <Link to="/" className="logo__href"><FormattedMessage id="header.logo"/></Link>
            </div>

            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={[selectedKey.key]}
              prefixCls="menu"
            >
              {menuItems.map((item) => (
                <Menu.Item key={item.key}>
                  <CustomLink link={item.to} external={item.external}>
                    {
                      item.key !== 'questionAnswer'
                      ? <FormattedMessage id={item.translationId} />
                      : <Icon type="question-circle" style={{ fontSize: 16 }}/>
                    }
                  </CustomLink>
                </Menu.Item>
              ))}
            </Menu>
          </div>
          <div className="header__right">
            <span className="header__user_name">{userName}</span>
            <Link to="/signout">
              <Icon type="logout" className="header__user-icon" style={{ fontSize: 16 }}/>
            </Link>
          </div>
        </div>
      </Layout.Header>
    );
  }
}

export default withRouter(Header);
