import React, { Component } from 'react';
import ReactTable from "react-table";
import DirectoryHeader from 'components/DirectoryHeader';
import getXlsx from 'utils/xlsx';
import { tableText as tableDefaultTexts} from 'constants/tableSettings';

class NoProcReport extends Component {
  downloadTable = () => {
    var { results, headers, fileName } = this.props;
    getXlsx(fileName, headers, results);
  }

  render() {
    var { results, headers, title, isDownload, showHeader } = this.props;

    return (
      <div>
        {showHeader == undefined && <DirectoryHeader
          text={title}
          isShown={false}
          isDownload={isDownload === undefined}
          onDownload={this.downloadTable}
          onShowModal={() => {}}
        />}
        <ReactTable
          data={results}
          columns={headers}
          minRows={0}
          {...tableDefaultTexts}
        />
      </div>
    );
  }
}

export default NoProcReport;

    