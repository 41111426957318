import qs from 'qs';
import apisauce from 'apisauce';
import { isDevelopment } from 'utils/isDevelopment';

function ApiError(message, response, params) {
  this.name = 'Api#ERROR';
  this.message = message || 'Something went wrong';
  this.status = (response && response.status) || null;
  this.data = response.data || {};

  if (isDevelopment) {
    console.log('Api#ERROR', message, response, params);
  }

  this.stack = (new Error()).stack;
}

const URL = '/';
const LIMIT = 1000;

const ENDPOINTS = {
  signin: () => `api/rest-auth/login/`,
  signout: () => `api/rest-auth/logout/`,
  loginOauth: () => `api/oauth/`,

  getUser: () =>`api/rest-auth/user/`,
  getRegions: () => `api/regions/`,
  getDistricts: (id) => `api/districts/?region=${id}`,
  getAllDistricts: (id) => `api/districts/?limit=3000`,
  getSchools: (id) => `api/schools/?region=&district=&city=${id}&limit=1000`,
  // getSchoolsMeta: () => `api/meta-schools/?limit=50000`,
  getProcedure: () => `api/procedure/`,
  getYears: () => `api/years/`,
  getClasses: () => `api/classes/`,
  getSubjects: () => `api/subjects/`,
  getReports: () => `api/reports/`,

  getRegionsCustomList: (params) => `/api/regions/custom_list/?${params}`,
  getDistrictsCustomList: (params) => `/api/districts/custom_list/?${params}`,
  getSchoolsCustomList: (params) => `/api/schools/custom_list/?${params}`,
  getParamsAllCustomList: (params) => `/api/params-all/custom_list/?${params}`,
  getCustomReportList: (params) => `/api/schools/custom_report/?${params}`,

  // TODO return limit=50000
  // getSchoolStat: () => '/api/school-stat/?limit=50000',
  getSchoolStat: () => '/api/school-stat/?limit=50000',
  getStatYears: () => '/api/study-year/',

  getAteRegions: (params) => `/api/regions/ate/?limit=100&${params}`,
  getAteDistricts: (params) => `/api/districts/ate/?limit=50&${params}`,

  // TODO вернуть ?limit=100
  getProceduresDir: () => '/api/data/',
  getProceduresReport: () => '/api/data-small/?limit=100',

  schoolGroups: () => '/api/personal-group/',

  // неоъективное ате
  getBiasDataRegions: (params) => `/api/regions/neob_ate/?${params}`,
  getBiasDataDistricts: (params) => `/api/districts/neob_ate/?${params}`,
  getBiasDataSchools: params => `/api/neef-marks/?${params}`,
  getBiasDataAll: params => `api/regions/neob_ate_all/?${params}`,

  // медалисты
  getMedalsSchools: (params) => `api/schools/reward/?${params}`,
  getMedalsRegions: (params) => `api/regions/reward/?${params}`,
  getMedalsDistricts: (params) => `api/districts/reward/?${params}`,

  getMedalsSchoolsAll: () => 'api/schools/reward_all/',
  getMedalsRegionsAll: () => 'api/regions/reward_all/',
  getMedalsDistrictsAll: () => 'api/districts/reward_all/',

  // поступление
  getAdmissionsRegions: params => `api/regions/postuplenie/?${params}`,
  getAdmissionsDistricts: params => `api/districts/postuplenie/?${params}`,
  getAdmissionsSchools: params => `api/schools/postuplenie/?${params}`,

  getAdmissionsAll: () => 'api/regions/postuplenie_all/',

  // групповые индексы
  getGroupIndexRegions: params => `api/regions/group_index/?${params}`,
  getGroupIndexDistricts: params => `api/districts/group_index/?${params}`,
  getGroupIndexSchools: params => `api/schools/group_index/?${params}`,
  getGroupIndexAll: (params) => `api/regions/group_index_all/?${params}`,

  getEvaluations: () => 'api/evaluation-systems/',
  getRegionIndicators: () => 'api/system-rating/',

  // инд результаты участников
  getIndividualResults: () => `api/personal-results/?limit=${LIMIT}`,
};

const create = (baseURL = URL) => {

  const api = apisauce.create({
    baseURL,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
  });

  async function signin(params) {
    const response = await api.post(ENDPOINTS.signin(), params);

    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.signin()} =>`, response);
    }
  }

  async function signout() {
    const response = await api.get(ENDPOINTS.signout());

    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.signout()} =>`, response);
    }
  }

  async function getUser() {
    const response = await api.get(ENDPOINTS.getUser());

    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getUser()} =>`, response);
    }
  }

  async function getRegions() {
    const response = await api.get(ENDPOINTS.getRegions(), { limit: LIMIT });

    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getRegions()} =>`, response);
    }
  }

  async function getDistricts(id) {
    const response = await api.get(ENDPOINTS.getDistricts(id), { limit: LIMIT });

    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getDistricts(id)} =>`, response);
    }
  }

  async function getAllDistricts() {
    const response = await api.get(ENDPOINTS.getAllDistricts());

    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getAllDistricts()} =>`, response);
    }
  }

  async function getSchoolStat(params) {
    const response = await api.get(ENDPOINTS.getSchoolStat(), params);

    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getSchoolStat()} =>`, response);
    }
  }

  async function getSchools(id) {
    const response = await api.get(ENDPOINTS.getSchools(id));

    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getSchools(id)} =>`, response);
    }
  }

  async function getProcedure() {
    const response = await api.get(ENDPOINTS.getProcedure());
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getProcedure()} =>`, response);
    }
  }

  async function getYears(params) {
    const response = await api.get(ENDPOINTS.getYears(), params);
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getYears()} =>`, response);
    }
  }

  async function getClasses(params) {
    const response = await api.get(ENDPOINTS.getClasses(), params);

    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getClasses()} =>`, response);
    }
  }

  async function getSubjects(params) {
    const response = await api.get(ENDPOINTS.getSubjects(), params);

    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getSubjects()} =>`, response);
    }
  }

  async function getReports() {
    const response = await api.get(ENDPOINTS.getReports());

    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getReports()} =>`, response);
    }
  }

  async function getRegionsCustomList(data) {
      const params = qs.stringify(data, { indices: false });
      const response = await api.get(ENDPOINTS.getRegionsCustomList(params));

    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getRegionsCustomList()} =>`, response, params);
    }
  }

  async function getDistrictsCustomList(data) {
    const params = qs.stringify(data, { indices: false });
    const response = await api.get(ENDPOINTS.getDistrictsCustomList(params));

    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getDistrictsCustomList()} =>`, response, params);
    }
  }

  async function getSchoolsCustomList(data) {
    const params = qs.stringify(data, { indices: false });
    const response = await api.get(ENDPOINTS.getSchoolsCustomList(params));

    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getSchoolsCustomList()} =>`, response, params);
    }
  }

  async function getParamsAllCustomList(data) {
    const params = qs.stringify(data, { indices: false });
    const response = await api.get(ENDPOINTS.getParamsAllCustomList(params));

    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getParamsAllCustomList()} =>`, response, params);
    }
  }

  async function getCustomReportList(data) {
    const params = qs.stringify(data, { indices: false });
    const response = await api.get(ENDPOINTS.getCustomReportList(params));

    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getCustomReportList()} =>`, response, params);
    }
  }

  async function getAteRegions(params) {
    var parsedParams = qs.stringify({ ids: params.region }, { indices: false });
    const response = await api.get(ENDPOINTS.getAteRegions(parsedParams));
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getAteRegions()} =>`, response, params);
    }
  }

  async function getAteDistricts(params) {
    var parsedParams = qs.stringify({ ids: params.districts }, { indices: false });
    const response = await api.get(ENDPOINTS.getAteDistricts(parsedParams));
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getAteDistricts()} =>`, response, params);
    }
  }

  async function getProceduresDir(params) {
    const response = await api.get(ENDPOINTS.getProceduresDir(), params);
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getProceduresDir()} =>`, response, params);
    }
  }

  async function addSchoolGroup(params) {
    const response = await api.post(ENDPOINTS.schoolGroups(), params);
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.schoolGroups()} =>`, response, params);
    }
  }

  async function getSchoolGroups() {
    const response = await api.get(ENDPOINTS.schoolGroups());
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.schoolGroups()} =>`, response);
    }
  }

  async function getStatYears() {
    const response = await api.get(ENDPOINTS.getStatYears());
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getStatYears()} =>`, response);
    }
  }

  async function getBiasDataRegions(params) {
    var parsedParams = qs.stringify({ ids: params.ids, filter_sample: params.sample }, { indices: false });
    const response = await api.get(ENDPOINTS.getBiasDataRegions(parsedParams));
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getBiasDataRegions()} =>`, response);
    }
  }

  async function getBiasDataDistricts(params) {
    var parsedParams = qs.stringify({ ids: params.ids, filter_sample: params.sample }, { indices: false });
    const response = await api.get(ENDPOINTS.getBiasDataDistricts(parsedParams));
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getBiasDataDistricts()} =>`, response);
    }
  }

  async function getBiasDataSchools(params) {
    var parsedParams = qs.stringify({ sample: params.sample, schools: params.ids }, { indices: false });
    const response = await api.get(ENDPOINTS.getBiasDataSchools(parsedParams));
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getBiasDataSchools()} =>`, response);
    }
  }

  async function getBiasDataAll(params) {
    var parsedParams = qs.stringify({ filter_sample: params.sample }, { indices: false });
    const response = await api.get(ENDPOINTS.getBiasDataAll(parsedParams));
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getBiasDataAll()} =>`, response);
    }
  }

  async function getMedalsSchools(params) {
    var parsedParams = qs.stringify({ ids: params }, { indices: false });
    const response = await api.get(ENDPOINTS.getMedalsSchools(parsedParams));
    const responseAll = await api.get(ENDPOINTS.getMedalsSchoolsAll());
  
    if (response.ok && responseAll.ok) {
      return {
        count: response.data.count + responseAll.data.count,
        results: [
          {
            name: 'Вся выборка',
            ...responseAll.data.results[0],
          },
          ...response.data.results,
        ],
      };
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getMedalsSchools()} =>`, response);
    }
  }

  async function getMedalsRegions(params) {
    var parsedParams = qs.stringify({ ids: params }, { indices: false });
    const response = await api.get(ENDPOINTS.getMedalsRegions(parsedParams));
    const responseAll = await api.get(ENDPOINTS.getMedalsRegionsAll());
  
    if (response.ok && responseAll.ok) {
      return {
        count: response.data.count + responseAll.data.count,
        results: [
          {
            name: 'Вся выборка',
            ...responseAll.data.results[0],
          },
          ...response.data.results,
        ],
      };
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getMedalsRegions()} =>`, response);
    }
  }

  async function getMedalsDistricts(params) {
    var parsedParams = qs.stringify({ ids: params }, { indices: false });
    const response = await api.get(ENDPOINTS.getMedalsDistricts(parsedParams));
    const responseAll = await api.get(ENDPOINTS.getMedalsDistrictsAll());
  
    if (response.ok && responseAll.ok) {
      return {
        count: response.data.count + responseAll.data.count,
        results: [
          {
            name: 'Вся выборка',
            ...responseAll.data.results[0],
          },
          ...response.data.results,
        ],
      };
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getMedalsDistricts()} =>`, response);
    }
  }

  async function getAdmissionsRegions(params) {
    var parsedParams = qs.stringify({ ids: params }, { indices: false });
    const response = await api.get(ENDPOINTS.getAdmissionsRegions(parsedParams));
    const responseAll = await api.get(ENDPOINTS.getAdmissionsAll());
    if (response.ok && responseAll.ok) {
      return {
        count: response.data.count + responseAll.data.count,
        results: [
          {
            name: 'Вся выборка',
            ...responseAll.data.results[0],
          },
          ...response.data.results,
        ],
      };
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getAdmissionsRegions()} =>`, response);
    }
  }

  async function getAdmissionsDistricts(params) {
    var parsedParams = qs.stringify({ ids: params }, { indices: false });
    const response = await api.get(ENDPOINTS.getAdmissionsDistricts(parsedParams));
    const responseAll = await api.get(ENDPOINTS.getAdmissionsAll());
    if (response.ok && responseAll.ok) {
      return {
        count: response.data.count + responseAll.data.count,
        results: [
          {
            name: 'Вся выборка',
            ...responseAll.data.results[0],
          },
          ...response.data.results,
        ],
      };
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getAdmissionsDistricts()} =>`, response);
    }
  }

  async function getAdmissionsSchools(params) {
    var parsedParams = qs.stringify({ ids: params }, { indices: false });
    const response = await api.get(ENDPOINTS.getAdmissionsSchools(parsedParams));
    const responseAll = await api.get(ENDPOINTS.getAdmissionsAll());
    if (response.ok && responseAll.ok) {
      return {
        count: response.data.count + responseAll.data.count,
        results: [
          {
            name: 'Вся выборка',
            ...responseAll.data.results[0],
          },
          ...response.data.results,
        ],
      };
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getAdmissionsSchools()} =>`, response);
    }
  }

  async function getGroupIndexRegions(params) {
    var parsedParams = qs.stringify({ ids: params.ids }, { indices: false });
    var customParams = Object.assign({}, params);
    delete customParams.ids;
    const response = await api.get(ENDPOINTS.getGroupIndexRegions(parsedParams), customParams);
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getGroupIndexRegions()} =>`, response);
    }
  }

  async function getGroupIndexDistricts(params) {
    var parsedParams = qs.stringify({ ids: params.ids }, { indices: false });
    var customParams = Object.assign({}, params);
    delete customParams.ids;
    const response = await api.get(ENDPOINTS.getGroupIndexDistricts(parsedParams), customParams);
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getGroupIndexDistricts()} =>`, response);
    }
  }

  async function getGroupIndexSchools(params) {
    var parsedParams = qs.stringify({ ids: params.ids }, { indices: false });
    var customParams = Object.assign({}, params);
    delete customParams.ids;
    const response = await api.get(ENDPOINTS.getGroupIndexSchools(parsedParams), customParams);
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getGroupIndexSchools()} =>`, response);
    }
  }

  async function getProceduresReport() {
    const response = await api.get(ENDPOINTS.getProceduresReport());
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getProceduresReport()} =>`, response);
    }
  }

  async function loginOauth() {
    const response = await api.get(ENDPOINTS.loginOauth());
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.loginOauth()} =>`, response);
    }
  }

  async function getEvaluations() {
    const response = await api.get(ENDPOINTS.getEvaluations());
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getEvaluations()} =>`, response);
    }
  }

  async function getRegionIndicators(params) {
    const response = await api.get(ENDPOINTS.getRegionIndicators(), params);
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getRegionIndicators()} =>`, response);
    }
  }

  async function getGroupIndexAll(params) {
    var parsedParams = qs.stringify({ ids: params.ids }, { indices: false });
    var customParams = Object.assign({}, params);
    delete customParams.ids;
    const response = await api.get(ENDPOINTS.getGroupIndexAll(parsedParams), customParams);
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getGroupIndexAll()} =>`, response);
    }
  }

  async function getIndividualResults(params) {
    const response = await api.get(ENDPOINTS.getIndividualResults(), params);
    if (response.ok) {
      return response.data;
    } else {
      throw new ApiError(`${URL}${ENDPOINTS.getIndividualResults()} =>`, response);
    }
  }

  return {
    signin,
    signout,
    getUser,
    getRegions,
    getDistricts,
    getSchools,
    getSchoolStat,
    getProcedure,
    getYears,
    getClasses,
    getSubjects,
    getReports,
    getRegionsCustomList,
    getDistrictsCustomList,
    getSchoolsCustomList,
    getParamsAllCustomList,
    getCustomReportList,
    getAllDistricts,
    getAteRegions,
    getAteDistricts,
    getProceduresDir,
    addSchoolGroup,
    getSchoolGroups,
    getStatYears,
    getBiasDataRegions,
    getBiasDataDistricts,
    getBiasDataSchools,
    getBiasDataAll,
    getMedalsSchools,
    getMedalsDistricts,
    getMedalsRegions,
    getAdmissionsRegions,
    getAdmissionsDistricts,
    getAdmissionsSchools,
    getGroupIndexRegions,
    getGroupIndexDistricts,
    getGroupIndexSchools,
    getGroupIndexAll,
    getProceduresReport,
    loginOauth,
    getEvaluations,
    getRegionIndicators,
    getIndividualResults,
  };
};

export default {
  create,
};
