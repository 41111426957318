import { call, put, all } from 'redux-saga/effects';
import DirectoryAteActions from 'redux/directoryAte';
import sortBy from 'lodash/sortBy';

export function* getDirectoryDataAte(api) {
  try {
    const [ regions ] = yield all([
      call(api.getRegions),
    ]);

    const normalizedRegions = sortBy(regions.results.map(item => ({
      name: item.name,
      label: `${item.code} ${item.name}`,
      id: item.id,
      value: `${item.id}`,
      code: parseInt(item.code, 10),
    })), 'code');

    yield put(DirectoryAteActions.getDirectoryDataSuccess(normalizedRegions));
  } catch (error) {
    yield put(DirectoryAteActions.getDirectoryDataError(error));
  }
}

export function* getDirectoryDistrictsAte(api, { id }) {
  try {
    const districts = yield call(api.getDistricts, id);

    const normalizedDistricts = districts.results.map(item => ({
      name:item.name,
      label:item.name,
      region: item.region,
      id: item.id,
      value: `${item.id}`,
    }));

    yield put(DirectoryAteActions.getDirectoryDistrictsSuccess(normalizedDistricts));
  } catch (error) {
    yield put(DirectoryAteActions.getDirectoryDistrictsError(error));
  }
}


export function* getDirectoryAteResults(api, { params }) {
  try {
    var data;
    var { districts } = params;
    if (districts.length > 0) {
      var [r, d] = yield all([
        call(api.getAteRegions, params),
        call(api.getAteDistricts, params),
      ]);
      data = {
        count: r.count + d.count,
        results: [...r.results, ...d.results],
      };
    } else {
      data = yield call(api.getAteRegions, params);
    }

    yield put(DirectoryAteActions.getDirectoryResultsSuccess(data));
  } catch (error) {
    yield put(DirectoryAteActions.getDirectoryResultsError(error));
  }
}
