import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import { Button } from 'antd';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  }

  state = {
    error: null,
  }

  componentDidCatch (error, errorInfo) {
    this.setState({
      error,
    });

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureEvent({
        message: 'Произошла ошибка при формировании отчёта',
        stacktrace: error,
      });
    });
  }

  reportError = () => {
    Sentry.showReportDialog();
  }

  render () {
    var { error } = this.state;
    if (error) {
      return (
        <div style={{ width: '100%', padding: '20px 0 0 20px' }}>
          <h2>Что-то пошло не так</h2>
          <Button size={'small'} type="primary" onClick={this.reportError}>Сообщить об ошибке</Button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
