import React from 'react';
import { Spin, Cascader, Button } from 'antd';
import ReportFilterProceduresPopup from 'containers/ReportFilterProceduresPopup/ReportFilterProceduresPopup';
import progressTypes from 'constants/progressTypes';
import './DirectoryProcFilterPopup.css';

var CASCADER_STYLE = {
  width: '100%',
};

function filter(inputValue, path) {
  return (path.some(option => (option.label).toLowerCase().indexOf(inputValue.toLowerCase()) > -1));
}

const DirectoryProcFilterPopup = (props) => {
  var {
    resultsProgress,
    optionsProgress,
    isFormInvalid,
  } = props;
  
  var className = 'directory-proc-filter-popup';
  return (
    <div className={`${className}`}>
        {/* <Cascader
          options={props.options}
          onChange={props.onChange}
          disabled={optionsProgress === progressTypes.WORK }
          style={CASCADER_STYLE}
          notFoundContent={optionsProgress === progressTypes.WORK ? <Spin size="small" /> : null}
          showSearch={{ filter }}
          placeholder="Выберите процедуру"
        /> */}
        <ReportFilterProceduresPopup
            addSelectedProcedure={props.onAddProcedure}
            removeSelectedProcedure={props.onRemoveProcedure}
            procedures={props.options}
            tags={props.tags}
          />
        <Button
          type="primary"
          className={`${className}__button`}
          onClick={props.onSubmit}
          loading={resultsProgress === progressTypes.WORK}
          disabled={isFormInvalid}
        >
          Показать
        </Button>
    </div>
  );
};

export default DirectoryProcFilterPopup;
