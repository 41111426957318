/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import DirectoryAteActions, {
  selectRegions,
  selectDistricts,
  selectProgress,
  selectResultsProgress,
  selectOptions
} from 'redux/directoryAte';
import DirectoryFilterWrapper from 'components/DirectoryFilterWrapper';
import DirectoryAteFilterPopup from 'components/DirectoryAteFilterPopup';

const mapStateToProps = ({ directoryAte }) => ({
  regions: selectRegions(directoryAte),
  districts: selectDistricts(directoryAte),
  progress: selectProgress(directoryAte),
  resultsProgress: selectResultsProgress(directoryAte),
  options: selectOptions(directoryAte),
});

const mapActionsToProps = {
  getDirectoryDistricts: DirectoryAteActions.getDirectoryDistricts,
  getData: DirectoryAteActions.getDirectoryData,
  updateOption: DirectoryAteActions.updateOption,
};

class DirectoryAteFilter extends Component {

  static propTypes = {
    regions: PropTypes.array,
    districts: PropTypes.array,
    onSubmit: PropTypes.func,
    progress: PropTypes.string,
    type: PropTypes.string,
    getDirectoryDistricts: PropTypes.func,
  }

  componentDidMount() {
    this.props.getData();
  }

  handleChange = (name, value) => {
    if (name === 'region' && value.includes('all')) {
      var { regions } = this.props
      this.props.updateOption('region', regions.map(region => '' + region.id));
      return 
    }

    this.props.updateOption(name, value)

    if (name === 'region' && !value.includes('all')) {
      if (value.length !== 1) {
        this.props.updateOption('districts', [])
      } else {
        this.props.getDirectoryDistricts(value[0]);
      }
    }
  }

  getParams = () => {
    const params = {};
    var { options } = this.props;
    Object.keys(options).forEach(key => {
      const item = options[key];
      if (item) {
        params[key] = item;
      }
    });

    return params;
  }

  isFormInvalid = () => {
    const { filter_year, region } = this.getParams();
    return !(region && filter_year && filter_year.length > 0);
  }

  handleSubmit = () => {
    const params = this.getParams();
    // особенности школы
    if (params.feature) {
      params[params.feature] = true;
      delete params.feature;
    }
    if (!isEmpty(params)) {
      var { type } = this.props;
      params.type = type;
      this.props.onSubmit(params);
      this.props.onHandleClose();
    }
  }

  render() {
    const {
      regions,
      districts,
      schools,
      progress,
      resultsProgress,
      options,
    } = this.props;

    return (
      <DirectoryAteFilterPopup
        data={options}
        regions={regions}
        districts={districts}
        schools={schools}
        progress={progress}
        resultsProgress={resultsProgress}
        handleChange={this.handleChange}
        onSubmit={this.handleSubmit}
        onFormInvalid={this.isFormInvalid()}
      />
    );
  }
}

export default connect(
  mapStateToProps, 
  mapActionsToProps
)(DirectoryFilterWrapper({ name: 'Справочник АТЕ'})(DirectoryAteFilter));
