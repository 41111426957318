import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReportFilterListItem from 'components/ReportFilterListItem/ReportFilterListItem';
import './LevelList.css';

class LevelList extends Component {
  static propTypes = {
    items: PropTypes.array,
    onCheck: PropTypes.func,
    onSelect: PropTypes.func,
  }

  render() {
    const { items } = this.props;

    return (
      <div className="region-list">
        {items.map((item, index) => (
          <ReportFilterListItem
            key={item.id}
            id={item.id}
            index={index}
            name={item.name}
            selected={item.selected}
            checked={item.checked}
            disabled={item.disabled}
            hasSelectedChildren={item.hasSelectedChildren}
            onSelect={this.props.onSelect}
            onCheck={this.props.onCheck}
          />
        ))}
      </div>
    );
  }
}

export default LevelList;
