import { call, put, all, select } from 'redux-saga/effects';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import ReportActions from 'redux/report';
import normalizeRegions from 'utils/normalizeRegions';
import normalizeDistricts from 'utils/normalizeDistricts';
import normalizeSchools from 'utils/normalizeSchools';
import normalizeReports from 'utils/normalizeReports';
const NO_PROC = [8, 9];
const NEOB = [11, 13];
const REGIONS_INDICATORS = 12;
const GROUP_INDEX = 10;
const INDIVIDUAL_RESULTS = 14;

const selectGroupIndexId = ({ report }) => report.reports.find(r => r.report_type == GROUP_INDEX).id;

const selectNeobIndexId = ({ report }) => report.reports.find(r => NEOB.includes(r.report_type) && r.checked);

const MEMBERS_TYPES = {
  SCHOOL: 'SCHOOL',
  DISTRICT: 'DISTRICT',
  REGION: 'REGION',
};

const getSelectedProcedures = ({ report }) => {
  return report.selectedProcedures.reduce((memo, item) => {
    memo[`${item.id}${item.year.name}${item.subject.id}${item.level.id}`] = item;
    return memo;
  }, {});
};

const getCheckedReports = ({ report }) => {
  return report.reports.filter(item => item.checked).reduce((memo, item) => {
    memo[item.id] = item;
    return memo;
  }, {});
};

const getCheckedMembersNames = ({ report }) => {
  const regions = report.checkedRegions.map(item => item.name);
  const districts = report.checkedDistricts.map(item => item.name);
  const schools = report.checkedSchools.map(item => item.name);

  return [...regions, ...districts, ...schools];
};

const getParams = ({ report }) => {
  var regions = [];
  var districts = [];
  var schools;

  if (report.chosenGroups.length > 0) {
    // TODO remove from saga and move to component
    const { chosenGroups } = report;
    const list = chosenGroups.map(x => x.data);
    const listData = [].concat.apply([], Array.isArray(list) ? list : [])
    schools = uniqBy(listData);
  } else {
    regions = report.checkedRegions.map(item => item.id);
    districts = report.checkedDistricts.map(item => item.id);
    schools = report.checkedSchools.map(item => item.id);
  }

  var checkedReports = report.reports.filter(item => item.checked).map((item, index) => ({
    reportId: item.id,
    reportTypeId: item.report_type,
    index,
  }));

  var noProcReports = checkedReports.filter(x => [...NO_PROC, REGIONS_INDICATORS].includes(x.reportTypeId));
  var noProcRequests = [];
  if (noProcReports.length > 0) {
    //  медали
    noProcRequests = noProcReports.map(({ reportId, reportTypeId, index: reportIndex }) => {
      const data = [];
      var apiRegFunc, apiDistrFunc, apiSchFunc;
      switch (reportTypeId) {
        case 8:
          apiRegFunc = 'getMedalsRegions';
          apiDistrFunc = 'getMedalsDistricts';
          apiSchFunc = 'getMedalsSchools';
          break;
        case 9:
          apiRegFunc = 'getAdmissionsRegions';
          apiDistrFunc = 'getAdmissionsDistricts';
          apiSchFunc = 'getAdmissionsSchools';
          break;
        case REGIONS_INDICATORS:
          apiRegFunc = 'getRegionIndicators';
          break;
        default:
          break;
      }

      if (regions.length > 0) {
        data.push({
          reportId: reportId,
          reportTypeId: reportTypeId,
          type: MEMBERS_TYPES.REGION,
          params: {
            ids: regions,
            report: reportTypeId,
            apiFunc: apiRegFunc,
          },
        });
      }

      if (districts.length > 0 && reportTypeId !== REGIONS_INDICATORS) {
        data.push({
          reportId: reportId,
          reportTypeId: reportTypeId,
          type: MEMBERS_TYPES.DISTRICT,
          params: {
            ids: districts,
            report: reportTypeId,
            apiFunc: apiDistrFunc,
          },
        });
      }

      if (schools.length > 0 && reportTypeId !== REGIONS_INDICATORS) {
        data.push({
          reportId: reportId,
          reportTypeId: reportTypeId,
          type: MEMBERS_TYPES.SCHOOL,
          params: {
            ids: schools,
            report: reportTypeId,
            apiFunc: apiSchFunc,
          },
        });
      }

      return data;
    });
  }

  var individualResultsReport = checkedReports.find(x => x.reportTypeId === INDIVIDUAL_RESULTS);
  var individualResultsRequests = [];
  if (individualResultsReport) {
    var { selectedProcedures, chosenGroups, checkedSchools } = report;
    // var schoolId = chosenGroups[0].data[0];
    var schoolIds;
    if (chosenGroups.length > 0) {
      schoolIds = flatten(chosenGroups.map(x => x.data));
    } else {
      schoolIds = checkedSchools.map(x => x.id);
    }

    individualResultsRequests = schoolIds.map(schoolId => 
      selectedProcedures.map(proc => ({
        reportId: individualResultsReport.reportId,
        reportTypeId: individualResultsReport.reportTypeId,
        type: MEMBERS_TYPES.SCHOOL,
        params: {
          school: schoolId,
          class_number: proc.level.id,
          sample__exam_subj: proc.subject.id,
          // sample__study_year: proc.years., TODO ???
          sample__year: proc.year.id,
          apiFunc: 'getIndividualResults',
          report: individualResultsReport.reportTypeId,
        },
      }))
    );
  }

  var neobReport = checkedReports.find(x => NEOB.includes(x.reportTypeId));
  var neobRequests = [];
  if (neobReport) {
    var neobProcedures = report.selectedProcedures.map(proc => {
      var { subject: { id: subjectId }, level: { id: levelId }, year: { id: yearId }, id } = proc;
      
      return report.neobProcedures.find(neobProc => 
        neobProc.procedure == id && neobProc.exam_subj == subjectId && neobProc.year == yearId && neobProc.level == levelId
      ).id;
    });

    const { reportId, reportTypeId } = neobReport;
    if (regions.length > 0) {
      neobRequests.push({
        reportId: reportId,
        reportTypeId: reportTypeId,
        type: MEMBERS_TYPES.REGION,
        params: {
          ids: regions,
          report: reportTypeId,
          sample: neobProcedures,
          apiFunc: 'getBiasDataRegions',
        },
      });
    }
    if (districts.length > 0) {
      neobRequests.push({
        reportId: reportId,
        reportTypeId: reportTypeId,
        type: MEMBERS_TYPES.DISTRICT,
        params: {
          ids: districts,
          sample: neobProcedures,
          report: reportTypeId,
          apiFunc: 'getBiasDataDistricts',
        },
      });
    }

    if (schools.length > 0) {
      neobRequests.push({
        reportId: reportId,
        reportTypeId: reportTypeId,
        type: MEMBERS_TYPES.SCHOOL,
        params: {
          ids: schools,
          sample: neobProcedures,
          report: reportTypeId,
          apiFunc: 'getBiasDataSchools',
        },
      });
    }

  }

  var reports = checkedReports.filter(x => ![...NO_PROC, ...NEOB, REGIONS_INDICATORS, INDIVIDUAL_RESULTS].includes(x.reportTypeId));
  const procedures = report.selectedProcedures;

  const requests = procedures.map(procedure => {
    return reports.map(({ reportId, reportTypeId, index: reportIndex }) => {
      const data = [];

      if (reportTypeId == GROUP_INDEX) {
        if (regions.length > 0) {
          data.push({
            type: MEMBERS_TYPES.REGION,
            reportId: reportId,
            procedureID: `${procedure.id}${procedure.year.name}${procedure.subject.id}${procedure.level.id}`,
            reportTypeId: reportTypeId,
            procedure: procedure,
            params: {
              ids: regions,
              report: reportTypeId,
              filter_proc: procedure.id,
              filter_year: procedure.year.name,
              filter_subject: procedure.subject.id,
              filter_level: procedure.level.id,
              apiFunc: 'getGroupIndexRegions',
            },
          });
        }

        if (districts.length > 0) {
          data.push({
            type: MEMBERS_TYPES.DISTRICT,
            reportId: reportId,
            procedureID: `${procedure.id}${procedure.year.name}${procedure.subject.id}${procedure.level.id}`,
            report: report.reports[reportIndex],
            reportTypeId: reportTypeId,
            procedure: procedure,
            params: {
              ids: districts,
              report: reportTypeId,
              filter_proc: procedure.id,
              filter_year: procedure.year.name,
              filter_subject: procedure.subject.id,
              filter_level: procedure.level.id,
              apiFunc: 'getGroupIndexDistricts',
            },
          });
        }

        if (schools.length > 0) {
          data.push({
            type: MEMBERS_TYPES.SCHOOL,
            reportId: reportId,
            procedureID: `${procedure.id}${procedure.year.name}${procedure.subject.id}${procedure.level.id}`,
            report: report.reports[reportIndex],
            reportTypeId: reportTypeId,
            procedure: procedure,
            params: {
              ids: schools,
              report: reportTypeId,
              filter_proc: procedure.id,
              filter_year: procedure.year.name,
              filter_subject: procedure.subject.id,
              filter_level: procedure.level.id,
              apiFunc: 'getGroupIndexSchools',
            },
          });
        }
        return data;
      }

      data.push(regions.map(id => ({
        type: MEMBERS_TYPES.REGION,
        reportId: reportId,
        procedureID: `${procedure.id}${procedure.year.name}${procedure.subject.id}${procedure.level.id}`,
        reportTypeId: reportTypeId,
        procedure: procedure,
        params: {
          ids: id,
          report: reportTypeId,
          filter_procedure: procedure.id,
          filter_year: procedure.year.name,
          filter_subj: procedure.subject.id,
          filter_level: procedure.level.id,
        },
      })));

      data.push(districts.map(id => ({
        type: MEMBERS_TYPES.DISTRICT,
        reportId: reportId,
        procedureID: `${procedure.id}${procedure.year.name}${procedure.subject.id}${procedure.level.id}`,
        report: report.reports[reportIndex],
        reportTypeId: reportTypeId,
        procedure: procedure,
        params: {
          ids: id,
          report: reportTypeId,
          filter_procedure: procedure.id,
          filter_year: procedure.year.name,
          filter_subj: procedure.subject.id,
          filter_level: procedure.level.id,
        },
      })));

      data.push(schools.map(id => ({
        type: MEMBERS_TYPES.SCHOOL,
        reportId: reportId,
        reportTypeId: reportTypeId,
        procedureID: `${procedure.id}${procedure.year.name}${procedure.subject.id}${procedure.level.id}`,
        report: report.reports[reportIndex],
        procedure: procedure,
        params: {
          ids: id,
          report: reportTypeId,
          filter_procedure: procedure.id,
          filter_year: procedure.year.name,
          filter_subj: procedure.subject.id,
          filter_level: procedure.level.id,
        },
      })));

      return data;
    });
  });

  var merged = [...requests, ...noProcRequests, ...neobRequests, ...individualResultsRequests];
  return flatten(flatten(flatten(merged)));
};

const getParamsAll = ({ report }) => {
  const procedures = report.selectedProcedures;
  const reports = report.reports.filter(item => item.checked)
    .map((item, index) => ({
      reportId: item.id,
      reportTypeId: item.report_type,
      index,
    }));
  var noProcRequests = reports
    .filter(report => NO_PROC.includes(report.reportTypeId))
    .map(report => ({
      reportTypeId: report.reportTypeId,
      params: {
        report: report.reportTypeId,
      },
    }));
  var neob = reports.find(report => NEOB.includes(report.reportTypeId));
  var neobRequest = [];
  if (neob) {
    neobRequest = [{
      reportTypeId: neob.reportTypeId,
      params: {
        report: neob.reportTypeId,
      },
    }];
  }
  const individualResultRequest = reports
    .filter(report => report.reportTypeId === INDIVIDUAL_RESULTS)
    .map(report => ({
      reportTypeId: report.reportTypeId,
      params: {
        report: report.reportTypeId,
      },
    }));
  const requests = procedures.map(procedure => {
    return reports.map(({ reportTypeId, reportId }) => {
      return {
        procedureId: `${procedure.id}${procedure.year.name}${procedure.subject.id}${procedure.level.id}${reportId}`,
        reportTypeId: reportTypeId,
        params: {
          report: reportTypeId,
          filter_procedure: procedure.id,
          filter_year: procedure.year.name,
          filter_subj: procedure.subject.id,
          filter_level: procedure.level.id,
        },
      };
    });
  });

  return flatten(flatten(flatten([...noProcRequests, ...neobRequest, ...requests, ...individualResultRequest])));
};

export function* getData(api) {
  try {
    const [
      reports,
      procedures,
      regions,
      schoolGroups,
      neobProcedures,
      evaluations,
    ] = yield all([
      call(api.getReports),
      call(api.getProcedure),
      call(api.getRegions),
      call(api.getSchoolGroups),
      call(api.getProceduresReport),
      call(api.getEvaluations),
    ]);

    const procedureData = procedures.results.map((item, key) => ({
      ...item,
      selected: false,
      checked: false,
      disabled: false,
      hasSelectedChildren: false,
    }));

    const data = {
      regions: normalizeRegions(regions.results),
      reports: normalizeReports(reports.results),
      procedures: procedureData,
      schoolGroups: schoolGroups.results,
      neobProcedures: neobProcedures.results,
      evaluations: evaluations.results,
    };

    yield put(ReportActions.getInitialDataSuccess(data));
  } catch (error) {
    yield put(ReportActions.getInitialDataError());
  }
}

export function* getDistricts(api, { id }) {
  try {
    const districts = yield call(api.getDistricts, id);
    const data = normalizeDistricts(districts.results);
    yield put(ReportActions.getDistrictsSuccess(data));
  } catch (error) {
    yield put(ReportActions.getDistrictsError(error));
  }
}

export function* getSchools(api, { id }) {
  try {
    const schools = yield call(api.getSchools, id);
    const data = normalizeSchools(schools.results);
    yield put(ReportActions.getSchoolsSuccess(data));
  } catch (error) {
    yield put(ReportActions.getSchoolsError(error));
  }
}

export function* getReportData(api) {
  try {
    const params = yield select(getParams);
    const paramsAll = yield select(getParamsAll);
    const checkedReports = yield select(getCheckedReports);
    const selectedProcedures = yield select(getSelectedProcedures);
    const checkedMembersNames = yield select(getCheckedMembersNames);
    
    const response = yield all(params.map(({ type, params }) => {
      if (params.report === 7) {
        const customParams = {
          filter_procedure: params.filter_procedure,
          filter_year: params.filter_year,
          filter_subj: params.filter_subj,
          filter_level: params.filter_level,
          limit: 2000,
        };

        switch (type) {
          case MEMBERS_TYPES.REGION: {
            customParams.filter_region = params.ids;
            return call(api.getCustomReportList, customParams);
          }
          case MEMBERS_TYPES.DISTRICT: {
            customParams.filter_district = params.ids;
            return call(api.getCustomReportList, customParams);
          }
          case MEMBERS_TYPES.SCHOOL: {
            customParams.ids = params.ids;
            return call(api.getCustomReportList, customParams);
          }
          default:
            return null;
        }
      }

      if (NO_PROC.includes(params.report)) {
        var customParams = params.ids;
        return call(api[params.apiFunc], customParams);
      }

      if (params.report == REGIONS_INDICATORS) {
        return params.ids.map(region => call(api[params.apiFunc], { region }));
      }

      if (NEOB.includes(params.report)) {
        return call(api[params.apiFunc], { ids: params.ids, sample: params.sample });
      }

      if (params.report == GROUP_INDEX) {
        var { filter_proc, filter_year, filter_subject, filter_level, ids } = params;
        var p = {
          filter_proc, filter_year, filter_subject, filter_level, ids,
        };
        return call(api[params.apiFunc], p);
      }

      if (params.report === INDIVIDUAL_RESULTS) {
        var { sample__exam_subj, class_number, sample__year } = params;
        const customParams = Object.assign({},
          { class_number },
          { sample__exam_subj },
          { sample__year },
          { school: params.school}
        );
        return call(api[params.apiFunc], customParams);
      }

      switch (type) {
        case MEMBERS_TYPES.REGION: {
          return call(api.getRegionsCustomList, params);
        }
        case MEMBERS_TYPES.DISTRICT: {
          return call(api.getDistrictsCustomList, params);
        }
        case MEMBERS_TYPES.SCHOOL: {
          return call(api.getSchoolsCustomList, params);
        }
        default:
          return null;
      }
    }));

    const responseParamsAll = yield all(paramsAll.reduce((result, { procedureId, params }) => {
      if (![...NO_PROC, ...NEOB, REGIONS_INDICATORS, GROUP_INDEX, INDIVIDUAL_RESULTS].includes(params.report)) {
        result[procedureId] = call(api.getParamsAllCustomList, params);
      }
      return result;
    }, {}));

    params.forEach((item, key) => {
      if (item.reportTypeId === 7 || [...NO_PROC, ...NEOB, GROUP_INDEX, INDIVIDUAL_RESULTS].includes(item.reportTypeId)) {
        item.data = {
          items: response[key].results,
        };
      } else if (item.reportTypeId == REGIONS_INDICATORS) {
        var formattedData = response[key].reduce((acc, res) => ({
          count: acc.count + 1,
          results: [
            ...acc.results,
            res.results,
          ],
        }), { count: 0, results: [] });
        item.data = {
          items: formattedData.results,
        };
      } else {
        item.data = response[key].results[0];
      }
    });

    const groupByReport = groupBy(params, 'reportId');
;
    var groupIndexResp;
    var groupIndexId = yield select(selectGroupIndexId);
    if (groupByReport[groupIndexId]) {
      var uniqProc = uniqBy(groupByReport[groupIndexId], 'procedureID');
      groupIndexResp = yield all(uniqProc.map(r => {
        const { params } = r;
        const { filter_proc, filter_year, filter_subject, filter_level } = params;
        const p = {
          filter_proc, filter_year, filter_subject, filter_level,
        };
        return call(api['getGroupIndexAll'], p);
      }));
      groupIndexResp = uniqProc.map((x, i) => ({
        ...groupIndexResp[i].results[0],
        procedure: x.procedure,
      }));
    }

    var neobIndexResp = [];
    var neobIndex = yield select(selectNeobIndexId);

    if (neobIndex && groupByReport[neobIndex.id]) {
      var neobIndexId = neobIndex.id;
      const samples = groupByReport[neobIndexId][0].params.sample;
      neobIndexResp = yield all(samples.map(x => call(api['getBiasDataAll'], { sample: x })));

      neobIndexResp = neobIndexResp.reduce((acc, item) => ({
        ...acc,
        all_school_count: item.results[0].all_school_count,
        school_count: item.results[0].school_count,
        samples: [
          ...acc.samples,
          ...item.results[0].samples,
        ],
      }), { id: 0, name: 'Вся выборка', samples: [] });
    }

    const reportData = Object.keys(groupByReport).map(reportKey => {
      if (NEOB.includes(groupByReport[reportKey][0].reportTypeId)) {
        var ateResults;
        // TODO разделения на школы и АТЕ
        var filteredAte = groupByReport[reportKey]
          .filter(x => ['DISTRICT', 'REGION'].includes(x.type));
        var ate = filteredAte
          .map(({ data: { items }}) => items);
        ateResults = flatten(ate);

        var schoolResults = [];
        var schools = groupByReport[reportKey]
          .find(x => x.type == "SCHOOL");
        if (schools) {
          schoolResults = schools.data.items;
        }

        return {
          report: checkedReports[reportKey],
          membersNames: checkedMembersNames,
          results: {
            schools: schoolResults,
            ate: [
              neobIndexResp,
              ...ateResults,
            ],
            procedures: groupByReport[reportKey][0].params.sample,
          },
        };
      }
      if (groupByReport[reportKey][0].reportTypeId == GROUP_INDEX) {
        var groupIndexResults = groupByReport[reportKey].map(r => r.data.items.map(x => ({...x, procedure: r.procedure})));
        groupIndexResults = [...groupIndexResp, ...groupIndexResults];
        var groupIndex = groupBy(flatten(groupIndexResults), 'id');

        return {
          report: checkedReports[reportKey],
          membersNames: checkedMembersNames,
          results:  Object.keys(groupIndex).map((key) => ({
              id: key,
              name: key != 0 ? groupIndex[key][0].obj.name : 'Вся выборка',
              proc: groupIndex[key].map((r, i) => ({
                mid: r.mid,
                min: r.min,
                top: r.top,
                total_count: r.total_count,
                procedureName: [r.procedure.name, r.procedure.year.name, r.procedure.level.name, r.procedure.subject.name].join(' '),
                procedureId: `proc${i}`,
              })),
            })),
        };

      }

      if (groupByReport[reportKey][0].reportTypeId == INDIVIDUAL_RESULTS) {
        return {
          report: checkedReports[reportKey],
          results: groupByReport[reportKey].map(res => ({
            data: res.data.items,
            params: {
              exam: res.params.sample__exam_subj,
              year: res.params.sample__year,
              class: res.params.class_number,
              school: res.params.school,
            },
          })),
        };
      }

      if (groupByReport[reportKey][0].hasOwnProperty('procedureID')) {
        const procedures = groupBy(groupByReport[reportKey], 'procedureID');
        return {
          report: checkedReports[reportKey],
          membersNames: checkedMembersNames,
          procedures: Object.keys(procedures).map(procedureKey => {
            const paramsAll = responseParamsAll[`${procedureKey}${checkedReports[reportKey].id}`].results[0];

            return {
              procedure: selectedProcedures[procedureKey],
              reportTypeId: checkedReports[reportKey].report_type,
              report: checkedReports[reportKey],
              paramsAll: {
                ...paramsAll,
                attribs: uniqBy(paramsAll.attribs, 'name'),
                columns: uniqBy(paramsAll.columns, 'name'),
              },
              members: procedures[procedureKey].map(item => {
                const hasBal = find(item.data.attribs, item => item.name === 'BAL');
                const hasAvgBal = find(item.data.attribs, item => item.name === 'AVGBAL');
                return {
                  ...item.data,
                  checkedMembersNames,
                  report: checkedReports[reportKey],
                  attribs: !hasBal && !hasAvgBal ? uniqBy(item.data.attribs, 'name') : item.data.attribs,
                  columns: uniqBy(item.data.columns, 'name'),
                  paramsAll: {
                    ...paramsAll,
                    attribs: !hasBal && !hasAvgBal ? uniqBy(paramsAll.attribs, 'name') : paramsAll.attribs,
                    columns: !hasBal && !hasAvgBal ? uniqBy(paramsAll.columns, 'name') : paramsAll.columns,
                  },
                };
              }),
            };
          }),
        };
      }
      var noProcData = params
        .filter(param => param.reportId == reportKey)
        .map(param => param.data.items);
      return {
        report: checkedReports[reportKey],
        membersNames: checkedMembersNames,
        results: flatten(noProcData),
      };
    });

    yield put(ReportActions.getReportsDataSuccess(reportData));
  } catch (error) {
    console.log(error)
    yield put(ReportActions.getReportsDataError(error));
  }
}
