/* eslint-disable */ 
// TODO fix sorting
import React, { Component } from 'react';
import { Spin, Layout } from 'antd';
import ReactTable from "react-table";
import progressTypes from 'constants/progressTypes';
import { tableText as tableDefaultTexts} from 'constants/tableSettings';
import DirectoryHeader from 'components/DirectoryHeader';
import getXlsx from 'utils/xlsx';
import 'react-table/react-table.css';

var { Content } = Layout;

var HEADERS = [{
    Header: 'Тип',
    accessor: 'procedure',
  }, {
    Header: 'Предмет',
    accessor: 'exam_subj',
  }, {
    Header: 'Класс',
    accessor: 'level',
  }, {
    Header: 'Год проведения',
    accessor: 'year',
  }, {
    Header: 'Ссылку на спецификацию',
    accessor: 'link_specific',
  }, {
    Header: 'Ссылку на демоверсию',
    accessor: 'link_demo',
  }, {
    Header: 'Количество участников',
    accessor: 'count_school',
    className: 'rt-td_right',
  }, {
    Header: 'Количество регионов-участников',
    accessor: 'count_reg',
    className: 'rt-td_right',
  },
];

class DirectoryProcResults extends Component {
  downloadTable= (results) => {
    var { options, subjects } = this.props;
    var title = "Оценочные процедуры";
    var parsedData = results.map(result => ({
      procedure: options.find(option => option.id == result.procedure).name,
      exam_subj: subjects.find(subject => subject.id == result.exam_subj).name,
      year: result.year,
      link_specific: result.link_specific,
      link_demo: result.link_demo,
      count_school: result.count_school,
      count_reg: result.count_reg,
    }));
    getXlsx(title, HEADERS, parsedData);
  }

  renderError() {
    return (
      <Layout>
        <Content className={'main'}>
          <div className={'content'}>
            <div>Ошибка: данные не были получены</div>
          </div>
        </Content>
      </Layout>
    );
  }

  renderResults(results) {
    var { options, subjects } = this.props;
    var columns = [{
      Header: 'Тип',
      accessor: 'procedure',
    }, {
      Header: 'Предмет',
      accessor: 'exam_subj',
    }, {
      Header: 'Класс',
      accessor: 'level',
    }, {
      Header: 'Год проведения',
      accessor: 'year',
    }, {
      Header: 'Ссылка на спецификацию',
      accessor: 'link_specific',
      Cell: row => {
        var { value } = row;
        if (!value) {
          return '-';
        }
        return <a href={value} download>Спецификация</a>
      }
    }, {
      Header: 'Ссылка на демоверсию',
      accessor: 'link_demo',
      Cell: row => {
        var { value } = row;
        if (!value) {
          return '-';
        }
        return <a href={value} download>Демоверсия</a>
      }
    }, {
      Header: 'Количество участников',
      accessor: 'count_people',
      className: 'rt-td_right',
    }, {
      Header: 'Количество ОО',
      accessor: 'count_school',
      className: 'rt-td_right',
    }, {
      Header: 'Количество регионов-участников',
      accessor: 'count_reg',
      className: 'rt-td_right',
    }];

    var parsedResults = results.map(result => ({
      procedure: options.find(option => option.id == result.procedure).name,
      exam_subj: subjects.find(subject => subject.id == result.exam_subj).name,
      level: result.level,
      year: result.year,
      link_specific: result.link_specific,
      link_demo: result.link_demo,
      count_people: parseInt(result.count_people),
      count_school: parseInt(result.count_school),
      count_reg: parseInt(result.count_reg),
    }))

    return (
      <ReactTable
        data={parsedResults}
        columns={columns}
        minRows={0}
        {...tableDefaultTexts}
      />
    );
  }

  renderData() {
    var { results: { count, results } } = this.props;
    var headerText = '';
    if (count == 0) {
      headerText = 'Процедур не найдено';
    }
    
    return (
      <div className="directory__container">
        <DirectoryHeader
          text={headerText}
          isShown={false}
          isDownload={count > 0}
          onDownload={() => this.downloadTable(results)}
          onShowModal={() => {}}
        />
        {this.renderResults(results)}
      </div>
    );
  }

  renderLoader() {
    return (
      <Spin className="loading" size='large' />
    );
  }

  render() {
    var { progress } = this.props;

    switch (progress) {
      case progressTypes.IDLE: {
        return this.renderLoader();
      }
      case progressTypes.WORK: {
        return this.renderLoader();
      }
      case progressTypes.SUCCESS: {
        return this.renderData();
      }
      case progressTypes.ERROR: {
        return this.renderError();
      }
      default: {
        return null;
      }
    }
  }
}

export default DirectoryProcResults;