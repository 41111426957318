import { call, put, select } from 'redux-saga/effects';
import SigninActions from 'redux/signin';
import { checkUserAuthorization } from 'saga/app';

const getParams = ({ signin: { username, password } }) => ({
  username,
  password,
});

export function* signin(api) {
  try {
    const params = yield select(getParams);
    yield call(api.signin, params);
    yield put(SigninActions.sendSigninSuccess());
    yield call(checkUserAuthorization, api);
  } catch (error) {
    yield put(SigninActions.sendSigninError(error));
  }
}

export function* oauthLogin(api) {
  try {
    const { link } = yield call(api.loginOauth);
    yield put(SigninActions.getOauthSuccess(link));
  } catch (error) {
    yield put(SigninActions.getOauthError(error));
  }
}