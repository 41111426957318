export const normalizeReports = (array) => {
  return array.map(item => {
    return {
      id: item.id,
      name: item.name,
      description: item.description,
      checked: false,
      report_type: item.report_type,
    };
  });
};

export default normalizeReports;
