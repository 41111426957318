export const tableText = {
  previousText: 'Предыдущая',
  nextText: 'Следующая',
  loadingText: 'Загрузка...',
  noDataText: 'Данных не найдено',
  pageText: 'Страница',
  ofText: 'из',
  rowsText: 'строк',
  
  // Accessibility Labels
  pageJumpText: 'Перейти на страницу',
  rowsSelectorText: 'строк на странице',
};