import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, CartesianGrid, BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import find from 'lodash/find';
import './ReportFive.css';

class ReportFive extends Component {
  static propTypes = {
    data: PropTypes.array,
  }

  renderTooltip ({ payload }) {
    if (payload.length) {
      const { value } = payload[0].payload;
      return (
        <div className="report-five__tooltip">
          <p className="report-five__tooltip-item">
            {`Процент участников: ${parseFloat(value).toFixed(2)}%`}
          </p>
        </div>
      );
    }
  }

  render() {
    const { data } = this.props;

    return (
      <div>
        {data.map((item, index) => {
          const isBlack = find(item.attribs, attribs => attribs.name === 'BADSCHOOL');

          const paramsAll = item.paramsAll.attribs.reduce((memo, item) => {
            const [balName, balValue] = item.value.split(';');
            memo[`${balName}`] = parseFloat(balValue);
            return memo;
          }, {});

          const chartData = item.attribs.map((attribsItem, index) => {
              const [balName, balValue] = attribsItem.value.split(';');
              return {
                name: balName,
                value: parseFloat(balValue),
                allValue: paramsAll[balName],
              };
            }).filter((item, index, inputArray) => {
              return inputArray.map(mapObj => mapObj['name']).indexOf(item['name']) === index;
            }).sort((a, b) => {
              return (parseFloat(a.name) > parseFloat(b.name)) ? 1 : ((parseFloat(b.name) > parseFloat(a.name)) ? -1 : 0);
            });

          return (
            <div className="report-five" key={index}>
              <div className={!isBlack ? 'report-five__name' : 'report-five__name report-five__name_bad'}>
                {item.short_name || item.name}
              </div>
              {(!item.paramsAll.attribs.length) && (<div className="report-five__no-data-all">Нет данных по всей выборке</div>)}
              {(item.attribs.length) ? (
                <ResponsiveContainer aspect={1.0 / 0.25} width='100%'>
                  <BarChart
                    data={chartData}
                    margin={{ top: 40, right: 20, left: 20, bottom: 20 }}
                  >
                    <XAxis
                      dataKey="name"
                      label={{ value: 'Первичные баллы', offset: -10, position: 'insideBottomLeft' }}
                    />
                    <YAxis
                      label={{ value: '% участников', angle: -90, position: 'insideBottomLeft', offset: 30 }}
                    />
                    <CartesianGrid strokeDasharray="2 2" vertical={false}/>
                    <Tooltip cursor={false} content={this.renderTooltip}/>
                    <Legend/>
                    <Bar dataKey={'value'} name={item.short_name || item.name} fill="#add8f7" barSize={20}/>
                    {(item.paramsAll.attribs.length) && (
                      <Bar dataKey={'allValue'} name={'Вся выборка'} fill={'#99b4c7'} barSize={20}/>
                    )}
                  </BarChart>
                </ResponsiveContainer>

              ) : (<div className="report-five__no-data">Нет данных</div>)}
            </div>
          );
        })}
      </div>
    );
  }

}

export default ReportFive;
