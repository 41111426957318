import { createActions, createReducer } from 'reduxsauce';
import progressTypes from 'constants/progressTypes';


const { Types, Creators } = createActions({
  getDirectoryData: null,
  getDirectoryDataSuccess: ['regions', 'years'],
  getDirectoryDataError: ['error'],

  getDirectoryDistricts: ['id'],
  getDirectoryDistrictsSuccess: ['districts'],
  getDirectoryDistrictsError: ['error'],

  getDirectorySchools: ['params'],
  getDirectorySchoolsSuccess: ['schools'],
  getDirectorySchoolsError: ['params'],

  addSchoolGroup: ['params'],
  addSchoolGroupSuccess: ['result'],
  addSchoolGroupError: ['error'],

  updateOption: ['name', 'value'],
}, { prefix: 'OO_' });

export default Creators;

export const DirectoryTypes = Types;

export const INITIAL_STATE = {
  regions: [],
  years: [],
  districts: [],
  schools: {},
  progress: progressTypes.IDLE,
  getDistrictsProgress: progressTypes.IDLE,
  getSchoolsProgress: progressTypes.IDLE,
  options: {
    school__region: undefined,
    school__city: undefined,
    naselenie: undefined,
    mestonahozhdenie: undefined,
    transport: undefined,
    // feature: undefined,
    school__nerus: undefined,
    school__small: undefined,
    school__correct: undefined,
    school__internat: undefined,
    school__smena: undefined,
    study_year: undefined,
    cluster: undefined,
    tom: false,
  },
};


export const getDirectoryData = (state) => ({
  ...state,
  regions: [],
  districts: [],
  progress: progressTypes.WORK,
});

export const getDirectoryDataSuccess = (state, { regions, years }) => ({
  ...state,
  regions,
  years,
  progress: progressTypes.SUCCESS,
});

export const getDirectoryDataError = (state) => ({
  ...state,
  regions: [],
  districts: [],
  progress: progressTypes.ERROR,
});


export const getDirectoryDistricts = (state) => ({
  ...state,
  districts: [],
  getDistrictsProgress: progressTypes.WORK,
});

export const getDirectoryDistrictsSuccess = (state, { districts }) => ({
  ...state,
  districts,
  getDistrictsProgress: progressTypes.SUCCESS,
});

export const getDirectoryDistrictsError = (state) => ({
  ...state,
  districts: [],
  getDistrictsProgress: progressTypes.ERROR,
});


export const getDirectorySchools = (state) => ({
  ...state,
  getSchoolsProgress: progressTypes.WORK,
});

export const getDirectorySchoolsSuccess = (state, { schools }) => ({
  ...state,
  getSchoolsProgress: progressTypes.SUCCESS,
  schools,
});

export const getDirectorySchoolsError = (state) => ({
  ...state,
  getSchoolsProgress: progressTypes.ERROR,
});

export const addSchoolGroup = state => state;

export const addSchoolGroupSuccess = (state, { result }) => ({
  ...state,
});

export const updateOption = (state, { name, value }) => ({
  ...state,
  options: {
    ...state.options,
    [name]: value,
  },
});

export const addSchoolGroupError = state => state;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_DIRECTORY_DATA]: getDirectoryData,
  [Types.GET_DIRECTORY_DATA_SUCCESS]: getDirectoryDataSuccess,
  [Types.GET_DIRECTORY_DATA_ERROR]: getDirectoryDataError,

  [Types.GET_DIRECTORY_DISTRICTS]: getDirectoryDistricts,
  [Types.GET_DIRECTORY_DISTRICTS_SUCCESS]: getDirectoryDistrictsSuccess,
  [Types.GET_DIRECTORY_DISTRICTS_ERROR]: getDirectoryDistrictsError,

  [Types.GET_DIRECTORY_SCHOOLS]: getDirectorySchools,
  [Types.GET_DIRECTORY_SCHOOLS_SUCCESS]: getDirectorySchoolsSuccess,
  [Types.GET_DIRECTORY_SCHOOLS_ERROR]: getDirectorySchoolsError,

  [Types.ADD_SCHOOL_GROUP]: addSchoolGroup,
  [Types.ADD_SCHOOL_GROUP_SUCCESS]: addSchoolGroupSuccess,
  [Types.ADD_SCHOOL_GROUP_ERROR]: addSchoolGroupError,

  [Types.UPDATE_OPTION]: updateOption,
});


export const selectRegions = (state) => state.regions;
export const selectYears = state => state.years;
export const selectDistricts = (state) => state.districts;
export const selectSchools = (state) => state.schools;
export const selectProgress = (state) => state.progress;
export const selectDistrictsProgress = (state) => state.getDistrictsProgress;
export const selectGetSchoolsProgress = (state) => state.getSchoolsProgress;
export const selectOptions = state => state.options;