export const normalizeDistricts = (array) => {
  return array.map(item => {
    return {
      id: item.id,
      name: item.name,
      region: item.region,
      selected: false,
      checked: false,
      disabled: false,
      hasSelectedChildren: false,
    };
  });
};

export default normalizeDistricts;
