import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SortItem from 'components/SortItem/SortItem';
import './Sort.css';

class Sort extends Component {
  static propTypes = {
    items: PropTypes.array,
    onClick: PropTypes.func,
  }

  render() {
    const { items } = this.props;

    return (
      <div className="sort">
        {items.map((item, key) => (
          <SortItem
            key={key}
            id={key}
            text={item.text}
            direction={item.direction}
            active={item.active}
            onClick={this.props.onClick}
          />
        ))}
      </div>
    );
  }
}

export default Sort;
