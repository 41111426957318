import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spin, Popover } from 'antd';
import FileSaver from 'file-saver';
import { parsePercents } from 'utils/parseValues'; 
import XLSX from 'xlsx';
import ErrorBoundary from 'helpers/errorBoundary';
import {
  selectGetReportsDataProgress,
  selectReportsData,
  selectNeobProcedures,
  selectEvaluations,
  selectRegions,
  selectDataError,
} from 'redux/report';
import { selectSchoolUser } from 'redux/app';
import ReportTitle from 'components/ReportTitle/ReportTitle';
import ProcedureTitle from 'components/ProcedureTitle/ProcedureTitle';
import ProcedureScatterTitle from 'components/ProcedureScatterTitle/ProcedureScatterTitle';
import ReportTypeList from 'containers/ReportTypeList/ReportTypeList';
import NoProcReport from 'components/NoProcReport';
import progressTypes from 'constants/progressTypes';

import './ReportsList.css';

const mapStateToProps = ({ report, app }) => ({
  getReportsDataProgress: selectGetReportsDataProgress(report),
  reportsData: selectReportsData(report),
  neobProcedures: selectNeobProcedures(report),
  evaluations: selectEvaluations(report),
  regions: selectRegions(report),
  getReportsDataError: selectDataError(report),
  schoolUser: selectSchoolUser(app),
});

const NEOB_ID = [11, 13];
const GROUP_INDEX = 10;
const INDIVIDUAL_RESULTS = 14;

const mapActionsToProps = {};

// TODO заменить temp_group на значние с бэка
var MEDAL_HEADERS = [{
  Header: 'Группы участников',
  accessor: 'temp_group',
}, {
  Header: 'Медалисты, количество',
  accessor: 'num_gold',
  className: 'rt-td_right',
}, {
  Header: 'Медалисты, доля(%)',
  accessor: 'numGold',
  className: 'rt-td_right',
},{
  Header: 'Индекс неподтверждения медалистов (%)',
  accessor: 'numGoldNotApproved',
  className: 'rt-td_right',
},{
  Header: 'Количество победителей ВсОШ',
  accessor: 'r_olymp',
  className: 'rt-td_right',
}, {
  Header: 'Победители ВсОШ, доля (%)',
  accessor: 'numOlymp',
  className: 'rt-td_right',
}, {
  Header: 'Количество победителей других олимпиад',
  accessor: 'other_olymp',
  className: 'rt-td_right',
}];

var ADMISSION_HEADERS = [{
  Header: 'Группы участников',
  accessor: 'temp_group',
}, {
  Header: 'Поступление в СПО ООО, количество',
  accessor: 'spo_ooo',
  className: 'rt-td_right',
}, {
  Header: 'Поступление в СПО ООО, доля (%)',
  accessor: 'spoOoo',
  className: 'rt-td_right',
}, {
  Header: 'Поступление в СПО СОО, количество',
  accessor: 'post_spo',
  className: 'rt-td_right',
}, {
  Header: 'Поступление в СПО СОО, доля (%)',
  accessor: 'spoSoo',
  className: 'rt-td_right',
}, {
  Header: 'Поступление в ВПО, количество',
  accessor: 'post_vuo',
  className: 'rt-td_right',
}, {
  Header: 'Поступление в ВПО, доля (%)',
  accessor: 'postVuo',
  className: 'rt-td_right',
}];

class ReportsList extends Component {
  static propTypes = {
    getReportsDataProgress: PropTypes.string,
    reportData: PropTypes.array,
  };

  s2ab(s) {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  handleReport = (type, data, report, procedure) => {
    let generalData = [], specificData = [];
    const rows = [
      [report.name],
      [`${procedure.name} ${procedure.year.name} / ${procedure.level.name} класс ${procedure.subject.name}`],
    ];
    const { paramsAll: { columns, attribs } } = data[0];

    columns.sort((a, b) => a.position - b.position);
    switch (type) {
      case 1:
        // const columnOrder = columns.filter(item => item.name !== 'COUNTP' && item.name !== 'BADSCHOOL').map(((item, key) => (
        //   item.label
        // )));

        const columnOrder = columns.filter(x => x.name.includes('_S')).map(x => x.name.slice(0, -2));

        rows.push([
          'Группы участников',
          'Количество участников',
          ...columnOrder,
        ]);
        // данные по всей россии
        
        generalData = ['COUNTP', ...columnOrder].map(cKey => {
          const val = attribs.find(a => a.name == cKey || a.name == `${cKey}_S`);
          return val ? val.value : '-';
        });
        rows.push([
          'Вся выборка',
          ...generalData,
        ]);
        // данные по каждому региону / школе / муниципалитету
        data.forEach(item => {
          const { attribs } = item;
          specificData = ['COUNTP', ...columnOrder].map(column => {
            const res = attribs[attribs.findIndex(attrib => attrib.name == column || attrib.name == `${column}_S`)]
            return res ? res.value : '-';
          });
          rows.push([
            item.name,
            ...specificData,
          ]);
        });
        break;
      case 2:
        const { tasks, paramsAll: { attribs: entireRussiaNumbers } } = data[0];
        // три столбца значений номера, текста требования и максимального балла
        const firstThreeColumns = tasks.map(({ num, task, max_point: maxPoint }) => [num, task, maxPoint]);
        const transpose = (a) => Object.keys(a[0])
          .map(c => a.map(r => r[c]));

        rows.push([
          '№',
          'Требования',
          'Макс. балл',
          ...data.map(item => `Средний % выполнения по ${item.name}`),
          'Средний % выполнения по всей России',
        ]);

        // хэдер с общими значениями для каждого из выбранных элементов
        rows.push([
          '',
          '',
          '',
          ...data.map(item => item.attribs.find(item => item.name === "COUNTP")).map(item => item ? item.value : '-'),
          data[0].paramsAll.attribs.find(item => item.name === "COUNTP")
          ? data[0].paramsAll.attribs.find(item => item.name === "COUNTP").value
          : '-',
        ]);

        specificData = data.map(({ attribs }) =>
          tasks.map(({ column_name: columnName }) => 
            attribs.find(x => columnName === x.name.slice(0, -2)))
          .map(item => item ? item.value : '-'));
        
        generalData = tasks.map(({ column_name: columnName }) => 
          entireRussiaNumbers.find(item => columnName === item.name.slice(0, -2))
        ).map(item => item ? item.value : '-');

        transpose([...specificData, generalData]).map((x, index) => rows.push([...firstThreeColumns[index], ...x]));
        break;
      case 4:
        rows.push([
          'Группы участников',
          'Количество участников',
          'Распределение',
          'групп',
          'баллов',
          'в %',
        ]);
        rows.push([
          '',
          '',
          '2',
          '3',
          '4',
          '5',
        ]);
        generalData = columns.map(column =>
          attribs[attribs.findIndex(attrib => attrib.name === column.name)] ?
          attribs[attribs.findIndex(attrib => attrib.name === column.name)].value :
          '-'
        );

        rows.push([
          'Вся выборка',
          ...generalData,
        ]);
        data.forEach(item => {
          const { attribs } = item;
          const specificData = columns.map(column =>
            attribs[attribs.findIndex(attrib => attrib.name === column.name)]
            ? attribs[attribs.findIndex(attrib => attrib.name === column.name)].value
            : '-'
          );
          rows.push([
            item.name,
            ...specificData,
          ]);
        });
        break;
      case 6:
        const generalResults = columns.reduce((generalResults, column) => {
          if (column.name !== 'COUNTP') {
            generalResults.push({
              columnName: column.name.slice(0, -2),
              value: attribs[attribs.findIndex(attrib => attrib.name === column.name)]
              ? attribs[attribs.findIndex(attrib => attrib.name === column.name)].value
              : '-',
            });
          }
          return generalResults;
        }, []).reverse();
        rows.push([
          'Группы участников',
          'Количество участников',
          ...generalResults.map(({ columnName }) => columnName),
        ]);
        rows.push([
          'Вся выборка',
          data[0].paramsAll.attribs.find(item => item.name === "COUNTP")
          ? data[0].paramsAll.attribs.find(item => item.name === "COUNTP").value
          : '-',
          ...generalResults.map(({ value }) => value),
        ]);
        
        data.forEach(({ attribs: itemAttribs, name }) => {
          const itemData = columns.reduce((itemData, column) => {
            if (column.name !== 'COUNTP') {
              itemData.push({
                columnName: column.name.slice(0, -2),
                value: itemAttribs[itemAttribs.findIndex(attrib => attrib.name === column.name)]
                ? itemAttribs[itemAttribs.findIndex(attrib => attrib.name === column.name)].value
                : '-',
              });
            }
            return itemData;
          }, []).reverse();
          rows.push([
            name,
            itemAttribs.find(item => item.name === "COUNTP")
            ? itemAttribs.find(item => item.name === "COUNTP").value
            : '-',
            ...itemData.map(({ value }) => value),
          ]);

          //группы баллов
          [2,3,4,5].forEach(x => {
            const groupData = columns.reduce((groupData, column) => {
              if (column.name !== 'COUNTP') {
                groupData.push(
                  itemAttribs[itemAttribs.findIndex(attrib => attrib.name === column.name)]
                  ? Math.trunc(itemAttribs[itemAttribs.findIndex(attrib => attrib.name === column.name)][`p${x}`])
                  : '-');
              }

              return groupData;
            }, []).reverse();

            rows.push([
              `Ср.% вып. уч. гр. баллов ${x}`,
              itemAttribs.find(item => item.name === "COUNTP")
              ? itemAttribs.find(item => item.name === "COUNTP")[`p${x}`]
              : '-',
              ...groupData,
            ]);
          });
        });
        break;        
      default:
        return;
    }

    const ws = XLSX.utils.aoa_to_sheet(rows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Report");
    const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
    FileSaver.saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), "report.xlsx");
  }

  renderProcedures(procedures) {
    return (
      <div>
        {procedures.map((item, index) => {

          if (item.reportTypeId === 7 && index === 1) {
            return (
              <div key={index} className="reports-list__item_small">
                <ProcedureScatterTitle
                  procedures={procedures}
                  report={item.report}
                />
                <ReportTypeList
                  type={item.reportTypeId}
                  data={item.members}
                  procedures={procedures}
                />
              </div>
            );
          } else if (item.reportTypeId !== 7) {
            return (
              <div key={index} className="reports-list__item_small">
                <ProcedureTitle
                  procedure={item.procedure}
                  type={item.reportTypeId}
                  data={item.members}
                  report={item.report}
                  onBtnClick={this.handleReport}
                />
                <ReportTypeList
                  type={item.reportTypeId}
                  data={item.members}
                  procedures={procedures}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  }

  renderNeobAte(res) {
    var { ate: results, procedures } = res;
    if (results.length == 0) {
      return null;
    }

    var { neobProcedures } = this.props;

    var tableProc = neobProcedures
      .filter(proc => procedures.includes(proc.id))
      .map(proc => ({ name: proc.name, id: proc.id }));

    var NEOB_ATE_HEADERS = [{
      Header: 'Группы участников',
      accessor: 'name',
    },
    {
      Header: 'Всего ОО с признаками необъективности',
      accessor: 'school_count',
      className: 'rt-td_right',
    }, {
      Header: 'Доля ОО с признаками необъективности (%)',
      accessor: 'school_count_part',
      className: 'rt-td_right',
    },
    ...tableProc.map((x, i) => ({
      Header: `${x.name}, доля участников (%)`,
      accessor: `partmembers_${x.id}`,
      className: 'rt-td_right',
    })),
    ...tableProc.map((x, i) => ({
      Header: `${x.name}, количество ОО`,
      accessor: `totaloo_${x.id}`,
      className: 'rt-td_right',
    })),
    ...tableProc.map((x, i) => ({
      Header: `${x.name}, доля ОО (%)`,
      accessor: `partoo_${x.id}`,
      className: 'rt-td_right',
    }))];

    var updatedResults = results.map(result => {
      var partMembers = tableProc.reduce((acc, proc, i) => {
        var sample = result.samples.find(s => s.id == proc.id)
        return {
          ...acc,
          [`partmembers_${proc.id}`]: parsePercents(sample.neef_people, sample.total_people),
          [`partoo_${proc.id}`]: parsePercents(sample.neef_oo, sample.total_oo),
          [`totaloo_${proc.id}`]: sample.total_oo,
        };
      }, {});
      return {
        name: result.name,
        school_count: result.school_count,
        school_count_part: parsePercents(result.school_count, result.all_school_count, 2),
        ...partMembers,
      };
    });

    return (
      <NoProcReport
        results={updatedResults}
        headers={NEOB_ATE_HEADERS}
        title="Индекс необъективности АТЕ"
        fileName="Индекс необъективности АТЕ"
      />
    );
  }

  renderNeobSchools(res) {
    var { neobProcedures } = this.props;
    var { schools: results, procedures } = res;

    if (results.length == 0) {
      return null;
    }

    var tableProc = neobProcedures
      .filter(proc => procedures.includes(proc.id))
      .map(proc => ({ name: proc.name, id: proc.id }));

    var formattedResults = {};
    results.forEach((result, i) => {
      var { school: { id: schoolId } } = result;
      if (!formattedResults[schoolId]) {
        formattedResults[schoolId] = {};
        formattedResults[schoolId].sum = [];
        formattedResults[schoolId].name = result.school.name;
      }

      formattedResults[schoolId].sum.push({ alg: result.alg, proc: result.sample.name, id: result.sample.id });

    });

    var NEOB_SCHOOL_HEADERS = [{
      Header: 'Группы участников',
      accessor: 'name',
    },
    ...tableProc.map(x => ({
      Header: x.name,
      id: `alg_${x.id}`,
      accessor: d => ({ [`alg_${x.id}`]: d[`alg_${x.id}`], [`alg-name_${x.id}`]: d[`alg-name_${x.id}`] }),
      Cell: row => {
        var { value: { [`alg_${x.id}`]: algCode,  [`alg-name_${x.id}`]: algName } } = row;
        return (
        algName ? (
          <span>
            <Popover
              placement="left"
              content={algName}
            >
              <div style={{ cursor: 'pointer' }}>
                {algCode} (показать)
              </div>
            </Popover>
          </span>
        ) : algCode);
      },
      sortMethod: ({ [`alg_${x.id}`]: algCode1Value }, { [`alg_${x.id}`]: algCode2Value }) => {
        var algCode1 = algCode1Value === '-' ? 0 : algCode1Value;
        var algCode2 = algCode2Value === '-' ? 0 : algCode2Value;
        if (algCode1 > algCode2) {
          return 1;
        }
        if (algCode1 < algCode2) {
          return -1;
        }
        return 0;
      },
    })),
    {
      Header: 'Всего маркеров',
      accessor: 'total',
      className: 'rt-td_right',
    }];

    var updatedResults = Object.keys(formattedResults).map(key => {
      var total = 0;
      var partMembers = tableProc.reduce((acc, proc, i) => {
        var sample = formattedResults[key].sum.find(s => s.id == proc.id);
        var alg;
        var algName = '';
        var algCode = '-';
        if (sample && sample.alg) {
          alg = sample.alg;
          algName = alg.name;
          algCode = alg.out_pk;
          total += 1;
        } else {
          alg = '-';
        }
        return {
          ...acc,
          [`alg_${proc.id}`]: algCode,
          [`alg-name_${proc.id}`]: algName,
        };
      }, {});
      return {
        name: formattedResults[key].name,
        total,
        ...partMembers,
      };
    });

    return (
      <NoProcReport
        results={updatedResults}
        headers={NEOB_SCHOOL_HEADERS}
        title="Показатели необъективности ОО"
        fileName="Показатели необъективности ОО"
      />
    );
  }

  renderGroupIndexes(item) {
    var { results } = item;

    if (results.length == 0) {
      return null;
    }
    var foundProcedures = results[0].proc;

    var GROUP_INDEX_HEADERS = [{
      Header: 'Группа участников',
      accessor: 'name',
    }, ...foundProcedures.map(x => ({
      Header: x.procedureName,
      accessor: `${x.procedureId}_val`,
      className: 'rt-td_right',
    }))];

    var lowResults = results.map(res => {
      var procIndex = res.proc.reduce((acc, proc) => ({
        ...acc,
        [`${proc.procedureId}_val`]: parsePercents(proc.min, proc.total_count),
      }), {});
      return {
        name: res.name,
        ...procIndex,
      };
    });

    var midResults = results.map(res => {
      var procIndex = res.proc.reduce((acc, proc) => ({
        ...acc,
        [`${proc.procedureId}_val`]: parsePercents(proc.mid, proc.total_count),
      }), {});
      return {
        name: res.name,
        ...procIndex,
      };
    });

    var topResults = results.map(res => {
      var procIndex = res.proc.reduce((acc, proc) => ({
        ...acc,
        [`${proc.procedureId}_val`]: parsePercents(proc.top, proc.total_count),
      }), {});
      return {
        name: res.name,
        ...procIndex,
      };
    });

    return (
      <React.Fragment>
        <NoProcReport
          results={lowResults}
          headers={GROUP_INDEX_HEADERS}
          title="Индекс низких результатов"
          fileName="Индекс низких результатов"
        />
        <NoProcReport
          results={midResults}
          headers={GROUP_INDEX_HEADERS}
          title="Индекс массовых результатов"
          fileName="Индекс массовых результатов"
        />
        <NoProcReport
          results={topResults}
          headers={GROUP_INDEX_HEADERS}
          title="Индекс высоких результатов"
          fileName="Индекс высоких результатов"
        />
      </React.Fragment>
    );
  }

  renderResult(item) {
    if (item.hasOwnProperty('procedures')) {
      return this.renderProcedures(item.procedures);
    } else if (NEOB_ID.includes(item.report.report_type)) {
      const { schoolUser } = this.props;
      const isSchoolUser = schoolUser.length > 0;
      const { results } = item;
      return <React.Fragment>
        {!isSchoolUser && this.renderNeobAte(results)}
        {this.renderNeobSchools(results)}
      </React.Fragment>;
    } else if (item.report.report_type == GROUP_INDEX) {
      return this.renderGroupIndexes(item);
    } else if (item.report.report_type == INDIVIDUAL_RESULTS) {
      return this.renderIndividualResults(item);
    } else {
      return this.renderNoProc(item);
    }
  }

  renderIndividualResults(item) {
    if (item.results.length == 0) {
      return null;
    }

    return item.results.map(res => {
      var { params: { class: level, exam, year, school } } = res;
      var key = [level, exam, year, school].join('-');
      var data = [];
      var headers = [];
      var title = `Школа ${school} / Класс ${level} / предмет ${exam} / год ${year}`;
      if (res.data.length !== 0) {
        var { sample, school: schoolRes } = res.data[0];
        const grades = res.data[0].fields.map(grade => ({
          Header: Object.keys(grade)[0],
          accessor: Object.keys(grade)[0],
          className: 'rt-td_right',
        }));

        title = [schoolRes.short_name, sample.name].join(' / ');
        key = [schoolRes.id, sample.id].join('-');
    
        data = res.data.map(r => {
          const fields = r.fields.reduce((acc, grade) => ({
            ...acc,
            ...grade,
          }), {});
          return {
            ...r,
            class_letter: r.class_letter.trim() ? r.class_letter : '-',
            noname: r.noname || '-',
            ...fields,
          };
        });
    
        headers = [
          {
            Header: 'Класс Номер',
            accessor: 'class_number',
            className: 'rt-td_right',
          },
          {
            Header: 'Класс Буква',
            accessor: 'class_letter',
          },
          {
            Header: 'Номер участника',
            accessor: 'noname'
          },
          {
            Header: 'Вариант',
            accessor: 'num_variant',
            className: 'rt-td_right',
          },
          ...grades,
          {
            Header: 'итоговый перв. балл',
            accessor: 'bal',
            className: 'rt-td_right',
          },
          {
            Header: 'отметка',
            accessor: 'mark',
            className: 'rt-td_right',
          },
          {
            Header: 'отметка по журналу',
            accessor: 'curmarks',
            className: 'rt-td_right',
          },
        ];

        return (
          <NoProcReport
            key={key}
            results={data}
            headers={headers}
            title={title}
            fileName={`Индивидуальные результаты_${title}`}
          />
        );
      }
      
      return (
        <h1 key={key}>{`Нет данных для школы с id=${school}, класс id=${level}, предмет id=${exam} и ${year}`}</h1>
      );
    });
  }

  renderNoProc(item) {
    var { report: { report_type: reportType, name: fileName }, results, membersNames } = item;
    var headers = [];
    var formattedResults, resultsAll = [];
    switch (reportType) {
      case 8:
        headers = MEDAL_HEADERS;
        resultsAll = results.find(r => r.id == 0);
        resultsAll.temp_group = resultsAll.name;
        resultsAll.numGold = parsePercents(resultsAll.num_gold, resultsAll.total);
        resultsAll.numGoldNotApproved = parsePercents(resultsAll.num_gold_not_approved, resultsAll.num_gold);
        resultsAll.numOlymp = parsePercents(resultsAll.r_olymp, resultsAll.total);

        formattedResults = results
          .filter(r => r.id !== 0)
          .map((r, i) => ({
            ...r,
            numGold: parsePercents(r.num_gold, r.total),
            temp_group: r.name || membersNames[i],
            numGoldNotApproved: parsePercents(r.num_gold_not_approved, r.num_gold),
            numOlymp: parsePercents(r.r_olymp, r.total),
          }));
        formattedResults = [resultsAll, ...formattedResults];
        break;
      case 9:
        headers = ADMISSION_HEADERS;
        resultsAll = results.find(r => r.id == 0);
        resultsAll.temp_group = resultsAll.name;
        resultsAll.spoOoo = parsePercents(resultsAll.spo_ooo, resultsAll.total);
        resultsAll.spoSoo =  parsePercents(resultsAll.post_spo, resultsAll.total);
        resultsAll.postVuo = parsePercents(resultsAll.post_vuo, resultsAll.total);

        formattedResults = results
          .filter(r => r.id !== 0)
          .map((r, i) => ({
            ...r,
            temp_group: r.name || membersNames[i],
            spoOoo: parsePercents(r.spo_ooo, r.total),
            spoSoo: parsePercents(r.post_spo, r.total),
            postVuo: parsePercents(r.post_vuo, r.total),
          }));
        formattedResults = [resultsAll, ...formattedResults];
        break;
      case 12:
        var { evaluations } = this.props;
        if (!evaluations) {
          return null;
        }
        headers = [
          {
            Header: 'Регион',
            accessor: 'reg',
          },
          ...evaluations.map(ev => ({
            Header: ev.name,
            accessor: `ind${ev.id}`,
            className: 'rt-td_right',
        })), {
          Header: 'Сумма',
          accessor: 'total',
          className: 'rt-td_right',
        }];
        formattedResults = results.reduce((acc, result) => {
          if (result.length == 0) {
            return acc;
          } else {

            var total = 0;
            var ind = result.reduce((acc, r) => {
              total += r.value;
              return {
                ...acc,
                [`ind${r.sys}`]: r.value,
              };
            }, {});

            var s = {
                reg: result[0].region.name,
                total,
                ...ind,
              };
            }
            return [
              ...acc,
              s,
            ];
          }, []);
        break;
      default:
        return null;
    }

    return <NoProcReport
      results={formattedResults}
      headers={headers}
      title={fileName}
      fileName={fileName}
    />;
  }

  renderReport() {
    const { reportsData } = this.props;
    if (!reportsData) {
      throw new Error('No data has been received to generate the reports');
    }

    return (
      <div className="reports-list__inner">
        {reportsData.map((item) => {
          return (
            <div className="reports-list__item" key={item.report.id}>
              <ReportTitle report={item.report} membersNames={item.membersNames}/>
              {this.renderResult(item)}
              {/* {this.renderProcedures(item.procedures)} */}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { getReportsDataProgress } = this.props;
    if (progressTypes.ERROR === getReportsDataProgress) {
      var { getReportsDataError } = this.props;
      throw new Error(getReportsDataError);
    }
    return (
      <ErrorBoundary>
        <div className="reports-list">
          {(progressTypes.IDLE === getReportsDataProgress) && (
            <div/>
          )}
          {(progressTypes.WORK === getReportsDataProgress) && (
            <div className="loading">
              <Spin className="loading" size="large"/>
            </div>
          )}
          {(progressTypes.SUCCESS === getReportsDataProgress) && (
            this.renderReport()
          )}
        </div>
      </ErrorBoundary>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportsList);
