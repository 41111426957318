import { createActions, createReducer } from 'reduxsauce';
import progressTypes from 'constants/progressTypes';

const { Types, Creators } = createActions(
  {
    getDirectoryData: null,
    getDirectoryDataSuccess: ['regions'],
    getDirectoryDataError: ['error'],

    getDirectoryDistricts: ['id'],
    getDirectoryDistrictsSuccess: ['districts'],
    getDirectoryDistrictsError: ['error'],

    getDirectoryResults: ['params'],
    getDirectoryResultsSuccess: ['results'],
    getDirectoryResultsError: ['error'],

    updateOption: ['name', 'value'],
  },
  { prefix: 'ATE_' }
);

export default Creators;

export const DirectoryAteTypes = Types;

export const INITIAL_STATE = {
  regions: [],
  districts: [],
  schools: [],
  resultsProgress: progressTypes.IDLE,
  progress: progressTypes.IDLE,
  options: {
    region: [],
    districts: [],
    filter_year: [],
  },
};

export const getDirectoryData = (state) => ({
  ...state,
  regions: [],
  districts: [],
  progress: progressTypes.WORK,
});

export const getDirectoryDataSuccess = (state, { regions }) => ({
  ...state,
  regions,
  progress: progressTypes.SUCCESS,
});

export const getDirectoryDataError = (state) => ({
  ...state,
  regions: [],
  districts: [],
  progress: progressTypes.ERROR,
});


export const getDirectoryDistricts = (state) => ({
  ...state,
  districts: [],
  progress: progressTypes.WORK,
});

export const getDirectoryDistrictsSuccess = (state, { districts }) => ({
  ...state,
  districts,
  progress: progressTypes.SUCCESS,
});

export const getDirectoryDistrictsError = (state) => ({
  ...state,
  districts: [],
});

export const getDirectoryResults = (state) => ({
  ...state,
  resultsProgress: progressTypes.WORK,
});

export const getDirectoryResultsSuccess = (state, { results }) => ({
  ...state,
  results,
  resultsProgress: progressTypes.SUCCESS,
});

export const getDirectoryResultsError = (state, { results }) => ({
  ...state,
  ...results,
  resultsProgress: progressTypes.ERROR,
});

export const updateOption = (state, { name, value }) => ({
  ...state,
  options: {
    ...state.options,
    [name]: value,
  },
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_DIRECTORY_DATA]: getDirectoryData,
  [Types.GET_DIRECTORY_DATA_SUCCESS]: getDirectoryDataSuccess,
  [Types.GET_DIRECTORY_DATA_ERROR]: getDirectoryDataError,

  [Types.GET_DIRECTORY_DISTRICTS]: getDirectoryDistricts,
  [Types.GET_DIRECTORY_DISTRICTS_SUCCESS]: getDirectoryDistrictsSuccess,
  [Types.GET_DIRECTORY_DISTRICTS_ERROR]: getDirectoryDistrictsError,

  [Types.GET_DIRECTORY_RESULTS]: getDirectoryResults,
  [Types.GET_DIRECTORY_RESULTS_SUCCESS]: getDirectoryResultsSuccess,
  [Types.GET_DIRECTORY_RESULTS_ERROR]: getDirectoryResultsError,

  [Types.UPDATE_OPTION]: updateOption,
});


export const selectRegions = (state) => state.regions;
export const selectDistricts = (state) => state.districts;
export const selectSchools = (state) => state.schools;
export const selectProgress = (state) => state.progress;
export const selectResultsProgress = (state) => state.resultsProgress;
export const selectResults = state => state.results;
export const selectOptions = state => state.options;