import { call, put } from 'redux-saga/effects';
import AppActions from 'redux/app';

export function* checkUserAuthorization(api) {
  try {
    const result = yield call(api.getUser);
    yield put(AppActions.checkAuthorizationSuccess(result));
  } catch (error) {
    yield put(AppActions.checkAuthorizationError());
  }
}
