import React, { Component } from 'react';
import DirectoryFilterItem from 'components/DirectoryFilterItem';
import classnames from 'classnames';
import ClickOutside from 'react-click-outside';

var directoryFilterWrapper = props => (WrappedComponent) => {
  class DirectoryFilterWrapper extends Component {
    state = {
      isOpen: false,
    }

    handleToggle = () => {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }

    handleClose = (e) => {
      /**
       * clickoutside doesn't work properly when the select option is active,
       * thus we need to explicitly check whether it has been triggered
       */
      if (e && e.toElement && e.toElement.className && (e.toElement.className.includes('ant-select-dropdown-menu-item') || e.toElement.className.includes('ant-cascader-menu-item'))) {
        return;
      }
      this.setState({ isOpen: false });
    }

    closePopup = () => {
      this.setState({ isOpen: false });
    }

    render() {
      var { name, key } = props;
      var { isOpen } = this.state;
      var className = classnames('directory-filter__popup', {
        'directory-filter__popup_proc': key == 'proc',
      });

      return (
        <ClickOutside onClickOutside={this.handleClose}>
          <DirectoryFilterItem
            title={name}
            isOpen={isOpen}
            onClick={this.handleToggle}
          />
          {
            isOpen && (
              <div className={className}>
                <WrappedComponent {...this.props} onHandleClose={this.closePopup}/>
              </div>
            )
          }
        </ClickOutside>
      );
    }
  }
  return DirectoryFilterWrapper;
};

export default directoryFilterWrapper;
