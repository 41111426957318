import { createActions, createReducer } from 'reduxsauce';
import progressTypes from 'constants/progressTypes';
import { removeItem } from 'utils/reduxHelpers';

const { Types, Creators } = createActions({
  getOptions: null,
  getOptionsSuccess: ['options', 'subjects'],
  getOptionsError: ['error'],
  getResults: ['params'],
  getResultsSuccess: ['results', 'subjects'],
  getResultsError: ['error'],
  addProcedure: ['procedure'],
  removeProcedure: ['procedureId'],
}, { prefix: 'PROC_'});

export default Creators;
export const DirectoryProcTypes = Types;

const INITIAL_STATE = {
  options: [],
  optionsProgress: progressTypes.IDLE,
  optionsError: null,
  selectedProcedures: [],
  results: [],
  subjects: [],
  resultsProgress: progressTypes.IDLE,
  resultsError: null,
};

const getOptions = state => ({
  ...state,
  optionsProgress: progressTypes.WORK,
});

const getOptionsSuccess = (state, { options, subjects }) => ({
  ...state,
  optionsProgress: progressTypes.SUCCESS,
  options,
  subjects,
});

const getOptionsError = (state, { error }) => ({
  ...state,
  optionsProgress: progressTypes.ERROR,
  optionsError: error,
});

const getResults = state => ({
  ...state,
  resultsProgress: progressTypes.WORK,
});

const getResultsSuccess = (state, { results, subjects }) => ({
  ...state,
  resultsProgress: progressTypes.SUCCESS,
  results,
});

const getResultsError = (state, { error }) => ({
  ...state,
  resultsProgress: progressTypes.ERROR,
  resultsError: error,
});

const addProcedure = (state, { procedure }) => ({
  ...state,
  selectedProcedures: [...state.selectedProcedures, procedure],
});

const removeProcedure = (state, { procedureId }) => ({
  ...state,
  selectedProcedures: removeItem(state.selectedProcedures, { index: procedureId }),
});

export const reducer =  createReducer(INITIAL_STATE, {
  [Types.GET_OPTIONS]: getOptions,
  [Types.GET_OPTIONS_SUCCESS]: getOptionsSuccess,
  [Types.GET_OPTIONS_ERROR]: getOptionsError,
  [Types.GET_RESULTS]: getResults,
  [Types.GET_RESULTS_SUCCESS]: getResultsSuccess,
  [Types.GET_RESULTS_ERROR]: getResultsError,
  [Types.ADD_PROCEDURE]: addProcedure,
  [Types.REMOVE_PROCEDURE]: removeProcedure,
});

export const selectOptions = ({ options }) => options;
export const selectOptionsProgress = ({ optionsProgress }) => optionsProgress;
export const selectResults = ({ results }) => results;
export const selectResultsProgress = ({ resultsProgress }) => resultsProgress;
export const selectSubjects = ({ subjects }) => subjects;
export const selectTags = ({ selectedProcedures }) => selectedProcedures;