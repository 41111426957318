import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReportOne from 'components/ReportOne/ReportOne';
import ReportTwo from 'components/ReportTwo/ReportTwo';
import ReportFour from 'components/ReportFour/ReportFour';
import ReportFive from 'components/ReportFive/ReportFive';
import ReportSix from 'components/ReportSix/ReportSix';
import ReportSeven from 'components/ReportSeven/ReportSeven';
import './ReportTypeList.css';

const mapStateToProps = () => ({});

const mapActionsToProps = {};

class ReportTypeList extends Component {

  static propTypes = {
    type: PropTypes.number,
    data: PropTypes.array,
    procedures: PropTypes.array,
  }

  render() {
    const { type, data, procedures } = this.props;

    switch (type) {
      case 1: {
        return <ReportOne data={data}/>; //Выполнение заданий
      }
      case 2: {
        return <ReportTwo data={data}/>; //Достижение планируемых результатов
      }
      case 4: {
        return <ReportFour data={data}/>; //Статистика по группам баллов
      }
      case 5: {
        return <ReportFive data={data}/>; //Распределение первичных баллов
      }
      case 6: {
        return <ReportSix data={data}/>; //Выполнение заданий группами участников
      }
      case 7: {
        return <ReportSeven data={data} procedures={procedures}/>; //Cравнительная диаграмма рассеивания
      }
      default: {
        return null;
      }
    }
  }

}

export default connect(mapStateToProps, mapActionsToProps)(ReportTypeList);
