import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spin, Layout } from 'antd';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import DocumentTitle from 'react-document-title';
import CustomLayout from 'containers/Layout';
import progressTypes from 'constants/progressTypes';
import ReportFilter from 'containers/ReportFilter/ReportFilter';
import ReportsList from 'containers/ReportsList/ReportsList';
import ReportActions, { selectGetInitialDataProgress } from 'redux/report';
import './Report.css';

const { Content } = Layout;

const mapStateToProps = ({ report }) => ({
  getInitialDataProgress: selectGetInitialDataProgress(report),
});

const mapActionsToProps = {
  getInitialData: ReportActions.getInitialData,
};

const messages = defineMessages({
  pageTitle: {
    id: "page.title",
  },
});


class Report extends Component {

  static propTypes = {
    getInitialDataProgress: PropTypes.string,
    getInitialData: PropTypes.func,
    intl: intlShape.isRequired,
  }

  componentDidMount() {
    this.props.getInitialData();
  }

  renderData() {
    return (
      <div>
        <ReportFilter/>
        <div className="content">
          <ReportsList/>
        </div>
      </div>
    );
  }

  renderError() {
    return (
      <Layout>
        <Content className={'main'}>
          <div className={'content'}>
            <div>error</div>
          </div>
        </Content>
      </Layout>
    );
  }

  renderLoader() {
    return (
      <Spin className="loading" size="large"/>
    );
  }

  renderContent() {
    const { getInitialDataProgress } = this.props;
    switch (getInitialDataProgress) {
      case progressTypes.IDLE: {
        return this.renderLoader();
      }
      case progressTypes.WORK: {
        return this.renderLoader();
      }
      case progressTypes.SUCCESS: {
        return this.renderData();
      }
      case progressTypes.ERROR: {
        return this.renderError();
      }
      default: {
        return null;
      }
    }
  }

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <DocumentTitle title={formatMessage(messages.pageTitle)}>
        <CustomLayout>
          {this.renderContent()}
        </CustomLayout>
      </DocumentTitle>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(Report));
