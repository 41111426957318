import { call, put, all, select } from 'redux-saga/effects';
import IndicatorsActions from 'redux/indicators';
import normalizeRegions from 'utils/normalizeRegions';
import normalizeReports from 'utils/normalizeReports';
// import normalizeYears from 'utils/normalizeYears';

var ATE_REPORTS = [3]; // report_type

// TODO use selectCheckedReports selector instead
var getReports = ({ indicators: { reports } }) => reports.filter(report => report.checked);

var getBiasParams = ({ indicators: { checkedRegions, checkedDistricts } }) => ({
  regions: checkedRegions.map(region => region.id),
  districts: checkedDistricts.map(district => district.id),
});

export function* getData(api) {
  try {
    // TODO another route to get reports
    var [
      reports,
      procedures,
      regions,
      years,
    ] = yield all([
      call(api.getReports),
      call(api.getProcedure),
      call(api.getRegions),
      call(api.getStatYears),
    ]);

    // TODO normalize with checked option
    var ateReports = reports.results.filter(report => ATE_REPORTS.includes(report.report_type));
    var data = {
      regions: normalizeRegions(regions.results),
      reports: normalizeReports(ateReports),
      procedures: procedures.results ,
      years: years.results,
    };
    yield put(IndicatorsActions.getInitialDataSuccess(data));
  } catch (error) {
    yield put(IndicatorsActions.getInitialDataError(error));
  }
}

export function* getDistricts(api, { id }) {
  try {
    const districts = yield call(api.getDistricts, id);

    const normalizedDistricts = districts.results.map(item => ({
      name:item.name,
      label:item.name,
      region: item.region,
      id: item.id,
      value: `${item.id}`,
    }));

    yield put(IndicatorsActions.getDistrictsSuccess(normalizedDistricts));
  } catch (error) {
    yield put(IndicatorsActions.getDistrictsError(error));
  }
  
}

export function* getResults(api, { params }) {
  try {
    // TODO type может быть несколько если выбрано несколько отчётов -- yield all
    var reports = yield select(getReports);
    var mappedParams = yield all(reports.map(({ report_type: reportType }) => {
      switch (reportType) {
        case 3: //Индекс необъективности АТЕ
          return select(getBiasParams);
        default:
          break;
      }
    }));
    var results = yield all(reports.map(({ report_type: reportType }, ind) => {
      var apiFuncRegions = Function;
      var apiFuncDistricts = Function;
      var apiParams = mappedParams[ind];
      switch (reportType) { //report_type
        case 3: //Индекс необъективности АТЕ
          apiFuncRegions = api.getBiasDataRegions;
          apiFuncDistricts = api.getBiasDataDistricts;
          break;
        default:
          break;
      }
      if (apiParams.districts.length == 0) {
        return call(apiFuncRegions, { ids: apiParams.regions });
      } else if(apiParams.regions.length == 0) {
        call(apiFuncDistricts, { ids: apiParams.districts });
      } else {
        return all([
          call(apiFuncRegions, { ids: apiParams.regions }),
          call(apiFuncDistricts, { ids: apiParams.districts }),
        ]);
      }
    }));
    yield put(IndicatorsActions.getReportsDataSuccess(results));
  } catch (error) {
    yield put(IndicatorsActions.getReportsDataError(error));
  }
}