import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Loader from 'components/Loader/Loader';
import { selectIsUserAuthorized } from 'redux/app';
import SignoutActions from 'redux/signout';

const mapStateToProps = ({ app }) => ({
  isUserAuthorized: selectIsUserAuthorized(app),
});

const mapActionsToProps = {
  onSendLogout: SignoutActions.sendSignout,
};


class Signout extends Component {
  static propTypes = {
    onSendLogout: PropTypes.func,
    isUserAuthorized: PropTypes.bool,
  }

  componentDidMount() {
    this.props.onSendLogout();
  }

  render() {
    const { isUserAuthorized } = this.props;
    if (!isUserAuthorized) {
      return (
        <Redirect to={{ pathname: '/signin' }}/>
      );
    }
    return (
      <Loader/>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(Signout);
