import { createActions, createReducer } from 'reduxsauce';
import progressTypes from 'constants/progressTypes';

const { Creators } = createActions({
  sendSignout: null,
  sendSignoutSuccess: null,
  sendSignoutError: ['error'],
});

export default Creators;

const Types = {
  SEND_SIGNOUT: 'SEND_SIGNOUT',
  SEND_SIGNOUT_SUCCESS: 'SEND_SIGNOUT_SUCCESS',
  SEND_SIGNOUT_ERROR: 'SEND_SIGNOUT_ERROR',
};

export const SignoutTypes = Types;

export const INITIAL_STATE = {
  sendSignoutProgress: progressTypes.IDLE,
  error: null,
};

export const sendSignout = (state) => ({
  ...state,
  sendSignoutProgress: progressTypes.WORK,
});

export const sendSignoutSuccess = (state) => ({
  ...state,
  sendSignoutProgress: progressTypes.SUCCESS,
});

export const sendSignoutError = (state, { error }) => ({
  ...state,
  sendSignoutProgress: progressTypes.ERROR,
  error: error,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SEND_SIGNOUT]: sendSignout,
  [Types.SEND_SIGNOUT_SUCCESS]: sendSignoutSuccess,
  [Types.SEND_SIGNOUT_ERROR]: sendSignoutError,
});

export const selectSendSignoutProgress = (state) => state.sendSignoutProgress;
export const selectError = (state) => state.error;
