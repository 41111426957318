import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { Sticky, StickyContainer } from 'react-sticky';
import first from 'lodash/first';
import find from 'lodash/find';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import groupDigits from 'utils/groupDigits';
import './ReportOne.css';

class ReportOne extends Component {
  static propTypes = {
    data: PropTypes.array,
  }

  renderTotal(total, columns, validColumns) {
    const countp = find(total, cell => !!cell.name && cell.name === 'COUNTP');
    return (
      <div className="report-one__sum">
        <div className="report-one__sum-member">Вся выборка</div>
        <div className="report-one__sum-countp">{(countp && countp.value) ? groupDigits(countp.value) : '-'}</div>
        <div className="report-one__sum-cols">
          {validColumns.sort((a, b) => a.position - b.position).filter(item => item !== 'COUNTP' && item !== 'BADSCHOOL'  ).map(((item, key) => {
            const cell = find(total, cell => !!cell.name && cell.name === item);
            return (
              <div className="report-one__sum-col" key={`sum-${item.name}__${key}`}>
                {(cell && cell.value) ? parseFloat(cell.value).toFixed(0) : '-'}
              </div>
            );
          }))}
        </div>
      </div>
    );
  }

  render() {
    const { data } = this.props;

    const columns = first(data).columns;
    const validColumns = uniq(sortBy(columns, item => item.position).map(item => item.label));

    const newvalidColumns = uniq(sortBy(columns, item => item.position).map(item => item.name));

    return (
      <StickyContainer key={data[0].id}>
        <div className="report-one">
          <Sticky>
            {
              ({ style }) => (
                <div style={{...style, zIndex: 1}}>
                  <div className="report-one__header">
                    <div className="report-one__header-member">
                      <div>Группы</div>
                      <div>участников</div>
                    </div>
                    <div className="report-one__header-countp">
                      <div>Кол-во</div>
                      <div>участников</div>
                    </div>
                    <div className="report-one__header-cols">
                      <div className="report-one__header-cols-title">Задания</div>
                      <div className="report-one__header-cols-wrapper">
                        {validColumns.filter(item => item !== 'COUNTP' && item !== 'BADSCHOOL').map(((item, key) => {
                          const label = item.replace('_S', '');
                          const task = find(data[0].tasks, item => item.column_name === label);
                          return (
                            <div className="report-one__header-col" key={`header-${item}__${key}`}>
                              <div className="report-one__header-meta">
                                <Popover content={(
                                  <div className="report-one__popover">
                                    {task ? (task.task) : 'Для данного задания нет описания'}
                                  </div>
                                )} title={label}>
                                  <div className="report-one__header-col-title">
                                    {label}
                                  </div>
                                </Popover>
                              </div>
                            </div>
                          );
                        }))}
                      </div>
                      <div className="report-one__header-cols-subtitle">% выполнения заданий</div>
                    </div>
                  </div>

                  {this.renderTotal(data[0].paramsAll.attribs, columns, newvalidColumns)}
                </div>
              )
            }
          </Sticky>

          {data.map((item, key) => {
            const isBlack = find(item.attribs, attribs => !!attribs.name && attribs.name === 'BADSCHOOL');

            if (!item.attribs.length) {
              return (
                <div className="report-one__row" key={`report-one-row__${key}`}>
                  <div className={!isBlack ? 'report-one__row-member' : 'report-one__row-member report-one__row-member_bad'}>
                    {item.short_name || item.name}
                  </div>
                  <div className="report-one__row-cols">
                    <div className="report-one__row-col">
                      Нет данных
                    </div>
                  </div>
                </div>
              );
            }

            const cols = newvalidColumns.sort((a, b) => a.position - b.position).map(validCol => {
              return find(item.attribs, (attr) => {
                return !!attr.name && attr.name === validCol;
              });
            });

            return (
              <div className="report-one__row" key={`report-one-row__${key}`}>
                <div className={!isBlack ? 'report-one__row-member' : 'report-one__row-member report-one__row-member_bad'}>
                  {item.short_name || item.name}
                </div>
                <div className={!isBlack ? 'report-one__row-countp' : 'report-one__row-countp report-one__row-countp_bad'}>
                  {groupDigits(item.attribs.find(item => item.name === 'COUNTP').value)}
                </div>
                <div className={!isBlack ? 'report-one__row-cols' : 'report-one__row-cols report-one__row-cols_bad'}>
                  {cols.filter(item => item && item.name && item.name !== 'COUNTP' && item.name !== 'BADSCHOOL').map(((attr, attrKey) => (
                    <div className="report-one__row-col" key={`report-one-row__${attr.name}__${attrKey}__${key}`}>
                      {parseFloat(attr.value)}
                    </div>
                  )))}
                </div>
              </div>
            );
          })}

        </div>
      </StickyContainer>
    );
  }

}

export default ReportOne;
