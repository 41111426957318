import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'antd';
import './ReportFilterItem.css';

class ReportFilterItem extends Component {
  static propTypes = {
    title: PropTypes.string,
    count: PropTypes.number,
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
  }

  render() {
    const { title, count, isOpen, isDisabled } = this.props;
    const caretClassName = classnames('report-filter-item__caret', {
      'report-filter-item__caret_up': isOpen,
      'report-filter-item__caret_down': !isOpen,
    });

    const caretClassItem = classnames('report-filter-item', {
      'report-filter-item_is-open': isOpen,
      'report-filter-item_disabled': isDisabled,
    });

    return (
      <div className={caretClassItem} onClick={this.props.onClick}>
        <div className="report-filter-item__name">{title}</div>
        <div className={caretClassName}>
          <Icon type={isOpen ? 'caret-up' : 'caret-down'}/>
        </div>
        {(count > 0) && <div className="report-filter-item__bubble">{count}</div>}
      </div>
    );
  }
}

export default ReportFilterItem;
