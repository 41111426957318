import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Modal } from 'antd';
import ReportActions, { selectProcedureFilterIsValid, getReportNumber, selectResetFilterIsEnabled } from 'redux/report';
import './ReportFilterSubmit.css';
import { isDevelopment } from 'utils/isDevelopment';
import isEqual from 'lodash/isEqual';

const mapStateToProps = ({ report }) => {
  const { regions, districts, schools, selectedProcedures, reports } = report;
  return {
    isValid: selectProcedureFilterIsValid(report),
    reportNumber: getReportNumber(report),
    isEnabled: selectResetFilterIsEnabled(report),
    isCheckedChanged: {
      regions,
      districts,
      schools,
      selectedProcedures,
      reports,
    },
  };
};

const mapActionsToProps = {
  getReportsData: ReportActions.getReportsData,
  resetCheckedItems: ReportActions.resetCheckedItems,
};

class ReportFilterSubmit extends Component {
  static propTypes = {
    isValid: PropTypes.bool,
    reportNumber: PropTypes.number,
    getReportsData: PropTypes.func,
    isEnabled: PropTypes.bool,
    resetCheckedItems: PropTypes.func,
  }

  state = {
    visible: false,
    showReset: false,
    prevPropsIsCheckedChanged: this.props.isCheckedChanged,
  }

  componentDidMount() {
    if (isDevelopment) {
      setTimeout(() => {
        // this.handleClick();
      }, 1500);
    }
  }

  
  static getDerivedStateFromProps({ isCheckedChanged }, { prevPropsIsCheckedChanged }) {
    if (!isEqual(isCheckedChanged, prevPropsIsCheckedChanged)) {
      return {
        prevPropsIsCheckedChanged: isCheckedChanged,
        showReset: false,
      };
    }
    return null;
  }

  handleClick = () => {
    const { reportNumber, isValid } = this.props;
    if (reportNumber > 50 && !this.state.visible){
      this.setState({ visible: true });
    } else if (isValid) {
      this.setState({ visible: false, showReset: true });
      this.props.getReportsData();
    }
  }

  handleReset = () => {
    if (this.props.isEnabled){
      this.props.resetCheckedItems();
    }
  }

  handleCancel = () => this.setState({ visible: false })

  render() {
    const { isValid, reportNumber, isEnabled } = this.props;
    const className = classnames('report-filter-submit', {
      'report-filter-submit_valid': isValid && isEnabled,
    });

    return (
      <div>
        {
          this.state.showReset ?
          <div className={className} onClick={this.handleReset}>
            Очистить
          </div> :
          <div className={className} onClick={this.handleClick}>
            Показать
          </div>
        }
        <Modal
          title="Внимание"
          visible={this.state.visible}
          onOk={this.handleClick}
          onCancel={this.handleCancel}
          okText="Да"
          cancelText="Нет"
        >
          <p>
            {`Внимание! Формирование выбранных отчетов (${reportNumber}) может занять длительное время.
              ${reportNumber > 500 ? ' Ваш браузер может свалиться.' : ''} Продолжить?`}
          </p>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(ReportFilterSubmit);
