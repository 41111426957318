import React, { Component } from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import progressTypes from 'constants/progressTypes';
import DirectoryProcFilterPopup from 'components/DirectoryProcFilterPopup';
import DirectoryProcActions, {
  selectOptions,
  selectOptionsProgress,
  selectResultsProgress,
  selectTags,
} from 'redux/directoryProc';
import DirectoryFilterWrapper from 'components/DirectoryFilterWrapper';


const mapStateToProps = ({ directoryProc }) => ({
  options: selectOptions(directoryProc),
  optionsProgress: selectOptionsProgress(directoryProc),
  resultsProgress: selectResultsProgress(directoryProc),
  tags: selectTags(directoryProc),
});

const mapActionsToProps = {
  getOptions: DirectoryProcActions.getOptions,
  getResults: DirectoryProcActions.getResults,
  addProcedure: DirectoryProcActions.addProcedure,
  removeProcedure: DirectoryProcActions.removeProcedure,
};

class DirectoryProcedureFilter extends Component {
  // state = {
  //   proc: undefined,
  //   year: undefined,
  //   cl: undefined,
  //   subj: undefined,
  // }

  componentDidMount() {
    const { optionsProgress } = this.props;
    if (optionsProgress !== progressTypes.SUCCESS) {
      this.props.getOptions();
    }
  }

  isFormInvalid() {
    var { tags } = this.props;
    return tags.length == 0;
  }

  handleChange = (propList, etc) => {
    var [ proc, year, cl, subj ] = propList;
    this.setState({ proc, year, cl, subj });
  }

  handleSubmit = () => {
    var { type } = this.props;
    var params = {};
    params.type = type;
    this.props.onSubmit(params);
    this.props.onHandleClose();
  }

  render() {
    var { optionsProgress } = this.props;
    switch (optionsProgress) {
      case progressTypes.WORK:
        return <Spin size="large" style={{ margin: '0 auto' }}/>;
      case progressTypes.SUCCESS:
        var { options, resultsProgress, tags } = this.props;
        return <DirectoryProcFilterPopup
          options={options}
          optionsProgress={optionsProgress}
          resultsProgress={resultsProgress}
          isFormInvalid={this.isFormInvalid()}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          onAddProcedure={this.props.addProcedure}
          onRemoveProcedure={this.props.removeProcedure}
          tags={tags}
        />;
      case progressTypes.ERROR:
        return (<h3>Не удалось получить фильтры</h3>);
      default:
        return null;
    }
  }
}

export default connect(
  mapStateToProps, 
  mapActionsToProps
)(DirectoryFilterWrapper({ name: 'Справочник оценочных процедур', key: 'proc'})(DirectoryProcedureFilter));
