import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReportFilterListItem from 'components/ReportFilterListItem/ReportFilterListItem';
import './ReportList.css';

class ReportList extends Component {
  static propTypes = {
    items: PropTypes.array,
    noProcIds: PropTypes.array,
    onCheck: PropTypes.func,
    onSelect: PropTypes.func,
  }

  render() {
    const { items, noProcIds } = this.props;
    var firstHalf = items.filter(item => !noProcIds.includes(item.report_type));
    var secondHalf = items.filter(item => noProcIds.includes(item.report_type));

    return (
      <div className="report-list">
        {firstHalf.map((item, index) => (
          <ReportFilterListItem
            key={item.id}
            id={item.id}
            index={index}
            code={item.codeText}
            name={item.name}
            selected={item.selected}
            checked={item.checked}
            disabled={item.disabled}
            hasSelectedChildren={item.hasSelectedChildren}
            onSelect={this.props.onSelect}
            onCheck={this.props.onCheck}
          />
        ))}
        <div className="report-list__line" />
        {secondHalf.map((item, index) => (
          <ReportFilterListItem
            key={item.id}
            id={item.id}
            index={index}
            code={item.codeText}
            name={item.name}
            selected={item.selected}
            checked={item.checked}
            disabled={item.disabled}
            hasSelectedChildren={item.hasSelectedChildren}
            onSelect={this.props.onSelect}
            onCheck={this.props.onCheck}
          />
        ))}
      </div>
    );
  }
}

export default ReportList;
