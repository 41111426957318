/* eslint-disable */ 
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Spin } from 'antd';
import progressTypes from 'constants/progressTypes';

import './SchoolsList.css';
import SchoolsListItem from './SchoolsListItem';

const { Content } = Layout;

class SchoolsList extends Component {
  static propTypes = {
    districts: PropTypes.array,
    getSchoolsProgress: PropTypes.func,
    population: PropTypes.object,
    regions: PropTypes.array,
    schools: PropTypes.object,
  }

  renderError() {
    return (
      <Layout>
        <Content className={'main'}>
          <div className={'content'}>
            <div>Ошибка</div>
          </div>
        </Content>
      </Layout>
    );
  }

  renderLoader(size = 'large') {
    return (
      <Spin className="loading" size={size}/>
    );
  }

  render () {
    const { status, schools, years, districts, regions } = this.props;

    switch (status) {
      case progressTypes.IDLE: {
        return null;
      }
      case progressTypes.WORK: {
        return (
          <div className="directory__container">
            {this.renderLoader()}
          </div>
        );
      }
      case progressTypes.SUCCESS: {
        return (
          <div className="directory__container">
            {Object.keys(schools).map(x => 
              <SchoolsListItem
                key={x}
                year={years.find(y => y.value == x)}
                schools={schools[x]}
                districts={districts}
                regions={regions}
                onSaveSchoolGroup={this.props.onSaveSchoolGroup}
              />
            )}
          </div>
        )
      }
      case progressTypes.ERROR: {
        return (
          <div className="directory__container">
            {this.renderError()}
          </div>
        );
      }
      default: {
        return null;
      }
    }
  }
}

export default SchoolsList;
