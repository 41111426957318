export const transportAccessibilityTypes = [
  { value: '1', label: '1 раз в день' },
  { value: '2', label: '1 раз в неделю' },
  { value: '3', label: 'несколько раз в день' },
  { value: '4', label: 'несколько раз в неделю' },
  { value: '5', label: 'ОО расположена в городе федерального значения или административном центре' },
  { value: '6', label: 'отсутствует' },
  { value: '7', label: 'реже 1 раза в неделю' },
  { value: '8', label: 'чаще 1 раза в час' },
];
