import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, Icon } from 'antd';
import find from 'lodash/find';
import Sort from 'components/Sort/Sort';
import RegionList from 'components/RegionList/RegionList';
import DistrictList from 'components/DistrictList/DistrictList';
import SchoolsList from 'components/SchoolsList/SchoolsList';
import SelectAll from 'components/SelectAll/SelectAll';
import progressTypes from 'constants/progressTypes';
import ReportActions, {
  selectRegions,
  selectDistricts,
  selectGetDistrictsProgress,
  selectSchools,
  selectGetSchoolsProgress,
  selectIsCheckedAllRegion,
  selectIsIndeterminateAllRegion,
  selectIsCheckedAllDistrict,
  selectIsIndeterminateAllDistrict,
  selectIsCheckedAllSchool,
  selectIsIndeterminateAllSchool,
} from 'redux/report';
import { selectUser } from 'redux/app';
import { isDevelopment } from 'utils/isDevelopment';
import './ReportFilterMembersPopup.css';

const mapStateToProps = ({ app, report }) => ({
  user: selectUser(app),
  regions: selectRegions(report),
  districts: selectDistricts(report),
  getDistrictsProgress: selectGetDistrictsProgress(report),
  schools: selectSchools(report),
  getSchoolsProgress: selectGetSchoolsProgress(report),
  isCheckedAllRegion: selectIsCheckedAllRegion(report),
  isIndeterminateAllRegion: selectIsIndeterminateAllRegion(report),
  isCheckedAllDistrict: selectIsCheckedAllDistrict(report),
  isIndeterminateAllDistrict: selectIsIndeterminateAllDistrict(report),
  isCheckedAllSchool: selectIsCheckedAllSchool(report),
  isIndeterminateAllSchool: selectIsIndeterminateAllSchool(report),
});

const mapActionsToProps = {
  selectRegion: ReportActions.selectRegion,
  getDistricts: ReportActions.getDistricts,
  getDistrictsSuccess: ReportActions.getDistrictsSuccess,
  selectDistrict: ReportActions.selectDistrict,
  getSchools: ReportActions.getSchools,
  checkRegion: ReportActions.checkRegion,
  checkDistrict: ReportActions.checkDistrict,
  checkSchool: ReportActions.checkSchool,
  checkAllRegion: ReportActions.checkAllRegion,
  checkAllDistrict: ReportActions.checkAllDistrict,
  checkAllSchool: ReportActions.checkAllSchool,
};

const getSelectedSort = items => find(items, item => item.active);

class ReportFilterMembersPopup extends Component {
  static propTypes = {
    regions: PropTypes.array,
    districts: PropTypes.array,
    getDistrictsProgress: PropTypes.string,
    schools: PropTypes.array,
    getSchoolsProgress: PropTypes.string,
    isCheckedAllRegion: PropTypes.bool,
    isIndeterminateAllRegion: PropTypes.bool,
    isCheckedAllDistrict: PropTypes.bool,
    isIndeterminateAllDistrict: PropTypes.bool,
    isCheckedAllSchool: PropTypes.bool,
    isIndeterminateAllSchool: PropTypes.bool,
    selectRegion: PropTypes.func,
    getDistricts: PropTypes.func,
    selectDistrict: PropTypes.func,
    getSchools: PropTypes.func,
    checkRegion: PropTypes.func,
    checkDistrict: PropTypes.func,
    checkSchool: PropTypes.func,
    checkAllRegion: PropTypes.func,
    checkAllDistrict: PropTypes.func,
    checkAllSchool: PropTypes.func,
  };

  state = {
    sortItems: [
      { field: 'code', text: '№', direction: 'asc', active: true },
      { field: 'sortName', text: 'Aя', direction: 'asc', active: false },
    ],
    regionSearch: '',
    districtSearch: '',
    schoolSearch: '',
  };

  componentDidMount() {
    const { user } = this.props;

    if (user.district.length) {
      this.props.getDistrictsSuccess(user.district);
    }
  }

  handleClickSort = position => {
    const { sortItems } = this.state;

    if (sortItems[position].active) {
      sortItems[position].direction = sortItems[position].direction === 'asc' ? 'desc' : 'asc';
    }

    const tmp = sortItems.map(item => ({ ...item, active: false }));

    tmp[position].active = true;

    this.setState({
      sortItems: tmp,
    });
  };

  handleSelectRegion = id => {
    this.props.selectRegion(id);
    this.props.getDistricts(id);
  };

  handleSelectDistrict = id => {
    const { user } = this.props;

    this.props.selectDistrict(id);
    this.props.getSchools(id);
  };

  handleCheckRegion = id => {
    this.props.checkRegion(id);
  };

  handleCheckDistrict = id => {
    console.log(id);
    this.props.checkDistrict(id);
  };

  handleCheckSchool = id => {
    this.props.checkSchool(id);
  };

  filterMembers = (districts, key) =>
    key ? districts.filter(item => item.name.toLowerCase().includes(key.toLowerCase())) : districts;

  getInputProps = key => ({
    onChange: event => this.setState({ [key]: event.target.value }),
    suffix: this.state[key] && <Icon type="close-circle" onClick={() => this.setState({ [key]: '' })} />,
    prefix: !this.state[key] && <Icon type="search" />,
    value: this.state[key],
  });

  render() {
    const { sortItems, regionSearch, districtSearch, schoolSearch } = this.state;
    const sort = getSelectedSort(sortItems);
    const {
      user,
      regions,
      districts,
      schools,
      getDistrictsProgress,
      getSchoolsProgress,
      isCheckedAllRegion,
      isIndeterminateAllRegion,
    } = this.props;

    const className = 'report-filter-members-popup';

    return (
      <div className={`${className}`}>
        <div className={`${className}__col`}>
          <div className={`${className}__col-header`}>
            <Sort items={sortItems} onClick={this.handleClickSort} />
            <SelectAll
              checked={isCheckedAllRegion}
              indeterminate={isIndeterminateAllRegion}
              onChange={this.props.checkAllRegion}
            />
          </div>
          <div className={`${className}__col-header`}>
            <Input placeholder="Поиск по регионам" {...this.getInputProps('regionSearch')} />
          </div>
          <div className={`${className}__col-content`}>
            <RegionList
              items={this.filterMembers(regions, regionSearch)}
              sort={sort}
              onCheck={this.handleCheckRegion}
              onSelect={this.handleSelectRegion}
            />
          </div>
        </div>

        <div className={`${className}__col`}>
          <div className={`${className}__col-header`}>
            <div className={`${className}__col-header-name`}>{'Список муниципалитетов'}</div>
            {getDistrictsProgress === progressTypes.SUCCESS && districts.length > 0 && (
              <SelectAll
                checked={this.props.isCheckedAllDistrict}
                indeterminate={this.props.isIndeterminateAllDistrict}
                onChange={this.props.checkAllDistrict}
              />
            )}
          </div>
          <div className={`${className}__col-header`}>
            <Input placeholder="Поиск по муниципалитетам" {...this.getInputProps('districtSearch')} />
          </div>
          <div className={`${className}__col-content`}>
            <DistrictList
              items={this.filterMembers(districts, districtSearch)}
              progress={getDistrictsProgress}
              onCheck={this.handleCheckDistrict}
              onSelect={this.handleSelectDistrict}
            />
          </div>
        </div>

        <div className={`${className}__col`}>
          <div className={`${className}__col-header`}>
            <div className={`${className}__col-header-name`}>{'Список школ'}</div>
            {getSchoolsProgress === progressTypes.SUCCESS && schools.length > 0 && (
              <SelectAll
                checked={this.props.isCheckedAllSchool}
                indeterminate={this.props.isIndeterminateAllSchool}
                onChange={this.props.checkAllSchool}
              />
            )}
          </div>
          <div className={`${className}__col-header`}>
            <Input placeholder="Поиск по школам" {...this.getInputProps('schoolSearch')} />
          </div>
          <div className={`${className}__col-content`}>
            <SchoolsList
              items={this.filterMembers(schools, schoolSearch)}
              progress={getSchoolsProgress}
              onCheck={this.handleCheckSchool}
              onSelect={this.handleCheckSchool}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ReportFilterMembersPopup);
