import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spin, Layout } from 'antd';
import CustomLayout from 'containers/Layout';
import DocumentTitle from 'react-document-title';
import DirectoryProcResults from 'components/DirectoryProcResults';
import DirectoryAteResults from 'components/DirectoryAteResults';
import SchoolsList from 'containers/SchoolsList/SchoolsList';
import progressTypes from 'constants/progressTypes';
import DirectoryAteFilter from 'containers/DirectoryAteFilter/DirectoryAteFilter';
import DirectoryProcedureFilter from 'containers/DirectoryProcedureFilter';
import DirectoryFilter from 'containers/DirectoryFilter/DirectoryFilter';
import ErrorBoundary from 'helpers/errorBoundary';
import DirectoryActions, {
  selectRegions,
  selectDistricts,
  selectSchools,
  selectProgress,
  selectGetSchoolsProgress,
  selectYears as selectOoYears,
} from 'redux/directory';
import DirectoryAteActions, { 
  selectResults as selectAteResults ,
  selectResultsProgress as selectAteResultsProgress,
} from 'redux/directoryAte';
import DirectoryProcActions, {
  selectResultsProgress as selectProcResultsProgress,
  selectResults as selectProcResults,
  selectOptions as selectProcOptions,
  selectSubjects as selectProcSubjects,
} from 'redux/directoryProc';

import './Directory.css';

const { Content } = Layout;

var DIRECTORY_OO = 'oo';
var DIRECTORY_ATE = 'ate';
var DIRECTORY_PROC = 'proc';

const mapStateToProps = ({ directory, directoryAte, directoryProc }) => ({
  regions: selectRegions(directory),
  districts: selectDistricts(directory),
  schools: selectSchools(directory),
  progress: selectProgress(directory),
  getSchoolsProgress: selectGetSchoolsProgress(directory),
  ateResults: selectAteResults(directoryAte),
  ateResultsProgress: selectAteResultsProgress(directoryAte),
  procResults: selectProcResults(directoryProc),
  procProgress: selectProcResultsProgress(directoryProc),
  procOptions: selectProcOptions(directoryProc),
  procSubjects: selectProcSubjects(directoryProc),
  oOyears: selectOoYears(directory),
});

const mapActionsToProps = {
  getData: DirectoryActions.getDirectoryData,
  getSchools: DirectoryActions.getDirectorySchools,
  getResultsAte: DirectoryAteActions.getDirectoryResults,
  saveSchoolGroup: DirectoryActions.addSchoolGroup,
  getResultsProc: DirectoryProcActions.getResults,
};

const POPULATION = {
  1: 'менее 200 человек',
  2: '200-1000 человек',
  3: '1000-5000 человек',
  4: '5000-25 тыс человек',
  5: '25 тыс. – 50 тыс. человек',
  6: '50 тыс. – 100 тыс. человек',
  7: '100 тыс. - 250 тыс. человек',
  8: '250 тыс. – 500 тыс. человек',
  9: '500 тыс. – 1 млн. человек',
  10: 'более 1 млн. человек',
};

class Directory extends Component {
  static propTypes = {
    regions: PropTypes.array,
    districts: PropTypes.array,
    schools: PropTypes.object,
    progress: PropTypes.string,
    getSchoolsProgress: PropTypes.string,
    getData: PropTypes.func,
    getSchools: PropTypes.func,
  }

  state = {
    directory: undefined,
  }

  componentDidMount() {
    this.props.getData();
  }

  handleSubmit = (params) => {
    var { type } = params;
    this.setState({ directory: type });
    delete params.type;
    switch (type) {
      case DIRECTORY_OO:
        this.props.getSchools(params);
        break;
      case DIRECTORY_ATE:
        this.props.getResultsAte(params);
        break;
      case DIRECTORY_PROC:
        this.props.getResultsProc(params);
        break;
      default:
        break;
    }
  }

  renderError() {
    return (
      <Layout>
        <Content className={'main'}>
          <div className={'content'}>
            <div>Ошибка</div>
          </div>
        </Content>
      </Layout>
    );
  }

  renderLoader(size = 'large') {
    return (
      <Spin className="loading" size={size}/>
    );
  }

  renderResults() {
    var { directory } = this.state;
    switch (directory) {
      case DIRECTORY_OO:
        var { regions, districts, schools, getSchoolsProgress, oOyears } = this.props;
        return (
          <div className="content">
            <ErrorBoundary>
              <SchoolsList
                districts={districts}
                regions={regions}
                schools={schools}
                years={oOyears}
                population={POPULATION}
                status={getSchoolsProgress}
                onSaveSchoolGroup={this.props.saveSchoolGroup}
              />
            </ErrorBoundary>
          </div>
        );
      case DIRECTORY_ATE:
        var { ateResults, ateResultsProgress } = this.props;
        return <DirectoryAteResults
          results={ateResults}
          progress={ateResultsProgress}
        />;
      case DIRECTORY_PROC:
        var { procProgress, procResults, procOptions, procSubjects } = this.props;
        return <DirectoryProcResults
          options={procOptions}
          results={procResults} 
          progress={procProgress}
          subjects={procSubjects}
        />;
      default:
        return null;
    }
  }

  renderData() {
    const { regions, districts, getSchoolsProgress } = this.props;
    return (
      <div>
        <div className="directory-filter">
          <div className="directory-filter__col">
            <div className="directory-filter__title">Выберите:</div>
            <DirectoryFilter
              regions={regions}
              districts={districts}
              progress={getSchoolsProgress}
              onSubmit={this.handleSubmit}
              type={DIRECTORY_OO}
            />
            <DirectoryAteFilter
              type={DIRECTORY_ATE}
              onSubmit={this.handleSubmit}
            />
            <DirectoryProcedureFilter
              type={DIRECTORY_PROC}
              onSubmit={this.handleSubmit}
            />
          </div>
        </div>
        {this.renderResults()}
      </div>
    );
  }

  renderContent() {
    const { progress } = this.props;

    switch (progress) {
      case progressTypes.IDLE: {
        return this.renderLoader();
      }
      case progressTypes.WORK: {
        return this.renderLoader();
      }
      case progressTypes.SUCCESS: {
        return this.renderData();
      }
      case progressTypes.ERROR: {
        return this.renderError();
      }
      default: {
        return null;
      }
    }
  }

  render() {
    return (
      // TODO HOLD format message
      <DocumentTitle title={'ФИС-ОКО - СПРАВОЧНИКИ'}>
        <CustomLayout>
          {this.renderContent()}
        </CustomLayout>
      </DocumentTitle>
    );
  }

}

export default connect(mapStateToProps, mapActionsToProps)(Directory);
