import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import './ProcedureTag.css';

class ProcedureTag extends Component {
  static propTypes = {
    procedure: PropTypes.string,
    level: PropTypes.string,
    subject: PropTypes.string,
    year: PropTypes.string,
    index: PropTypes.number,
    onClick: PropTypes.func,
  }

  handleClick = () => {
    const { index } = this.props;
    this.props.onClick(index);
  }

  render() {
    const {
      procedure,
      year,
      level,
      subject,
    } = this.props;

    const name = `${procedure} ${year} / ${level} / ${subject}`;

    return (
      <div className="procedure-tag">
        <div className="procedure-tag__name" alt={name} title={name}>
          {`${name}`}
        </div>
        <div className="procedure-tag__remove" onClick={this.handleClick}>
          <Icon type="close"/>
        </div>
      </div>
    );
  }
}

export default ProcedureTag;
